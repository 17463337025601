<template>
    <div class="step__control">
        <div class="step__control-top">
            <div class="step__control-left">
                <button @click="emitStep('prev')" class="step__control-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                        <mask id="path-1-inside-1_1_1140" fill="white">
                            <path d="M6 0L-7.15493e-08 6L6 12"/>
                        </mask>
                        <path
                            d="M6.91924 0.919239L7.83848 2.19236e-08L6 -1.83848L5.08076 -0.919239L6.91924 0.919239ZM-7.15493e-08 6L-0.919239 5.08076C-1.42692 5.58844 -1.42692 6.41156 -0.919239 6.91924L-7.15493e-08 6ZM5.08076 12.9192L6 13.8385L7.83848 12L6.91924 11.0808L5.08076 12.9192ZM5.08076 -0.919239L-0.919239 5.08076L0.919238 6.91924L6.91924 0.919239L5.08076 -0.919239ZM-0.919239 6.91924L5.08076 12.9192L6.91924 11.0808L0.919239 5.08076L-0.919239 6.91924Z"
                            fill="#2491EB"
                            mask="url(#path-1-inside-1_1_1140)"
                        />
                    </svg>
                    <span>back</span>
                </button>
                <button v-if="$route.params.id === '3' && !isReadonly"
                        @click="emitAction('addDoor')"
                        class="step__control-button step__control-button--add"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path
                            d="M7 1V13M1 7H13"
                            stroke="white"
                            stroke-width="1.3"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <span>add door</span>
                </button>
                <button
                    v-if="$route.params.id === '4' && typeAddButton === 'Filler' && !isReadonly"
                    class="step__control-button step__control-button--add"
                    @click="emitAction('addFiller')"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path
                            d="M7 1V13M1 7H13"
                            stroke="white"
                            stroke-width="1.3"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <span>add FILLER</span>
                </button>
                <button
                    v-if="$route.params.id === '4' && typeAddButton !== 'Filler' && !isReadonly"
                    class="step__control-button step__control-button--add step__control-button--another"
                    @click="emitAction('addFiller')"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path
                            d="M7 1V13M1 7H13"
                            stroke="white"
                            stroke-width="1.3"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <span>add another one</span>
                </button>
                <button
                    @click="changeExtraMode(false)"
                    v-if="$route.params.id === '7' && isExtraMode && !isReadonly"
                    class="step__control-button step__control-button--add"
                >
                    <span>save extra</span>
                </button>
                <button
                    v-if="$route.params.id === '8' && !isReadonly"
                    class="step__control-button step__control-button--finish step__control-button--add"
                    @click="emitAction('addSpace')"
                >
                    <span>add a space</span>

                </button>
                <button
                    v-if="$route.params.id === '8' && !isReadonly"
                    @click="emitAction('addPartition')"
                    class="step__control-button step__control-button--finish step__control-button--add"
                >
                    <span>add a partition</span>
                </button>
            </div>
            <div class="step__control-right">
                <button class="step__control-button"
                        v-if="!isReadonly"
                        @click="emitAction('saveDraft')"
                >Save as a draft</button>
                <button
                    v-if="+$route.params.id < 8"
                    @click="emitStep('next')"
                    class="step__control-button step__control-button--next"
                >
                    Next
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                        <mask id="path-1-inside-1_1_837" fill="white">
                            <path d="M0 0L6 6L1.43099e-07 12"/>
                        </mask>
                        <path
                            d="M-0.919239 0.919239L-1.83848 2.19236e-08L-2.19236e-08 -1.83848L0.919239 -0.919239L-0.919239 0.919239ZM6 6L6.91924 5.08076C7.42692 5.58844 7.42692 6.41156 6.91924 6.91924L6 6ZM0.919239 12.9192L1.65022e-07 13.8385L-1.83848 12L-0.919239 11.0808L0.919239 12.9192ZM0.919239 -0.919239L6.91924 5.08076L5.08076 6.91924L-0.919239 0.919239L0.919239 -0.919239ZM6.91924 6.91924L0.919239 12.9192L-0.919239 11.0808L5.08076 5.08076L6.91924 6.91924Z"
                            fill="white"
                            mask="url(#path-1-inside-1_1_837)"
                        />
                    </svg>
                </button>
            </div>
        </div>
        <div v-if="$route.params.id === '8'  && !isReadonly" class="step__control-bottom">
            <div class="step__control-block">
                <select-question
                    :defaultValue="'FINISH and save as DONE'"
                    :isFinishSelect="true"
                    :selectList="selectFinishStatus"
                    @change-select="emitFinishAction"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from "vue";
import SelectQuestion from "@/components/UI/SelectQuestion.vue";
import { useUiStore } from "../stores/uiStore";
import { useEstimateStore } from "../stores/estimateStore";

const emit = defineEmits(["step", "action"]);
const props = defineProps({
    typeAddButton: null,
});
const uiStore = useUiStore();

const isExtraMode = ref(false);

const selectFinishStatus = useUiStore().getSelectFinishStatusList(useEstimateStore().estimate);

const isReadonly = useEstimateStore().getEstimateReadonly();

function emitAction(actionName:string) {
    const allowedActions = [
        'addDoor', 'addFiller', 'addSpace', 'addPartition', 'addMounts',
        'addDeliveryExtra', 'saveDeliveryExtra',
        'saveDraft', 'saveOfficial', 'saveQuote', 'saveDone', 'saveOrder',
    ];
    //
    if (allowedActions.includes(actionName)) {
        console.debug('emitAction', actionName);
        emit('action', actionName);
    } else {
        console.error(`action '${actionName}' not allowed`);
    }
}

function emitStep(direction: string) {
    const allowedActions = ['prev', 'next'];
    uiStore.reloadSquare();
    if (allowedActions.includes(direction)) {
        emit('step', direction);
    } else {
        console.error(`stepping '${direction}' not allowed`);
    }
}

function emitFinishAction(text: string) {
    switch (text) {
        case 'FINISH and save as DONE':
            emitAction('saveDone');
            break;
        case 'FINISH and save as official':
            emitAction('saveOfficial');
            break;
        case 'FINISH and save as quote' :
            emitAction('saveQuote');
            break;
        case 'FINISH and save as order' :
            emitAction('saveOrder');
            break;
        default:
            console.error('action not defined', text);
    }
}

function changeExtraMode(mode: boolean) {
    if (mode) {
        emitAction('addDeliveryExtra');
    } else {
        emitAction('saveDeliveryExtra');
    }
    isExtraMode.value = mode;
}

</script>

<style scoped>
.step__control-top {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    column-gap: 18px;
}

.step__control-block {
    width: 284px;
}

.step__control-bottom {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.step__control-right {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.step__control-left {
    display: flex;
    align-items: center;
    column-gap: 18px;
}

.step__control-button {
    padding: 14px 32px;
    border-radius: 30px;
    border: 1px solid #d2d8e3;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    color: #2491eb;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.step__control-button.step__control-button--add {
    border: 1px solid transparent;
    background-color: #404d63;
    color: #fff;
}

.step__control-button--finish {
    padding-left: 18px;
    padding-right: 18px;
}

.step__control-button.step__control-button--another {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
}

.step__control-button.step__control-button--next {
    border: 1px solid transparent;
    background-color: #2491eb;
    color: #fff;
}
</style>
