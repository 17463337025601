import { acceptHMRUpdate, defineStore } from "pinia";
import { useDiscount } from "../utils/api";
import { useUserStore } from "./userStore";
import { useEstimateStore } from "./estimateStore";
import { usePriceStore } from "./priceStore";
import { priceStoreData } from "../models/priceStoreData";
import {reactive, watch} from "vue";
import {Estimate} from "../models/estimate";
import {useDictStore} from "./dictStore";

export const usePriceStore = defineStore('price', () => {
    const user = useUserStore();
    const estimate = useEstimateStore();
    const priceStore = usePriceStore();
    const dict = useDictStore();
    const data = <priceStoreData>reactive({
        clientType: null,
        discounts: [],
        multiplicator: 1,
        currentDiscount: {
            client_type: null,
            discount_percent: 0,
            price_code: null,
        },
        estimate: <Estimate>useEstimateStore().estimate,
        currentPriceKey: null,
        currentSumKey: null
    });

    const loadRules = async () => {
        const res = await useDiscount();
        if (res !== null && res.code == 200) {
            data.discounts = res?.data?.data;
            return true;
        }

        return false;
    }

    loadDiscount().then(r => {
        if (r) {
            console.log('Price rules initialized')
        } else {
            onsole.log('Price rules not initialized')
        }
    });

    async function loadDiscount() {
        const res = await useDiscount();
        if (res !== null && res.code == 200) {
            data.discounts = res?.data?.data;
            return true;
        }

        return res;
    }

    const getClientType = () => {
        //const client = estimate?.estimate?.info?.client;
        const dealTypeCode =  estimate?.estimate?.info?.dealTypeCode;
        if (dealTypeCode == 'b2c') {
            data.clientType = 'B2C';
        } else if (data.dealTypeCode == 'cost') {
            data.clientType = 'COST';
        } else {
            data.clientType = 'B2B';
        }

        return data.clientType;

    }
    const initCurrentPriceRule = (): string => {
        if (data.discounts.length == 0) {
            loadRules().then(r => {
                if (r) {
                    setPriceKeys();
                }
            });
        } else {
            setPriceKeys();
        }
    }

    const setPriceKeys = () => {
        let roleId = undefined;
        if (user?.profile?.roles?.length > 0 && data?.discounts?.length > 0) {
            user.profile.roles.forEach(function (role){
                roleId = role.pivot.role_id;
            });
            data.discounts.forEach(function (discount) {
                if (discount?.role_id == roleId && discount?.client_type?.toUpperCase() == data?.clientType?.toUpperCase()) {
                    data.currentDiscount = discount;
                }
            });
        }
        if (data?.currentDiscount?.price_code !== undefined) {
            if (data.currentDiscount.price_code === 'cost') {
                data.currentPriceKey = 'price';
                data.currentSumKey = 'sum';
            } else {
                data.currentPriceKey = 'price' + data.currentDiscount?.price_code?.toUpperCase();
                data.currentSumKey = 'sum' + data.currentDiscount?.price_code?.toUpperCase();
            }
        } else {
            data.currentPriceKey = 'price';
            data.currentSumKey = 'sum';
        }
    }

    const getDiscountPercent = ():number => {
        const clientType = getClientType();
        let roleId = undefined;
        if (user.profile.roles.length > 0 && data.discounts.length > 0) {
            user.profile.roles.forEach(function (role){
                roleId = role.pivot.role_id;
            });
            data.discounts.forEach(function (discount) {
                if (discount.role_id == roleId && discount.client_type == clientType) {
                    return discount;
                }
            });

            return data.currentDiscount.discount_percent;
        }

        return 0;
    }

    const initCurrentMultiplicator = () => {
        priceStore.loadDiscount();
        let role = 'manager'
        if (user.isAdmin) {
            role = 'admin';
        }
        if (user.isSalesEstimator) {
            role = 'estimator';
        }

        const mlt = dict.dicts.multiplicator.filter(function (value) {
            if (value?.code?.toLowerCase() == role?.toLowerCase()) {
                return value;
            }
        });

        let qty = mlt[0].qtyB2C;
        if (data.clientType == 'B2B') {
            qty = mlt[0].qtyB2B;
        }

        if (qty) {
            data.multiplicator = Number(qty)
        } else {
            data.multiplicator = 1;
        }
    }

    async function getDiscount() {
        const res = await useDiscount();
        if (res.code == 200) {
            return res;

        } else return false;
    }

    watch(
        () => ( estimate?.estimate?.info?.dealTypeCode ),
        () => {
            console.log('change dealTypeCode')
            const dealTypeCode =  estimate?.estimate?.info?.dealTypeCode;
            if (dealTypeCode == 'b2c') {
                data.clientType = 'B2C';
                data.currentDiscount.price_code = 'b2c';
            } else if (dealTypeCode == 'cost') {
                data.clientType = 'cost';
                data.currentDiscount.price_code = 'cost';
            } else {
                data.clientType = 'B2B';
                data.currentDiscount.price_code = 'b2b';
            }

            initCurrentPriceRule();
            initCurrentMultiplicator();


            return data.clientType;
        },
    );

    return{
        data,
        getDiscount,
        initCurrentPriceRule,
        initCurrentMultiplicator,
        getDiscountPercent,
        loadDiscount
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(usePriceStore, import.meta.hot),
    );
}
