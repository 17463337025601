import { DeliveryInfo, Estimate, EstimateInfo } from "../models/estimate";
import { getFromDictByCode, getTitleFromDictByCode, useDictStore } from "../stores/dictStore";
import { createPinia, setActivePinia } from "pinia";
import { SummaryBlock, SummaryHeader, SummaryLine } from "../models/summary";
import { addTextToText, formatDateTime, formatDate, generateCode } from "../utils/common";
import {
    AdditionalCostItem,
    Dimensions,
    HardwareItem,
    OneDimension,
    Structure,
    StructureItem
} from "../models/structure";
import { CalculationItem } from "../models/calculate";
import { CalculateService } from "./calculateService";
import { usePriceStore } from "../stores/priceStore";

setActivePinia(createPinia());
const dicts = useDictStore();
export class SummaryService {

    static blockFactory(data: object = null): SummaryBlock {
        return <SummaryBlock>{
            ...<SummaryBlock>{
                code: generateCode(),
                type: null,
                objectType: null,
                objectCode: null,
                style: null,
                title: '',
                description: '',
                header: this.headerFactory(),
                footer: this.headerFactory(),
                lines: [],
                subBlocks: [],
            }, ...data
        }
    }

    static lineFactory(data: object = null): SummaryLine {
        return <SummaryLine>{
            ...<SummaryLine>{
                code: generateCode(),
                type: null,
                objectType: null,
                objectCode: null,
                style: null,
                title: null,
                qty: null,
                price: null,
                priceB2C: null,
                priceB2B: null,
                sum: null,
                sumB2C: null,
                sumB2B: null,
            }, ...data
        }
    }

    static headerFactory(data: object = null): SummaryHeader {
        return <SummaryHeader>{
            ...this.lineFactory({
                ...{
                    type: 'header',
                    priceTitle: null,
                    qtyTitle: null,
                    sumTitle: null,
                }, ...data
            })
        }
    }

    static headerFactoryQtySum(data: object = null): SummaryHeader {
        const priceCode = usePriceStore().data.currentPriceKey;
        return this.headerFactory({
            ...{
                title: '',
                qtyTitle: 'Q-ty',
                priceTitle: priceCode,
                sumTitle: 'SUM',
            }, ...data
        });
    }

    static fromEstimate(estimate: Estimate, calculation: CalculationItem): SummaryBlock {
        const dictStore = useDictStore();
        const estimateSummary = this.blockFactory({
            type: 'estimate',
            title: `System: ${getTitleFromDictByCode(dictStore.getSystemTypes(), estimate.info.installedSystemCode)} - PRODUCT DESCRIPTION and AMOUNT` + ' Track#: ' + estimate.info.trackCode + ' Estimate#: ' + estimate.info.estimateNo,
            description: 'DESCRIPTIONS FOR MANAGERS and copying to the HUB SPOT',
        })
        const validatedPage = estimate.progress.validatedPage;

        // add blocks
        estimateSummary.subBlocks.push(this.getGeneralInfoBlock(estimate.info));

        if (validatedPage >= 2) {
            for (const space of estimate.spaces) {
                const spaceSummary = this.blockFactory({
                    type: 'space',
                    objectType: 'space',
                    objectCode: space.code,
                    title: space.title,
                })
                for (const structure of space.structures) {
                    const calculateItem = CalculateService.getSubitemByCode(calculation, structure.code, 'structure');
                    spaceSummary.subBlocks.push(this.getStructure(structure, calculateItem));
                }

                estimateSummary.subBlocks.push(spaceSummary);
            }
        }
        // добавляем доп расходы если дошли до 6 страницы
        if (validatedPage >= 6) {
            estimateSummary.subBlocks.push(this.getOverheadStructure(
                estimate.overhead,
                CalculateService.getSubitemByCode(calculation, 'overheads')
            ))
        }
        // добавляем доставку если дошли до 6 страницы
        if (validatedPage >= 7) {
            estimateSummary.subBlocks.push(this.getDeliveryStructure(
                estimate.delivery,
                CalculateService.getSubitemByCode(calculation, 'delivery')
            ))
        }

        return estimateSummary;
    }

    static getGeneralInfoBlock(info: EstimateInfo): SummaryBlock {
        let estimatorValue = '';
        if (typeof info.estimatorCode === 'object') {
            estimatorValue = info.estimatorCode?.code;
        } else {
            estimatorValue = info.estimatorCode;
        }
        const infoBlock = this.blockFactory({
            type: 'info',
            objectType: 'info',
            objectCode: info.code,
            title: 'General Information',
            header: null,
            lines: [
                this.lineFactory({
                    type: 'keyval',
                    title: 'Estimate started',
                    value: formatDateTime(<number>info.started)
                }),
                this.lineFactory({
                    type: 'keyval',
                    title: 'Price Date',
                    value: formatDate(<number>info.priceDate)
                }),
                this.lineFactory({type: 'keyval', title: 'Usage', value: getFromDictByCode(dicts.getInstallationUsage(), info.installationUsageCode)?.title}),
                this.lineFactory({
                    type: 'keyval',
                    title: 'Finish (color)',
                    value: getFromDictByCode(dicts.getFinishColors(), info.colorCode)?.title
                }),
                this.lineFactory({type: 'empty'}),
                this.lineFactory({type: 'keyval', title: 'First and Last name (in full)', value: info.client.name}),
                this.lineFactory({
                    type: 'keyval',
                    title: 'Business name (if applicable)',
                    value: info.client.businessName
                }),
                this.lineFactory({type: 'keyval', title: 'Email', value: info.client.email}),
                this.lineFactory({type: 'keyval', title: 'Phone', value: info.client.phone}),
                this.lineFactory({type: 'keyval', title: 'Billing address', value: info.client.billingAddress ? info.client.billingAddress : info.client.shippingAddress}),
                this.lineFactory({type: 'keyval', title: 'Shipping Address', value: addTextToText(info.shippingState ?? '', info.shippingZip ?? '', ', ') + ' ' + info.client.shippingAddress}),
                this.lineFactory({type: 'empty'}),
                this.lineFactory({type: 'keyval', title: 'Delivery', value: (info.delivery === 'true') ? "yes" : "no"}),
                this.lineFactory({type: 'keyval', title: 'Installation', value: (info.installation === 'true') ? "yes" : "no"}),
                this.lineFactory({
                    type: 'keyval',
                    title: 'Manager',
                    value: info.managerCode?.title
                }),
                this.lineFactory({
                    type: 'keyval',
                    title: 'Estimator',
                    value: estimatorValue
                }),
            ]
        });

        return infoBlock;
    }

    static getStructure(structure: Structure, calculationItem: CalculationItem): SummaryBlock[] {
        const summaryBlocks = this.getPartitionStructure(
            structure.partition,
            CalculateService.getSubitemByCode(calculationItem, structure.partition.code, 'partition')
        );
        for (const door of structure.doors) {
            summaryBlocks.subBlocks.push(this.getDoorStructure(
                door,
                CalculateService.getSubitemByCode(calculationItem, door.code, 'door')
            ));
        }
        for (const filler of structure.fillers) {
            switch (filler.structureTypeCode) {
                case 'filler':
                    summaryBlocks.subBlocks.push(this.getFillerStructure(filler, CalculateService.getSubitemByCode(calculationItem, filler.code, 'filler')));
                    break;
                case 'transom':
                    summaryBlocks.subBlocks.push(this.getTransomStructure(filler, CalculateService.getSubitemByCode(calculationItem, filler.code, 'filler')));
                    break;
                default: // none
                    summaryBlocks.subBlocks.push(this.blockFactory({
                        type: 'filler',
                        code: structure.code,
                        title: 'Filler and transom: none',
                        header: this.headerFactoryQtySum({title: 'Filler and transom: none'}),
                    }))
            }
        }
        // для обратной совместимости, когда mounts могли быть массивом
        if (structure.mounts) {
            if (Array.isArray(structure.mounts)) {
                for (const mount of structure.mounts) {
                    summaryBlocks.subBlocks.push(this.getMountsStructure(mount, CalculateService.getSubitemByCode(calculationItem, mount.code, 'mounts')));
                }
            } else {
                summaryBlocks.subBlocks.push(this.getMountsStructure(structure.mounts, CalculateService.getSubitemByCode(calculationItem, structure.mounts.code, 'mounts')));
            }
        }
        return summaryBlocks;
    }

    static getPartitionStructure(partition: StructureItem, calculationItem: CalculationItem): SummaryBlock {
        const block = this.blockFactory({
            type: 'partition',
            title: partition.title,
            objectType: 'partition',
            objectCode: partition.code,
            header: this.headerFactoryQtySum({
                title: partition.title,
                objectType: 'partition',
                objectCode: partition.code,
            }),
            lines: [
                this.lineFactory({
                    type: 'keyval',
                    title: ['Partition;', 'Dimensions:', dimensionsToText(partition.dimensions), ' ', "Square:", calculateClearArea(partition.dimensions),' ', 'Ft.'].join(' '),
                }),


                this.lineFactory(<SummaryLine>{
                    type: 'qtysum',
                    title: [
                        'Glass:',
                        getFromDictByCode(dicts.getGlassThickness(), partition.glassThicknessCode)?.title,
                        getFromDictByCode(dicts.getGlassSorts(), partition.glassSortCode)?.title
                    ].join(' '),
                    price: CalculateService.getSubitemByCode(calculationItem, partition.code).subitems.sum, // цену partition берем из суммы элементов
                    priceB2C: CalculateService.getSubitemByCode(calculationItem, partition.code).subitems.sumB2C,
                    priceB2B: CalculateService.getSubitemByCode(calculationItem, partition.code).subitems.sumB2B,
                    qty: CalculateService.getSubitemByCode(calculationItem, partition.code).qty,
                    sum: CalculateService.getSubitemByCode(calculationItem, partition.code).sum,
                    sumB2C: CalculateService.getSubitemByCode(calculationItem, partition.code).sumB2C,
                    sumB2B: CalculateService.getSubitemByCode(calculationItem, partition.code).sumB2B,
                }),
            ],
        })

        if (partition.outOfSquareQty > 0) {
            block.lines.push(this.lineFactory({
                    type: 'qtysum',
                    title: 'Out of square',
                    qty: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare').qty,
                    price:  CalculateService.getSubitemByCode(calculationItem, 'outOfSquare')?.price,
                    priceB2C:  CalculateService.getSubitemByCode(calculationItem, 'outOfSquare')?.priceB2C,
                    priceB2B:  CalculateService.getSubitemByCode(calculationItem, 'outOfSquare')?.priceB2B,
                    sum: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare').sum,
                    sumB2C: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare').sumB2C,
                    sumB2B: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare').sumB2B,
                })
            )
        }
        return block;
    }

    static getDoorStructure(door: StructureItem, calculationItem: CalculationItem): SummaryBlock {
        // no door
        if (door.structureTypeCode === 'dr-none') {
            return this.blockFactory({
                type: 'door',
                title: 'DOOR',
                objectType: 'door',
                objectCode: door.code,
                header: this.headerFactoryQtySum({
                    title: 'Door: ' + getFromDictByCode(dicts.getDoorTypes(), door.structureTypeCode)?.title,
                    objectType: 'door',
                    objectCode: door.code,
                }),
                lines: []
            })
        }

        // any door
        const doorBlock: SummaryBlock = this.blockFactory({
            type: 'door',
            title: 'DOOR',
            objectType: 'door',
            objectCode: door.code,
            header: this.headerFactoryQtySum({
                title: 'Door: ' + getFromDictByCode(dicts.getDoorTypes(), door.structureTypeCode)?.title,
                objectType: 'door',
                objectCode: door.code,
            }),
            lines: [
                this.lineFactory({
                    type: 'keyval',
                    title: [
                        'Door type:', getFromDictByCode(dicts.getDoorTypes(), door.structureTypeCode)?.title
                    ].join(' '),
                }),
                this.lineFactory({
                    type: 'keyval',
                    title: ['Dimensions:', dimensionsToText(door.dimensions)].join(' '),
                }),
                this.lineFactory({
                    type: 'qtysum',
                    title: [
                        'Glass:',
                        getFromDictByCode(dicts.getGlassThickness(), door.glassThicknessCode)?.title,
                        getFromDictByCode(dicts.getGlassSorts(), door.glassSortCode)?.title
                    ].join(' '),
                    price: CalculateService.getSubitemByCode(calculationItem, 'glass')?.price,
                    priceB2C: CalculateService.getSubitemByCode(calculationItem, 'glass')?.priceB2C,
                    priceB2B: CalculateService.getSubitemByCode(calculationItem, 'glass')?.priceB2B,
                    qty: CalculateService.getSubitemByCode(calculationItem, 'glass').qty,
                    sum: CalculateService.getSubitemByCode(calculationItem, 'glass').sum,
                    sumB2C: CalculateService.getSubitemByCode(calculationItem, 'glass').sumB2C,
                    sumB2B: CalculateService.getSubitemByCode(calculationItem, 'glass').sumB2b,
                }),
            ],
        });
        for (const hardware: HardwareItem of door.hardwares) {
            doorBlock.lines.push(this.lineFactory({
                type: 'qtysum',
                title: [
                    getFromDictByCode(dicts.getHardwareTypes('door'), hardware.hardwareTypeCode)?.title + ':',
                    getFromDictByCode(dicts.getHardwareValues(hardware.hardwareTypeCode), hardware.hardwareValueCode)?.title
                ].join(' '),
                qty: CalculateService.getSubitemByCode(calculationItem, hardware.code).qty,
                price: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.price,
                priceB2C: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.priceB2C,
                priceB2B: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.priceB2B,
                sum: CalculateService.getSubitemByCode(calculationItem, hardware.code).sum,
                sumB2C: CalculateService.getSubitemByCode(calculationItem, hardware.code).sumB2C,
                sumB2B: CalculateService.getSubitemByCode(calculationItem, hardware.code).sumB2B,
            }))
        }
        return doorBlock;
    }

    static getFillerStructure(filler: StructureItem, calculationItem: CalculationItem): SummaryBlock {
        const block: SummaryBlock = this.blockFactory({
            type: 'filler',
            title: 'Filler',
            objectType: 'filler',
            objectCode: filler.code,
            header: this.headerFactoryQtySum({
                title: getFromDictByCode(dicts.getStructureTypes('filler'), filler.structureTypeCode)?.title,
                objectType: 'filler',
                objectCode: filler.code,
            }),
            lines: [
                this.lineFactory({
                    type: 'qtysum',
                    title: [
                        'Type:',
                        getFromDictByCode(dicts.getStructureTypes('filler'), filler.structureTypeCode)?.title,
                        //getFromDictByCode(dicts.getFillerTypes(), filler.fillerTypeCode)?.title
                    ].join(' '),
                }),
                this.lineFactory({
                    type: 'qtysum',
                    title: ['Dimensions:', dimensionsToText(filler.dimensions)].join(' '),
                }),
                this.lineFactory({
                    type: 'qtysum',
                    title: [
                        'Material:',
                        getFromDictByCode(dicts.getAluminumMaterialTypes(), filler.fillerMaterialCode)?.title
                    ].join(' '),
                    price: CalculateService.getSubitemByCode(calculationItem, filler.code)?.price,
                    priceB2C: CalculateService.getSubitemByCode(calculationItem, filler.code)?.priceB2C,
                    priceB2B: CalculateService.getSubitemByCode(calculationItem, filler.code)?.priceB2B,
                    qty: CalculateService.getSubitemByCode(calculationItem, filler.code).qty,
                    sum: CalculateService.getSubitemByCode(calculationItem, filler.code).sum,
                    sumB2C: CalculateService.getSubitemByCode(calculationItem, filler.code).sumB2C,
                    sumB2B: CalculateService.getSubitemByCode(calculationItem, filler.code).sumB2B,
                }),
                this.lineFactory({
                    type: 'qtysum',
                    title: 'Out of square',
                    price: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare')?.price,
                    priceB2C: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare')?.priceB2C,
                    priceB2B: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare')?.priceB2B,
                    qty: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare').qty,
                    sum: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare').sum,
                    sumB2C: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare').sumB2C,
                    sumB2B: CalculateService.getSubitemByCode(calculationItem, 'outOfSquare').sumB2B,
                }),
                this.lineFactory({
                    type: 'qtysum',
                    title: 'Assets',
                    price: CalculateService.getSubitemByCode(calculationItem, 'assets')?.price,
                    priceB2C: CalculateService.getSubitemByCode(calculationItem, 'assets')?.priceB2C,
                    priceB2B: CalculateService.getSubitemByCode(calculationItem, 'assets')?.priceB2B,
                    qty: CalculateService.getSubitemByCode(calculationItem, 'assets').qty,
                    sum: CalculateService.getSubitemByCode(calculationItem, 'assets').sum,
                    sumB2C: CalculateService.getSubitemByCode(calculationItem, 'assets').sumB2C,
                    sumB2B: CalculateService.getSubitemByCode(calculationItem, 'assets').sumB2B,
                }),
            ],
        });

        return block;
    }

    static getTransomStructure(transom: StructureItem, calculationItem: CalculationItem): SummaryBlock {
        const block: SummaryBlock = this.blockFactory({
            type: 'transom',
            title: 'Transom',
            objectType: 'transom',
            objectCode: transom.code,
            header: this.headerFactoryQtySum({
                title: 'Transom',
                objectType: 'transom',
                objectCode: transom.code,
            }),
            lines: [
                this.lineFactory({
                    type: 'keyval',
                    title: [
                        'Type',
                        getFromDictByCode(dicts.getTransomTypes(), transom.fillerTypeCode)?.title
                    ].join(' '),
                }),
                this.lineFactory({
                    type: 'keyval',
                    title: ['Dimensions:', dimensionsToText(transom.dimensions)].join(' '),
                }),
                this.lineFactory({
                    type: 'qtysum',
                    title: [
                        'Glass:',
                        getFromDictByCode(dicts.getGlassSorts(), transom.glassSortCode)?.title,
                        getFromDictByCode(dicts.getGlassThickness(), transom.glassThicknessCode)?.title
                    ].join(' '),
                    price:  CalculateService.getSubitemByCode(calculationItem, 'glass')?.price,
                    priceB2C:  CalculateService.getSubitemByCode(calculationItem, 'glass')?.priceB2C,
                    priceB2B:  CalculateService.getSubitemByCode(calculationItem, 'glass')?.priceB2B,
                    qty: CalculateService.getSubitemByCode(calculationItem, 'glass')?.qty,
                    sum:  CalculateService.getSubitemByCode(calculationItem, 'glass')?.sum,
                    sumB2C:  CalculateService.getSubitemByCode(calculationItem, 'glass')?.sumB2C,
                    sumB2B:  CalculateService.getSubitemByCode(calculationItem, 'glass')?.sumB2B,
                }),
            ],
        });

        for (const hardware: HardwareItem of transom.hardwares) {
            block.lines.push(this.lineFactory({
                type: 'qtysum',
                title: [
                    getFromDictByCode(dicts.getHardwareTypes('transom'), hardware.hardwareTypeCode)?.title + ':',
                    getFromDictByCode(dicts.getHardwareValues(hardware.hardwareTypeCode), hardware.hardwareValueCode)?.title
                ].join(' '),
                price: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.price,
                priceB2C: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.priceB2C,
                priceB2B: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.priceB2B,
                qty: CalculateService.getSubitemByCode(calculationItem, hardware.code).qty,
                sum: CalculateService.getSubitemByCode(calculationItem, hardware.code).sum,
                sumB2C: CalculateService.getSubitemByCode(calculationItem, hardware.code).sumB2C,
                sumB2B: CalculateService.getSubitemByCode(calculationItem, hardware.code).sumB2B,
            }))
        }

        return block;
    }

    static getMountsStructure(mounts: StructureItem, calculationItem: CalculationItem): SummaryBlock {
        const block: SummaryBlock = this.blockFactory({
            type: 'mounts',
            title: 'CHANEL, TRACKS, PROFILES',
            objectType: 'mounts',
            objectCode: mounts.code,
            header: this.headerFactoryQtySum({
                title: 'CHANEL, TRACKS, PROFILES',
                objectType: 'mounts',
                objectCode: mounts.code,

            }),
            lines: [],
        });
        for (const hardware: HardwareItem of mounts.hardwares) {
            block.lines.push(this.lineFactory({
                type: 'qtysum',
                title: [
                    getFromDictByCode(dicts.getHardwareTypes('mounts'), hardware.hardwareTypeCode)?.title + ':',
                    getFromDictByCode(dicts.getHardwareValues(hardware.hardwareTypeCode), hardware.hardwareValueCode)?.title
                ].join(' '),
                qty: CalculateService.getSubitemByCode(calculationItem, hardware.code).qty,
                price: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.price,
                priceB2C: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.priceB2C,
                priceB2B: CalculateService.getSubitemByCode(calculationItem, hardware.code)?.priceB2B,
                sum: CalculateService.getSubitemByCode(calculationItem, hardware.code).sum,
                sumB2C: CalculateService.getSubitemByCode(calculationItem, hardware.code).sumB2C,
                sumB2B: CalculateService.getSubitemByCode(calculationItem, hardware.code).sumB2B,
            }))
        }
        return block;
    }


    static getOverheadStructure(overheads: AdditionalCostItem[], calculationItem: CalculationItem): SummaryBlock {
        const block: SummaryBlock = this.blockFactory({
            type: 'overhead',
            title: 'Overhead costs',
            objectType: 'overhead',
            header: this.headerFactoryQtySum({
                title: 'Overhead costs',
                objectType: 'overhead',
            }),
            lines: [],
        });
        for (const costs: AdditionalCostItem of overheads) {
            block.lines.push(this.lineFactory({
                type: 'qtysum',
                title: [
                    getFromDictByCode(dicts.getAdditionalCosts(), costs.costTypeCode)?.title + ':',
                    getFromDictByCode(dicts.getAdditionalCostValues(costs.costTypeCode), costs.costValueCode)?.title
                ].join(' '),
                price: CalculateService.getSubitemByCode(calculationItem, costs.code)?.price,
                priceB2C: CalculateService.getSubitemByCode(calculationItem, costs.code)?.priceB2C,
                priceB2B: CalculateService.getSubitemByCode(calculationItem, costs.code)?.priceB2B,
                qty: CalculateService.getSubitemByCode(calculationItem, costs.code).qty,
                sum: CalculateService.getSubitemByCode(calculationItem, costs.code).sum,
                sumB2C: CalculateService.getSubitemByCode(calculationItem, costs.code).sumB2C,
                sumB2B: CalculateService.getSubitemByCode(calculationItem, costs.code).sumB2B,
            }))
        }
        return block;
    }

    static getDeliveryStructure(deliveryInfo: DeliveryInfo, calculationItem: CalculationItem): SummaryBlock {
        const block: SummaryBlock = this.blockFactory({
            type: 'delivery',
            objectType: 'delivery',
            title: 'Delivery and installation',
            header: this.headerFactoryQtySum({
                title: 'Delivery and installation',
                objectType: 'delivery',
            }),
            lines: [
                this.lineFactory({
                    type: 'keyval',
                    title: 'Address(state)',
                    value: [
                        deliveryInfo.deliveryAddress.country,
                        deliveryInfo.deliveryAddress.state,
                        deliveryInfo.deliveryAddress.zip,
                    ].join(', '),
                }),
                this.lineFactory({
                    type: 'keyval',
                    title: 'Address',
                    value: deliveryInfo.deliveryAddress.address,
                }),
            ],
        });

        block.lines.push(this.lineFactory({type: 'empty'}));

        for (const costs: AdditionalCostItem of deliveryInfo.deliveryCosts) {
            block.lines.push(this.lineFactory({
                type: 'qtysum',
                title: [
                    getFromDictByCode(dicts.getAdditionalCosts('delivery'), costs.costTypeCode)?.title + ':',
                    getFromDictByCode(dicts.getAdditionalCostValues(costs.costTypeCode), costs.costValueCode)?.title
                ].join(' '),
                qty: CalculateService.getSubitemByCode(calculationItem, costs.code).qty,
                price: CalculateService.getSubitemByCode(calculationItem, costs.code)?.price,
                priceB2C: CalculateService.getSubitemByCode(calculationItem, costs.code)?.priceB2C,
                priceB2B: CalculateService.getSubitemByCode(calculationItem, costs.code)?.priceB2B,
                sum: CalculateService.getSubitemByCode(calculationItem, costs.code).sum,
                sumB2C: CalculateService.getSubitemByCode(calculationItem, costs.code).sumB2C,
                sumB2B: CalculateService.getSubitemByCode(calculationItem, costs.code).sumB2B,
            }))
        }

        block.lines.push(this.lineFactory({type: 'empty'}));

        for (const costs: AdditionalCostItem of deliveryInfo.extraCosts) {
            block.lines.push(this.lineFactory({
                type: 'qtysum',
                title: getFromDictByCode(dicts.getHardwareValues(), costs.costValueCode)?.title,
                qty: CalculateService.getSubitemByCode(calculationItem, costs.code).qty,
                price: CalculateService.getSubitemByCode(calculationItem, costs.code)?.price,
                priceB2C: CalculateService.getSubitemByCode(calculationItem, costs.code)?.priceB2C,
                priceB2B: CalculateService.getSubitemByCode(calculationItem, costs.code)?.priceB2B,
                sum: CalculateService.getSubitemByCode(calculationItem, costs.code).sum,
                sumB2C: CalculateService.getSubitemByCode(calculationItem, costs.code).sumB2C,
                sumB2B: CalculateService.getSubitemByCode(calculationItem, costs.code).sumB2B,
            }))
        }
        return block;
    }


}


export function oneDimensionToText(dimension: OneDimension): string {
    const parts = [];

    if (dimension.ft + dimension.inch + dimension.fractionA + dimension.fractionB === 0) {
        return '0';
    }
    if (dimension.ft !== null) {
        parts.push(dimension.ft?.toString() + '\'')
    }
    if (dimension.inch !== null) {
        parts.push(dimension.inch?.toString() + ((dimension?.fractionA + dimension?.fractionB)  ? '"' : ''))
    }
    if (dimension.fractionA + dimension.fractionB !== 0) {
        parts.push(dimension.fractionA?.toString() + '/' + dimension.fractionB?.toString() + '"');
    }
    return parts.join(' ');
}

export function dimensionsToText(dimensions: Dimensions): string {
    return oneDimensionToText(dimensions.width) + 'W x '
        + oneDimensionToText(dimensions.height) + 'H';
}


export function calculateClearArea(dimensions: Dimensions): number {
    const heightInches = dimensions.height.ft * 12 + dimensions.height.inch;
    const widthInches = dimensions.width.ft * 12 + dimensions.width.inch;

    const areaInSquareInches = widthInches * heightInches;
    const areaInSquareFeet = areaInSquareInches / 144;

    return parseFloat(areaInSquareFeet.toFixed(2));
}

