import "./bootstrap";
import { createApp } from "vue";
import '@/lib/axios'
import App from "./App.vue";
import router from "./router";
import "./assets/css/normalize.css";
import "./assets/css/reset.css";
import "./assets/css/style.css";
import { createPinia } from "pinia";
import Notifications from '@kyvg/vue3-notification'
import vSelect from "vue-select";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'


const app = createApp(App);
app.component("v-select", vSelect);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)


app.use(pinia);
app.use(router);
app.use(Notifications);

app.mount("#app");
