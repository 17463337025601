<template>
    <template v-if="summary">
        <div class="step__right-head">
            <h2>Estimate summary</h2>
            <span>square: {{ formatDimension(totalArea) }} ft.</span>
            <span v-if="!isManager">{{ formatMoney(calculation?.sumOrig) }}</span>
            <span v-else >{{ formatMoney(estimateStore.estimate.info.subtotal) }}</span>
        </div>
        <div v-if="summary.type==='estimate'" class="step__info">
            <h3 class="step__info-title">{{ summary.description }}</h3>
            <p class="step__info-description">{{ summary.title }}</p>
        </div>
        <template v-for="block in summary.subBlocks" v-bind:key="block.objectCode">
            <ul class="step__accordion">
                <li class="step__accordion-item" :data-code="block.objectCode">
                    <SummaryBlock
                        :summary-block="block"
                        @header-click-edit="headerClickEdit"
                        @header-click-delete="headerClickDelete"
                    >
                    </SummaryBlock>
                </li>
            </ul>
        </template>
    </template>
</template>

<script setup lang="ts">
import SummaryBlock from "./UI/infoEstimate/SummaryBlock.vue";
import {useEstimateStore} from "../stores/estimateStore";
import {useUserStore} from "../stores/userStore";
import {cloneDeep} from 'loadsh';
import {reactive, Ref, ref, watch} from "vue";
import {useUiStore,} from "../stores/uiStore";
import {formatDimension, formatMoney} from "../utils/common";
import {SummaryBlock as SummaryBlockItem} from "../models/summary"
import {CalculationItem} from "../models/calculate"
import {EstimateCalulatorBuilder} from "../services/calculateService";
import {SummaryService} from "../services/summaryService";
import {EstimateService} from "../services/estimateService";

defineExpose({reloadSummary});

const isManager = useUserStore().isSalesManager;

const estimateStore = useEstimateStore();

const uiStore = useUiStore();

let summary: SummaryBlockItem = reactive({value: null});

const totalArea = ref(EstimateService.calculateEstimatePartitionsArea(estimateStore.estimate));

// fixme no need to recalc, need use from estimate
const calculation: Ref<CalculationItem> = ref(null);

const accordionStatus = ref(uiStore.summaryAccordeonStatus);

reloadSummary();

reloadSquare();

function reloadSummary() {
    console.debug('reloadSummary');
    saveAccordeonStatus();
    const calc = EstimateCalulatorBuilder.fromEstimate(useEstimateStore().estimate);
    calculation.value = cloneDeep(calc);
    // summary.value = cloneDeep(estimateStore.getSummary());
    summary = SummaryService.fromEstimate(useEstimateStore().estimate, calc);
    console.debug('reload summary', summary.value);
    loadAccordeonStatus();
}

function reloadSquare() {
    // console.log('reload square!!!')
    totalArea.value = EstimateService.calculateEstimatePartitionsArea(estimateStore.estimate);
}
watch(() => uiStore.squareRecalculateFlag, () => {
    reloadSquare();
})

watch(() => uiStore.summaryRecalculateFlag, () => {
    reloadSummary();
})


function headerClickEdit(blockType: string, blockCode: string) {
    console.debug('click on edit', blockType, blockCode);
    estimateStore.setActiveElementByType(blockType, blockCode);
    uiStore.changePageByType(blockType);
}

function headerClickDelete(blockType: string, blockCode: string) {
    console.debug('click on delete', blockType, blockCode);
    estimateStore.deleteElementByType(blockType, blockCode);
    reloadSummary();
}

function saveAccordeonStatus() {
    accordionStatus.value = []
    document.querySelectorAll('.step__accordion-item.step__accordion-item--active').forEach((item) => {
        if (item.dataset.code) {
            accordionStatus.value.push(item.dataset.code);
        }
    });
}

function loadAccordeonStatus() {
    accordionStatus.value?.forEach((code) => {
        document.querySelector(`.step__accordion-item[data-code="${code}"]`).classList.add('step__accordion-item--active');
    })
}

</script>

<style scoped>
.step__accordion-block-count span {
    padding-right: 15px;
    width: 15px;
    text-align: center;
}

.step__right-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 15px;
}

.step__info {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.step__info-title {
    color: #2491eb;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}

.step__info-description {
    color: #fff;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

:deep(.step__accordion-total) {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

:deep(.step__accordion-block-list) {
    display: grid;
    row-gap: 10px;
}

:deep(.step__accordion-block-dot) {
    width: 2px;
    height: 2px;
    background-color: #fff;
    border-radius: 55px;
    margin-top: 6px;
    /* align-self: center; */
}

:deep(.step__accordion-block-right) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 142px;
    column-gap: 10px;
}

:deep(.step__accordion-block-right div) {
    width: 50%;
    text-align: right;
}

:deep(.step__accordion-block-item) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}

:deep(.step__accordion-block-left) {
    display: grid;
    grid-template-columns: minmax(0, max-content) 1fr;
    column-gap: 10px;
}

.step__accordion-block-left h3 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

:deep(.step__accordion-block-head) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
    margin-top: 20px;
}

.step__accordion-block-head h3 {
    text-transform: uppercase;
}

:deep(.step__accordion-block-container) {
    display: flex;
    align-items: center;
    column-gap: 31px;
}

:deep(.step__accordion-block-units) {
    display: flex;
    align-items: center;
    column-gap: 46px;
}

:deep(.step__accordion-svg) {
    position: relative;
    transform: rotate(0);
    transition: 0.5s ease;
}

:deep(.step__accordion-item--active .step__accordion-svg) {
    transform: rotate(-180deg);
    transition: 0.5s ease;
}

:deep(.step__accordion-head) {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 15px 0;
    column-gap: 20px;
    /*  */
    cursor: pointer;
}

.step__accordion-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

:deep(.step__accordion-overlay) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
}

:deep(.step__accordion-body) {
    display: none;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.step__accordion-item--active.step__accordion-item--office :deep(.step__accordion-body) {
    border-top: none;
    padding-top: 10px;
}

.step__accordion-item--active.step__accordion-item--office :deep(.step__accordion-wrapper) {
    display: grid;
    row-gap: 35px;
}

.step__accordion-item--active :deep(.step__accordion-body) {
    display: block;
}

.step__accordion-item--active :deep(.step__accordion-body.step__accordion-body--general) {
    display: grid;
    row-gap: 30px;
}

:deep(.step__accordion-body--general ul) {
    display: grid;
    row-gap: 10px;
}

:deep(.edit-button) {
    cursor: pointer;
    text-align: center;
    font-size: 10px;
    padding-bottom: 1px;
    text-decoration: none;
    border-bottom: thin solid;
    margin-bottom: 2px;
}

:deep(.edit-button svg) {
    width: 12px;
    margin-left: 5px;
}

:deep(.edit-button a) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

:deep(.delete-button) {
    cursor: pointer;
    text-align: center;
    font-size: 10px;
    padding-bottom: 1px;
    text-decoration: none;
    border-bottom: thin solid;
    margin-bottom: 2px;
}

:deep(.delete-button svg) {
    width: 12px;
    margin-left: 5px;
}

:deep(.delete-button a) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
</style>
