import {
    DeliveryInfo,
    Estimate,
    EstimateInfo,
    ProgressInfo,
    EstimatedSpace,
    Address,
} from "../models/estimate";
import { AdditionalCostItem, Dimensions, HardwareItem, Structure, StructureItem } from "../models/structure";
import { Client } from "../models/client";
import { calcAreaFt, dimensionToFt, generateCode } from "../utils/common";
import { useDictStore } from "../stores/dictStore";
import { merge } from "lodash";
import {useUserStore} from "../stores/userStore";
import {usePriceStore} from "../stores/priceStore";
import dayjs from "dayjs";


export class EstimateService {
    private estimate: Estimate

    constructor(estimate: Estimate) {
        this.estimate = estimate;
    }

    static findSpacesForElementByCode(spaces: EstimatedSpace[], elementCode: string): string | null {
        let structureCode: string | null = null;
        for (const space of spaces) {
            structureCode = this.findStructuresForElementByCode(space.structures, elementCode);
            if (structureCode) return space.code;
        }
        return null;
    }

    static findStructuresForElementByCode(structures: Structure[], elementCode: string): string {
        for (const structure of structures) {
            if (structure.code === elementCode) return structure.code;
            if (structure.partition.code === elementCode) return structure.code;
            if (structure.doors && structure.doors.find((item => item.code === elementCode))) return structure.code;
            if (structure.fillers && structure.fillers.find((item => item.code === elementCode))) return structure.code;
            if (structure.mounts.code === elementCode) return structure.code;
        }
        return null;
    }


    static estimateFactory(data = {}): Estimate {
        const defaults = <Estimate>{
            info: this.estimateInfoFactory(),
            delivery: this.deliveryInfoFactory(),
            progress: this.progressInfoFactory(),
            spaces: [
                this.estimatedSpacesFactory()
            ],
            overhead: null,
        }
        return merge(defaults, data);
    }

    static estimateInfoFactory(data = {}): EstimateInfo {
        let defaults: EstimateInfo;
        defaults = {
            started: Date.now(),
            priceDate: Date.now(),
            priceDateFormated: dayjs().format('YYYY-MM-DD'),
            subtotal: null,
            estimatePrice: null,
            code: generateCode(),
            estimateNo: null,//dayjs().format('YYMMDD-hm'),
            trackCode: null,    //  generate track code
            title: '',
            client: this.clientFactory(),
            managerCode: null,
            estimatorCode: null,
            dealTypeCode: null,         // dict DealType
            installationPlaceCode: null, // dict InstallationPlace
            installationUsageCode: null, // dict
            installationPlaceTitle: null, //
            installedSystemCode: null, // dict SystemType
            usageCode: null,            // dict systemUsages
            colorCode: null,            // dict finishColor
            shippingAddress: null,
            shippingZip: null,
            shippingState: null,
            delivery: 'true',
            installation: 'false',
        };
        return <EstimateInfo>{...defaults, ...data};
    }

    static estimatedSpacesFactory(data = {}): EstimatedSpace {
        const defaults = <EstimatedSpace>{
            code: generateCode(),
            title: '',
            structures: [],
        }
        return <EstimatedSpace>{...defaults, ...data};
    }

    static deliveryInfoFactory(data = {}): DeliveryInfo {
        const priceStore = usePriceStore();
        priceStore.loadDiscount();
        const defaults = <DeliveryInfo>{
            deliveryAddress: <Address>{address: '', zip: '', country: 'USA', state: ''},
            deliveryCosts: [
                this.additionalCostItemFactory({
                    costTypeCode: 'dlvr-combine',
                    costValueCode: 'dlvr-combine-01'
                }),
                this.additionalCostItemFactory({
                    costTypeCode: 'dlvr-direct',
                    costValueCode: 'dlvr-direct-01',
                }),
                this.additionalCostItemFactory({
                    costTypeCode: 'dlvr-install',
                    costValueCode: 'dlvr-install-01'
                }),
            ],
            extraCosts: [],
            multiplicator: priceStore.data.multiplicator,
            discountPercent: priceStore.data.currentDiscount.discount_percent,
            discountSum: 0,
        }
        return {...defaults, ...data};
    }

    static progressInfoFactory(data = {}): ProgressInfo {
        const defaults = <ProgressInfo>{
            status: 'DRAFT',
            updated: new Date(),
            validatedPage: 0,
            activePage: 1,
            activeSpaceCode: null,
            activeStructureCode: null,
            activeMountsCode: null,
            isSaved: false,
        }
        return {...defaults, ...data};

    }

    static additionalCostItemFactory(data = {}): AdditionalCostItem {
        const defaults = <AdditionalCostItem>{
            code: generateCode(),
            costTypeCode: 'dlvr',    // dict costTypes
            costValueCode: null,
            title: null,
            description: null,
            unit: '',
            qty: null,
            price: 1,
            priceB2C: 1,
            priceB2B: 1,
            sum: null,
        }
        return {...defaults, ...data};
    }

    static clientFactory(data = {}): Client {
        const defaults = <Client>{
            name: null,
            billingAddress: null,
            phone: null,
            email: null,
            businessName: null,
            manager: null,
        }
        return {...defaults, ...data};
    }

    static structureFactory(data = {}): Structure {
        const defaults = <Structure>{
            code: generateCode(),
            partition: this.structureItemFactory({structureTypeCode: 'fxp', title: ''}),
            doors: [],
            fillers: [],   // transom and fillers
            mounts: [],
            additionalCosts: [],
        }
        return merge(defaults, data);
    }

    static structureItemFactory(data = {}): StructureItem {
        const defaults = <StructureItem>{
            id: null,
            code: generateCode(),
            structureTypeCode: null,
            title: null,
            qty: 1,
            dimensions: <Dimensions>{
                width: {ft: 0, inch: 0, fractionA: 0, fractionB: 0},
                height: {ft: 0, inch: 0, fractionA: 0, fractionB: 0},
                areaFt: 0,
                perimeterFt: 0,
            },
            glassSortCode: null, //fix bug for default value
            glassThicknessCode: null, //fix bug for default value
            hardwares: [],
            additionalCosts: [],
            outOfSquareQty: null,
            note: null,
            // filler specific
            fillerTypeCode: "",
            fillerMaterialCode: "",
            fillerAssetsQty: null,
        }
        const structure = merge(defaults, data);
        structure.dimensions.areaFt = calcAreaFt(structure.dimensions);
        structure.dimensions.perimeterFt = calcAreaFt(structure.dimensions);

        return structure;
    }

    static structureDoorFactory(data = {}, systemCode: string | null = null): StructureItem {

        // todo inject estimate
        let defaultData = {}

        if (isKompasSystem(systemCode)) {
            defaultData = merge(
                defaultData,
                {
                    glassSortCode: 'clear',
                    glassThicknessCode: '3-8',
                    hardware: this.doorHardwareFactory('dr-none')
                });

        }

        if (['romano', 'trevi'].includes(systemCode)) {
            defaultData = merge(defaultData, {glassSortCode: 'clear', glassThicknessCode: '3-16'});
        }

        defaultData = merge(defaultData,
            {
                dimensions: <Dimensions>{   // default door dimensions
                    width: {ft: 3, inch: 0, fractionA: 0, fractionB: 0},
                    height: {ft: 7, inch: 0, fractionA: 0, fractionB: 0}
                }
            });

        merge(
            defaultData,
            {hardwares: this.doorHardwareFactory('dr-none')}
        );

        return this.structureItemFactory(
            {...defaultData, ...data}
        );
    }

    static structureFillerFactory(data = {}): StructureItem {
        return this.structureItemFactory(
            {
                ...{
                    hardwares: this.fillerHardwareFactory(data?.structureTypeCode)
                }, ...data
            }
        );
    }

    static structureMountsFactory(data = {}): StructureItem {
        return this.structureItemFactory(
            {
                ...{
                    hardwares: [],
                }, ...data
            }
        );
    }

    static doorHardwareFactory(doorType: string): HardwareItem[] {
        const dictStore = useDictStore();
        // коэффициент двойной двери
        const qtyMulti = isDoubleDoor(doorType) ? 2 : 1;

         // общие правила
        switch (doorType) {
            case 's-sw':
            case 'd-sw':
                return [
                    // patches
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-patches',
                        hardwareValueCode: dictStore.getHardwareValues('dr-patches')[1].code,
                        qty: 1 * qtyMulti,
                    },
                    // handle
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-handle',
                        hardwareValueCode: dictStore.getHardwareValues('dr-handle')[1].code,
                        qty: 1 * qtyMulti,
                    },
                    // lock
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-lock',
                        hardwareValueCode: dictStore.getHardwareValues('dr-lock')[0].code,
                        qty: 1 * qtyMulti,
                    },

                ];

            case 's-sl':
            case 'd-sl':
                return [
                    // rollers
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-roller',
                        hardwareValueCode: dictStore.getHardwareValues('dr-roller')[1].code,
                        qty: 1 * qtyMulti,
                    },
                    // U-holder
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-uholder',
                        hardwareValueCode: dictStore.getHardwareValues('dr-uholder')[1].code,
                        qty: 1 * qtyMulti,
                    },
                    // handles
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-handle',
                        hardwareValueCode: dictStore.getHardwareValues('dr-handle')[1].code,
                        qty: 1 * qtyMulti,
                    },
                    // lock
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-lock',
                        hardwareValueCode: dictStore.getHardwareValues('dr-lock')[0].code,
                        qty: 1 * qtyMulti,
                    },

                    // no patches in sliding door
                    // <HardwareItem>{
                    //     code: generateCode(),
                    //     hardwareTypeCode: 'dr-patches',
                    //     hardwareValueCode: dictStore.getHardwareValues('dr-lock')[0].code,
                    //     qty: 1 * qtyMulti,
                    // },
                ];
            // kompas not have hardware, bu have prefubreshed door
            case "kmp-s":
                return [
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-door-kmp-s',
                        hardwareValueCode: dictStore.getHardwareValues('dr-door-kmp-s')[0].code,
                        qty: 1,
                    },
                ];

            case "kmp-d":
                return [
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'dr-door-kmp-d',
                        hardwareValueCode: dictStore.getHardwareValues('dr-door-kmp-d')[0].code,
                        qty: 1,
                    },
                ];
            // steel doors
            default:
                // dr-none
                return [];
        }
    }


    static doorAdditionalCostsFactory(structure: StructureItem): AdditionalCostItem[] {
        const additionalCosts: AdditionalCostItem[] = [];

        for (const hardware of structure.hardwares) {
            switch (hardware.hardwareTypeCode) {
                case "dr-lock" :
                    // если нет замка, пропускаем
                    if (hardware.hardwareValueCode === 'lock-none') {
                        break;
                    }
                    additionalCosts.push(this.additionalCostItemFactory({
                        code: hardware.hardwareTypeCode.concat('-', 'holes-01'),
                        costTypeCode: hardware.hardwareTypeCode,
                        costValueCode: 'holes-01',
                        qty: hardware.qty * 2
                    }));
                    break;
                case "dr-handle":
                    // если нет, пропускаем
                    if (hardware.hardwareValueCode === 'hnd-ls-none') {
                        break;
                    }
                    additionalCosts.push(this.additionalCostItemFactory({
                        code: hardware.hardwareTypeCode.concat('-', 'holes-01'),
                        costTypeCode: hardware.hardwareTypeCode,
                        costValueCode: 'holes-01',
                        qty: hardware.qty * 2
                    }));
                    break;
                case "dr-patches" :
                    if (isSlideDoor(structure.structureTypeCode)) {
                        break;
                    }
                    additionalCosts.push(this.additionalCostItemFactory({
                        code: hardware.hardwareTypeCode.concat('-', 'holes-01'),
                        costTypeCode: hardware.hardwareTypeCode,
                        costValueCode: 'holes-01',
                        qty: hardware.qty * 2
                    }));
                    break;
            }
        }
        return additionalCosts;
    }


    static fillerHardwareFactory(structureType: string): HardwareItem[] {
        switch (structureType) {
            case 'filler':
                return []

            case "transom":
                return [
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'tr-type',
                        hardwareValueCode: "",
                        qty: 1
                    },
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'tr-color',
                        hardwareValueCode: "",
                        qty: 1
                    },
                    <HardwareItem>{
                        code: generateCode(),
                        hardwareTypeCode: 'tr-hwset',
                        hardwareValueCode: "",
                        qty: 1
                    },
                ]

            default:
                return [];
        }

    }

    /**
     * @param {StructureItem} structure Структура, в которую будут добавлены элементы
     * @param {string} installedSystemCode   код системы
     * @param {StructureItem} partitionStructure         элемент partiotion
     * @param {StructureItem} doorStructure              элемент двери
     * @param {StructureItem|null} transomStructure       элемент transom
     */

    static mountsHardwareFactory(structure: StructureItem,
                                 installedSystemCode: string,
                                 partitionStructure: StructureItem,
                                 doorStructure: StructureItem,
                                 transomStructure: StructureItem|null = null): HardwareItem[] {
        // input data:
        // mountsTypeCode: mnt-perimeter mnt-topbottom mnt-none
        // doorTypeCode cg: s-sw d-sw s-sl d-sl kmp-s kmp-d trevi romano
        // doorTypeCode steel: st-s-sw st-d-sw  st-s-sl st-d-sl st-ml-sl st-fl st-s-ar st-d-ar
        const hardwareItems = [];

        const partitionTypeCode = partitionStructure.structureTypeCode;

        const mountsTypeCode = structure.structureTypeCode;

        const doorTypeCode = doorStructure.structureTypeCode;

        const doorOnly = false // calc process ???

        const noDoor = doorTypeCode === 'dr-none' || partitionTypeCode === 'div';   // признка что в проеме нет двери

        // calculations
        const doubleDoorCoef = isDoubleDoor(doorTypeCode) ? 2 : 1;      // коэффициент одиночная/двойная дверь
        const calcTotalWidthFt: number = dimensionToFt(partitionStructure.dimensions.width);    // ширина partition
        const calcTotalHeightFt: number = dimensionToFt(partitionStructure.dimensions.height);  // высота partition

        const calcDoorWidthFt: number = dimensionToFt(doorStructure.dimensions.width);          // ширина двери
        const calcDoorHeightFt: number = dimensionToFt(doorStructure.dimensions.height);        // высота двери

        // для mnt-top (Top frame sort)  если есть трансом, то длина mnt-top НЕ уменьшается на ширину двери
        let calcTransomWidthFt: number = 0;
        let calcTransomHeightFt: number = 0;
        let calcTopFrameWidthFt: number = 0;
        if (transomStructure)
        {
            calcTransomWidthFt = dimensionToFt(transomStructure?.dimensions.width);       // ширина проема над дверью
            calcTransomHeightFt = dimensionToFt(transomStructure?.dimensions.height);     // высота проема над дверью
            calcTopFrameWidthFt = calcTotalWidthFt;
        } else {
            calcTopFrameWidthFt = calcTotalWidthFt - calcDoorWidthFt;
        }

        /*
            Для CGF-400 высотой 10' количество горизонтальных мульонов в таблице 7.
         */
        const calcLinesHrzQty = EstimateService.calcHorizontalDividerLines(installedSystemCode, calcTotalHeightFt);    // количество линий материала горизонтальных (из таблицы)
        /*
            Для каждой двери 1 вертикальный мульон.
            Для партишн 1 мульон делит 3' партишн пополам.
            Считаем по формуле: [общая длина] - [дверь].
            10'-3'=7'
            Находим значение в таблице
            Значение для ширины 7' в таблице = 4
            суммируем 4 вертикальных линии + 1 линия на двери = 5
         */
        const calcLinesVrtQty = 1 + EstimateService.calcVerticalDividerLines(installedSystemCode, calcTotalWidthFt /*- calcDoorWidthFt*/);      // количество линий материала вертикальных(из таблицы)


        // IF FORMULA for Clear Strip for Glass-to-Glass Joints formula: (round [total height]/3) -1 logic: if ([total width]/3-1)<4, then 0, other CALCULATE by FORMULA
        const calcStripJointsQty = (Math.round(calcTotalWidthFt / 3) - 1 < 4) ? 0 : (Math.round(calcTotalWidthFt / 3) - 1)
        const calcStripJointsConsideringDoorQty = (Math.round((calcTotalWidthFt - calcDoorWidthFt) / 3) - 1)
        const calcStripJointsWithoutDoorQty = (Math.round((calcTotalWidthFt - calcDoorWidthFt) / 3))

        const allowForSystems = ['cgs200', 'cgf300', 'cgf350', 'cgf400', 'kompas-sgl', 'kompas-dbl', 'trevi', 'romano',];
        if (!allowForSystems.includes(installedSystemCode)) {
            return [];
        }

        // cgs200 whole perimeter

        if (installedSystemCode === 'cgs200' && (mountsTypeCode === 'mnt-perimeter' || mountsTypeCode === 'mnt-topbottom') && isSlideDoor(doorTypeCode) && doorOnly) {
            // By default, Top TRACK without holder for fixed panels [total width] Top Track End Caps x2
            // same calc for top-bottom
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-noholder',
                hardwareValueCode: 'mnt-toptrack-noholder-1',
                qty: this.roundingQty(calcTotalWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-end',
                hardwareValueCode: 'mnt-toptrack-end-1',
                qty: 2,
            });
        }

        if (installedSystemCode === 'cgs200' && mountsTypeCode === 'mnt-perimeter' && isSlideDoor(doorTypeCode) && !doorOnly) {
            // By default Top TRACK for fixed panels [total width] Side frame is 1-1/12" [height x2] Bottom frame is 1/2" [total width] - [door width] Glazing gasket ([total width] x2 + [height] x2 - [door width]) x2 Clear Strip for Glass-to-Glass Joints IF FORMULA
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-sl',
                hardwareValueCode: this.calcOverheadSlidingTopTrackCode(calcTotalWidthFt),
                qty: 1,
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack',
                hardwareValueCode: 'mnt-toptrack-1',
                qty: this.roundingQty(calcTotalWidthFt),
            });*/
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-side',
                hardwareValueCode: 'mnt-uch-deep-1-12',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-bottom',
                hardwareValueCode: 'mnt-uch-low-1-2',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                // для cgf/cgs если нет трансома, из общей ширины (верхней) вычитаем ширину двери
                qty: this.roundingQty((calcTotalWidthFt * 2 + calcTotalHeightFt * 2 - calcDoorWidthFt - (calcTransomHeightFt ? 0 : calcDoorWidthFt )) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-stripes',
                hardwareValueCode: 'mnt-strp-1',
                qty: this.roundingQty(calcStripJointsConsideringDoorQty),
            });
        }

        if (installedSystemCode === 'cgs200' && mountsTypeCode === 'mnt-perimeter' && (isSwingDoor(doorTypeCode)) && !doorOnly) {
            // By default Top frame  is 1-1/12" [total width] - [door width] Side frame is 1-1/12" [height] x2 Bottom frame is 1/2" [total width] - [door width] Glazing gasket ([total width] x2 - [door width] x2 + [height] x2) x2
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-top',
                hardwareValueCode: 'mnt-uch-deep-1-12',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-side',
                hardwareValueCode: 'mnt-uch-deep-1-12',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-bottom',
                hardwareValueCode: 'mnt-uch-low-1-2',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalWidthFt - (calcTransomHeightFt ? 0 : calcDoorWidthFt )) * 2 + (calcTotalWidthFt - calcDoorWidthFt) * 2 + calcTotalHeightFt * 2 * 2) // длина сверху * 2 + высота * 2 * 2 + длина снизу (ширина-дверь)*2
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-stripes',
                hardwareValueCode: 'mnt-strp-1',
                qty: this.roundingQty(calcStripJointsConsideringDoorQty),
            });
        }

        if (installedSystemCode === 'cgs200' && mountsTypeCode === 'mnt-perimeter' && isSwingDoor(doorTypeCode) && doorOnly) {
            // none
        }

// cgs200 top bottom perimeter
        // silde && door only = same as whole perimeter

        if (installedSystemCode === 'cgs200' && mountsTypeCode === 'mnt-topbottom' && isSlideDoor(doorTypeCode) && !doorOnly) {
            // By default Top TRACK for fixed panels [total width] Side frame is 1-1/12" [height x2] Bottom frame is 1/2" [total width] - [door width] Glazing gasket ([total width] x2 + [height] x2 - [door width]) x2 Clear Strip for Glass-to-Glass Joints IF FORMULA
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack',
                hardwareValueCode: 'mnt-toptrack-1',
                qty: this.roundingQty(calcTotalWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-bottom',
                hardwareValueCode: 'mnt-uch-low-1-2',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalWidthFt * 2 - calcDoorWidthFt  - (calcTransomHeightFt ? 0 : calcDoorWidthFt ) ) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-stripes',
                hardwareValueCode: 'mnt-strp-1',
                qty: this.roundingQty(calcStripJointsConsideringDoorQty),
            });
        }


        if (installedSystemCode === 'cgs200' && mountsTypeCode === 'mnt-topbottom' && (isSwingDoor(doorTypeCode)) && !doorOnly) {
            // By default Top frame  is 1-1/12" [total width] - [door width] Side frame is 1-1/12" [height] x2 Bottom frame is 1/2" [total width] - [door width] Glazing gasket ([total width] x2 - [door width] x2 + [height] x2) x2
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-top',
                hardwareValueCode: 'mnt-uch-deep-1-12',
                qty: this.roundingQty(calcTotalWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-bottom',
                hardwareValueCode: 'mnt-uch-low-1-2',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalWidthFt * 2 - calcDoorWidthFt  - (calcTransomHeightFt ? 0 : calcDoorWidthFt )) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-stripes',
                hardwareValueCode: 'mnt-strp-1',
                qty: this.roundingQty(calcStripJointsConsideringDoorQty),
            });
        }

        //  CGF-300/350/400
        // slide && door only
        // same for top-bottom door only
        if (installedSystemCode === 'cgf300' && (mountsTypeCode === 'mnt-perimeter' || mountsTypeCode === 'mnt-topbottom') && isSlideDoor(doorTypeCode) && doorOnly) {
            // CGF-300 Top TRACK without holder for fixed panels [total width] Top Track End Caps x 2 CGF frame [door height] x 2 { x 4 for double door} Mullions top and bottom (door) [door width] x 4 3M tape [door height] x 2 { x 4 for double door} + [door width] x 4
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-noholder',
                hardwareValueCode: 'mnt-toptrack-noholder-1',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-end',
                hardwareValueCode: 'mnt-toptrack-end-1',
                qty: 2,
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcDoorHeightFt * (isDoubleDoor(doorTypeCode) ? 4 : 2)),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorWidthFt * 4 + calcDoorHeightFt * (2 * doubleDoorCoef)),
            });*/
        }

        if (installedSystemCode === 'cgf350' && (mountsTypeCode === 'mnt-perimeter' || mountsTypeCode === 'mnt-topbottom') && isSlideDoor(doorTypeCode) && doorOnly) {
            //  CGF-350 Top TRACK without holder for fixed panels [total width] x 1 Top Track End Cap x 2 CGF frame [door height] x 2 { x 4 for double door} Mullions top, bottom (door) [door width] x 4 Mullions horizontal (door) ([door width] x [LINES Q-TY FROM DATA SHEET]) x 2  3M tape [door height] x 2 { x 4 for double door} + [door width] x 4 + ([door width] x [LINES Q-TY FROM DATA SHEET]) x 2
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-sl',
                hardwareValueCode: this.calcOverheadSlidingTopTrackCode(calcTotalWidthFt),
                qty: 1,
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-end',
                hardwareValueCode: 'mnt-toptrack-end-1',
                qty: 2,
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcDoorHeightFt * (2 * doubleDoorCoef)),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz-1',
                hardwareValueCode: 'mnt-mul-hrz',
                qty: this.roundingQty(calcDoorWidthFt * calcLinesHrzQty * 2),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorHeightFt * (2 * doubleDoorCoef) + calcDoorWidthFt * 4 + (calcDoorWidthFt * calcLinesHrzQty * 2))
            });*/
        }

        if (installedSystemCode === 'cgf400' && (mountsTypeCode === 'mnt-perimeter' || mountsTypeCode === 'mnt-topbottom') && isSlideDoor(doorTypeCode) && doorOnly) {
            // CGF-400  Top TRACK without holder for fixed panels [total width] x 1 Top Track End Cap x 2 CGF frame [door height] x 2 { x 4 for double door}
            // Mullions top, bottom (door) [door width] x 4 Mullions horizontal (door) ([door width] x [LINES Q-TY FROM DATA SHEET]) x 2
            // Mullions vertical (door) ([door height] x [LINES Q-TY FROM DATA SHEET]) x 2 3M tape [door height] x 2 { x 4 for double door} + [door width] x 4 + ([door width] x [LINES Q-TY FROM DATA SHEET]) x 2 + ([door height] x [LINES Q-TY FROM DATA SHEET]) x 2
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-noholder',
                hardwareValueCode: 'mnt-toptrack-noholder-1',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-end',
                hardwareValueCode: 'mnt-toptrack-end-1',
                qty: 2,
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcDoorHeightFt * (2 * doubleDoorCoef)),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-hrz-1',
                qty: this.roundingQty(calcDoorWidthFt * calcLinesHrzQty * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-vrt',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(((calcTotalWidthFt / 3).toFixed(0) - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2 * calcTotalHeightFt),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorHeightFt * (2 * doubleDoorCoef) + calcDoorWidthFt * 4 + (calcDoorWidthFt * calcLinesHrzQty * 2) + (calcDoorHeightFt * calcLinesVrtQty * 2)),
            });*/
        }

        // CGF-300/350/400
        // whole perimeter && slide && !doorOnly

        if (installedSystemCode === 'cgf300' && mountsTypeCode === 'mnt-perimeter' && isSlideDoor(doorTypeCode) && !doorOnly) {
            // CGF-300 Top TRACK without holder for fixed panels [total width] Top Track End Caps x 2 CGF frame [door height] x 2 { x 4 for double door} Mullions top and bottom (door) [door width] x 4 3M tape [door height] x 2 { x 4 for double door} + [door width] x 4
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-sl',
                hardwareValueCode: this.calcOverheadSlidingTopTrackCode(calcTotalWidthFt),
                qty: 1,
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalHeightFt * 2 + (calcTotalWidthFt - calcDoorWidthFt ) * 2) * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * 4 * (isDoubleDoor(doorTypeCode) ? 4 : 1)),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorWidthFt * 4 + calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4)),
            });*/
        }

        if (installedSystemCode === 'cgf350' && mountsTypeCode === 'mnt-perimeter' && isSlideDoor(doorTypeCode) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-sl',
                hardwareValueCode: this.calcOverheadSlidingTopTrackCode(calcTotalWidthFt),
                qty: 1,
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalHeightFt * 2 + (calcTotalWidthFt - calcDoorWidthFt)*2) * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * 4 * (isDoubleDoor(doorTypeCode) ? 4 : 1)),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorWidthFt * 4 + calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4)),
                // qty: calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4)
                //     + calcDoorWidthFt * 2 + calcTotalWidthFt * 2 + calcTotalWidthFt * calcLinesHrzQty * 2,
            });*/
        }

        if (installedSystemCode === 'cgf400' && mountsTypeCode === 'mnt-perimeter' && isSlideDoor(doorTypeCode) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-sl',
                hardwareValueCode: this.calcOverheadSlidingTopTrackCode(calcTotalWidthFt),
                qty: 1,
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalHeightFt * 2 + calcTotalWidthFt * 2 - calcDoorWidthFt * 2) * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                //hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-vrt',
                //hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(((calcTotalWidthFt / 3).toFixed(0) - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2 * calcTotalHeightFt),
//                qty: calcDoorHeightFt * (calcLinesVrtQty - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2,
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4)),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                // сумма всех мульонов и cgf профилей
                qty: this.roundingQty(calcDoorWidthFt * 2
                    + calcDoorWidthFt * 2
                    + (calcTotalWidthFt * calcLinesHrzQty * 2)
                    + (calcDoorHeightFt * (calcLinesVrtQty) * 2)),
                // qty: calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4)
                //     + calcTotalWidthFt * 2 + calcTotalWidthFt * calcLinesHrzQty * 2
                //     + calcTotalHeightFt * (calcLinesVrtQty - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2,
            });*/
        }

        // CGF-300/350/400
        // whole perimeter && swing && door only
        // top-bottom && door only
        if (installedSystemCode === 'cgf300' && (mountsTypeCode === 'mnt-perimeter' || mountsTypeCode === 'mnt-topbottom') && isSwingDoor(doorTypeCode) && doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcDoorHeightFt * 2 + calcDoorWidthFt * 2),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorHeightFt * 2 + calcDoorWidthFt * 2),
            });*/
        }

        if (installedSystemCode === 'cgf350' && (mountsTypeCode === 'mnt-perimeter' || mountsTypeCode === 'mnt-topbottom') && isSwingDoor(doorTypeCode) && doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcDoorHeightFt * 2 + calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt + calcLinesHrzQty * 2),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorHeightFt * 2 + calcDoorWidthFt * 2
                    + calcDoorWidthFt * calcLinesHrzQty * 2),
            });*/
        }

        if (installedSystemCode === 'cgf400' && (mountsTypeCode === 'mnt-perimeter' || mountsTypeCode === 'mnt-topbottom') && isSwingDoor(doorTypeCode) && doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcDoorHeightFt * 2 * doubleDoorCoef + calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt + calcLinesHrzQty * 2), // from 350
                //qty: this.roundingQty(calcTotalWidthFt * (calcLinesHrzQty) * 2), //old
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-vrt',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(((calcTotalWidthFt / 3).toFixed(0) - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2 * calcTotalHeightFt),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorHeightFt * 2 * doubleDoorCoef
                    + calcDoorWidthFt * 2 + calcDoorWidthFt * calcLinesHrzQty * 2
                    + calcDoorHeightFt * (calcDoorHeightFt * calcLinesVrtQty) * 2),
            });*/
        }

        // CGF-300/350/400
        // whole perimeter && swing && !door only

        if (installedSystemCode === 'cgf300' && mountsTypeCode === 'mnt-perimeter' && (isSwingDoor(doorTypeCode)) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalHeightFt * 2 + (calcTotalWidthFt * 2  - calcDoorWidthFt * (calcTransomHeightFt ? 1 : 2 ))) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4) + calcDoorWidthFt * 2),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4) + calcDoorWidthFt * 2),
            });*/
        }

        if (installedSystemCode === 'cgf350' && mountsTypeCode === 'mnt-perimeter' && (isSwingDoor(doorTypeCode)) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalWidthFt * 2 - (calcDoorWidthFt * (calcTransomHeightFt ? 1 : 2 )) + calcTotalHeightFt * 2 ) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4) + calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4) + calcDoorWidthFt * 2 + calcTotalWidthFt * calcLinesHrzQty * 2),
            });*/
        }

        if (installedSystemCode === 'cgf400' && mountsTypeCode === 'mnt-perimeter' && (isSwingDoor(doorTypeCode)) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalHeightFt * 2 + (calcTotalWidthFt * 2 - calcDoorWidthFt * (calcTransomHeightFt ? 1 : 2 ))) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4) + calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-vrt',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(((calcTotalWidthFt / 3).toFixed(0) - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2 * calcTotalHeightFt),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 6 : 4) + calcDoorWidthFt * 2
                    + calcTotalWidthFt * calcLinesHrzQty * 2
                    + calcDoorHeightFt * (calcLinesVrtQty - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2),
            });*/
        }

        // CGF-300/350/400
        // top bottom && slide && !door only

        if (installedSystemCode === 'cgf300' && mountsTypeCode === 'mnt-topbottom' && isSlideDoor(doorTypeCode) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack',
                hardwareValueCode: 'mnt-toptrack-1',
                qty: calcTotalWidthFt,
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalWidthFt * 2 - calcDoorWidthFt * 2) * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty((calcTotalWidthFt + calcDoorWidthFt) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty((calcTotalWidthFt + calcDoorWidthFt) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty((calcTotalHeightFt * 2 * (isDoubleDoor(doorTypeCode) ? 4 : 3))),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcDoorWidthFt * 2 + calcTotalHeightFt * 2 * (isDoubleDoor(doorTypeCode) ? 4 : 3)),
            });*/
        }

        if (installedSystemCode === 'cgf350' && mountsTypeCode === 'mnt-topbottom' && isSlideDoor(doorTypeCode) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack',
                hardwareValueCode: 'mnt-toptrack-1',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty(calcTotalWidthFt * 2 - calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-mdl',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * 2 * (isDoubleDoor(doorTypeCode) ? 4 : 3)),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6)
                    + calcDoorWidthFt * 2 + calcTotalWidthFt * 2 + calcTotalWidthFt * calcLinesHrzQty * 2),
            });*/
        }

        if (installedSystemCode === 'cgf400' && mountsTypeCode === 'mnt-topbottom' && isSlideDoor(doorTypeCode) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack',
                hardwareValueCode: 'mnt-toptrack-1',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty(calcTotalWidthFt * 2 - calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcTotalWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-vrt',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(((calcTotalWidthFt / 3).toFixed(0) - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2 * calcTotalHeightFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6)),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6)
                    + calcDoorWidthFt * 2 + calcTotalWidthFt * 2 + calcTotalWidthFt * calcLinesHrzQty * 2
                    + calcDoorHeightFt * (calcLinesVrtQty - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2),
            });*/
        }

        // CGF-300/350/400
        // top-bottom && swing && !door only

        if (installedSystemCode === 'cgf300' && mountsTypeCode === 'mnt-topbottom' && (isSwingDoor(doorTypeCode)) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalWidthFt * 2 - calcDoorWidthFt * 2) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6) + calcDoorWidthFt * 2),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6) + calcDoorWidthFt * 2),
            });*/
        }

        if (installedSystemCode === 'cgf350' && mountsTypeCode === 'mnt-topbottom' && isSwingDoor(doorTypeCode) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalWidthFt - calcDoorWidthFt) * 2 * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6) + calcDoorWidthFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt + calcLinesHrzQty * 2),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6) + calcDoorWidthFt * 2 + calcTotalWidthFt * calcLinesHrzQty * 2),
            });*/
        }

        if (installedSystemCode === 'cgf400' && mountsTypeCode === 'mnt-topbottom' && isSwingDoor(doorTypeCode) && !doorOnly) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: this.roundingQty((calcTotalWidthFt - calcDoorWidthFt) * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6)),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-vrt',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(((calcTotalWidthFt / 3).toFixed(0) - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2 * calcTotalHeightFt),
            });
            /*hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * (isDoubleDoor(doorTypeCode) ? 8 : 6) + calcTotalWidthFt * calcLinesHrzQty * 2
                    + calcDoorHeightFt * (calcLinesVrtQty - (isDoubleDoor(doorTypeCode) ? 3 : 2)) * 2),
            });*/
        }

        // todo redirect top-bottom door-only

        // CGF-300/350/400
        // divider && whole perimeter

        // todo check divider condition
        if (installedSystemCode === 'cgs200' && mountsTypeCode === 'mnt-perimeter' && noDoor) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-top',
                hardwareValueCode: 'mnt-uch-deep-1-12',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-side',
                hardwareValueCode: 'mnt-uch-deep-1-12',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-bottom',
                hardwareValueCode: 'mnt-uch-low-1-2',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            // Маргарита Никифорович : 5 шаг должны быть только channels и mullions
            // Returned gasket
             hardwareItems.push(<HardwareItem>{
                 code: generateCode(),
                 hardwareTypeCode: 'mnt-gasket',
                 hardwareValueCode: 'mnt-gsk-1',
                 qty: (calcTotalWidthFt * 2 + calcTotalHeightFt * 2) * 2,
             });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-stripes',
                hardwareValueCode: 'mnt-strp-1',
                qty: this.roundingQty(calcStripJointsWithoutDoorQty),
            });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-stripes',
            //     hardwareValueCode: 'mnt-strp-1',
            //     qty: calcStripJointsQty,
            // });
        }

        if (installedSystemCode === 'cgf300' && mountsTypeCode === 'mnt-perimeter' && noDoor) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            // task 35014
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: (calcTotalWidthFt * 2 + calcTotalHeightFt * 2) * 2,
            });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-stripes',
            //     hardwareValueCode: 'mnt-strp-1',
            //     qty: calcStripJointsQty,
            // });
        }

        if (installedSystemCode === 'cgf350' && mountsTypeCode === 'mnt-perimeter' && noDoor) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: (calcTotalWidthFt * 2 + calcTotalHeightFt * 2) * 2,
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-mdl',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-tape',
            //     hardwareValueCode: 'mnt-tape-1',
            //     qty: calcTotalWidthFt * calcLinesHrzQty,
            // });
        }

        if (installedSystemCode === 'cgf400' && mountsTypeCode === 'mnt-perimeter' && noDoor) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-side',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalHeightFt * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: (calcTotalWidthFt * 2 + calcTotalHeightFt * 2) * 2,
            });

            // TODO Kovalev
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });

            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-mul-mdl',
            //     hardwareValueCode: 'mnt-mul-topbtm-cgf1',
            //     qty: calcTotalWidthFt * calcLinesHrzQty * 2,
            // });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-vrt',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(((calcTotalWidthFt / 3).toFixed(0) - 1) * 2 * calcTotalHeightFt),
            });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-tape',
            //     hardwareValueCode: 'mnt-tape-1',
            //     qty: calcTotalWidthFt * calcLinesHrzQty * 2
            //         + calcTotalHeightFt * calcLinesVrtQty * 2,
            // });
        }

        // CGF-300/350/400
        // divider && top-bottom

        if (installedSystemCode === 'cgs200' && mountsTypeCode === 'mnt-topbottom' && noDoor) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-top',
                hardwareValueCode: 'mnt-uch-deep-1-12',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-bottom',
                hardwareValueCode: 'mnt-uch-low-1-2',
                qty: this.roundingQty(calcTotalWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: calcTotalWidthFt * 4,
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-stripes',
                hardwareValueCode: 'mnt-strp-1',
                qty: this.roundingQty(calcStripJointsWithoutDoorQty),
            });
        }

        if (installedSystemCode === 'cgf300' && mountsTypeCode === 'mnt-topbottom' && noDoor) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: calcTotalWidthFt * 4,
            });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-cgf-frame',
            //     hardwareValueCode: 'mnt-cgf-frame-1',
            //     qty: calcTotalHeightFt * 2,
            // });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-tape',
            //     hardwareValueCode: 'mnt-tape-1',
            //     qty: calcTotalHeightFt * 2,
            // });
        }

        if (installedSystemCode === 'cgf350' && mountsTypeCode === 'mnt-topbottom' && noDoor) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-gasket',
                hardwareValueCode: 'mnt-gsk-1',
                qty: calcTotalWidthFt * 4,
            });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-cgf-frame',
            //     hardwareValueCode: 'mnt-cgf-frame-1',
            //     qty: calcTotalHeightFt * 2,
            // });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-tape',
            //     hardwareValueCode: 'mnt-tape-1',
            //     qty: calcTotalHeightFt * 2 + (calcTotalWidthFt * calcLinesHrzQty) * 2,
            // });
        }

        if (installedSystemCode === 'cgf400' && mountsTypeCode === 'mnt-topbottom' && noDoor) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-top',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTopFrameWidthFt),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame-btm',
                hardwareValueCode: 'mnt-uch-cgf1',
                qty: this.roundingQty(calcTotalWidthFt - calcDoorWidthFt),
            });
             hardwareItems.push(<HardwareItem>{
                 code: generateCode(),
                 hardwareTypeCode: 'mnt-gasket',
                 hardwareValueCode: 'mnt-gsk-1',
                 qty: (calcTotalWidthFt * 2 - calcDoorWidthFt * 2) * 2,
             });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-cgf-frame',
            //     hardwareValueCode: 'mnt-cgf-frame-1',
            //     qty: calcTotalHeightFt * 2,
            // });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-hrz',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcTotalWidthFt * calcLinesHrzQty * 2),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-vrt',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(((calcTotalWidthFt / 3).toFixed(0) - 1) * 2 * calcTotalHeightFt),
            });
            // hardwareItems.push(<HardwareItem>{
            //     code: generateCode(),
            //     hardwareTypeCode: 'mnt-tape',
            //     hardwareValueCode: 'mnt-tape-1',
            //     qty: calcTotalHeightFt * 2 + (calcTotalWidthFt * calcLinesHrzQty) * 2
            //         + (calcTotalHeightFt * calcLinesVrtQty) * 2,
            // });
        }

        // kompas

        // kompas swing

        if (isKompasSystem(installedSystemCode) && mountsTypeCode === 'mnt-perimeter' && (isSwingDoor(doorTypeCode))) {
            //todo kompas mounts
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfh',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalWidthFt * 3),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfv',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalHeightFt * (2 + Math.round(calcTotalWidthFt / 3) - 1)), // 2 по крям + каждые 3ft в середине
            });

//3. Kompas step 5 при swing doors не должно быть cgf frame и мульенов
//             hardwareItems.push(<HardwareItem>{
//                 code: generateCode(),
//                 hardwareTypeCode: 'mnt-cgf-frame',
//                 hardwareValueCode: 'mnt-cgf-frame-1',
//                 qty: (calcDoorHeightFt + calcDoorWidthFt) * (2 + doubleDoorCoef),
//             });
//             hardwareItems.push(<HardwareItem>{
//                 code: generateCode(),
//                 hardwareTypeCode: 'mnt-tape',
//                 hardwareValueCode: 'mnt-tape-1',
//                 qty: (calcDoorHeightFt + calcDoorWidthFt) * 2 + doubleDoorCoef,
//             });
        }

        // kompas sliding
        if (isKompasSystem(installedSystemCode) && mountsTypeCode === 'mnt-perimeter' && isSlideDoor(doorTypeCode)) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfh',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalWidthFt * 3),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfv',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalHeightFt * (2 + Math.round(calcTotalWidthFt / 3) - 1)), // 2 по крям + каждые 3ft в середине
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-toptrack-sl',
                hardwareValueCode: this.calcOverheadSlidingTopTrackCode(calcTotalWidthFt),
                qty: 1,
            });

// Kompas sliding не должно быть мульенов  ----------- отмена
// single sliding: CGF frame = 2 высоты; CGF mullions = 4 ширины двери.  <-------- новая вводная
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-top',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-mul-btm',
                hardwareValueCode: 'mnt-mul-topbtm-cgf1',
                qty: this.roundingQty(calcDoorWidthFt * 2),
            });

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-cgf-frame',
                hardwareValueCode: 'mnt-cgf-frame-1',
                qty: this.roundingQty(calcTotalHeightFt * 2 * doubleDoorCoef),
                // qty: (calcDoorHeightFt + calcDoorWidthFt) * (2 + doubleDoorCoef),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-tape',
                hardwareValueCode: 'mnt-tape-1',
                qty: this.roundingQty(calcTotalHeightFt * 2  * doubleDoorCoef),
                // qty: (calcDoorHeightFt + calcDoorWidthFt) * (2 + doubleDoorCoef),
            });
        }
        // kompas divider
        if (isKompasSystem(installedSystemCode) && mountsTypeCode === 'mnt-perimeter' && noDoor) {

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfh',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalWidthFt * 3),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfv',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalHeightFt * (2 + Math.round(calcTotalWidthFt / 3) - 1)), // 2 по крям + каждые 3ft в середине
            });
        }

        // kompas divider
        if (isKompasSystem(installedSystemCode) && mountsTypeCode === 'mnt-topbottom' && noDoor) {

            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfh',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalWidthFt * 3),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfv',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalHeightFt * (Math.round(calcTotalWidthFt / 3) - 1)), // 2 по крям + каждые 3ft в середине
            });
        }



        if (installedSystemCode === 'trevi' && mountsTypeCode === 'mnt-none') {
            let valueCode = 'mnt-frame-sq-trevi';
            if (doorTypeCode !== 'dr-none') {
                valueCode = 'mnt-frame-sq-trevi' + '-' + doorTypeCode
            }
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-trevi-frame',
                hardwareValueCode: valueCode,
                qty: this.roundingQty((calcTotalWidthFt * calcTotalHeightFt)),
            });
        }

        if (installedSystemCode === 'romano' && mountsTypeCode === 'mnt-none') {
            let valueCode = 'mnt-frame-sq-romano';
            if (doorTypeCode !== 'dr-none') {
                valueCode = 'mnt-frame-sq-romano' + '-' + doorTypeCode
            }
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-romano-frame',
                hardwareValueCode: valueCode,
                qty: this.roundingQty((calcTotalWidthFt * calcTotalHeightFt)),
            });
        }

        // kompas prefabricated with perimeter type=mnt-none
        if ((doorTypeCode === 'kmp-s' || doorTypeCode === 'kmp-d') && mountsTypeCode === 'mnt-none' ) {
            // для kompas double frame удваивается количество профиля
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prf',
                hardwareValueCode: 'mnt-kmp-prf-1',
                qty: this.roundingQty((calcTotalWidthFt + calcTotalHeightFt) * 2 * (installedSystemCode === 'kompas-dbl' ? 2 : 1)),
            });
        }

        if ((doorTypeCode === 'kmp-s' || doorTypeCode === 'kmp-d') && mountsTypeCode === 'mnt-perimeter' ) {
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfh',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalWidthFt * 3),
            });
            hardwareItems.push(<HardwareItem>{
                code: generateCode(),
                hardwareTypeCode: 'mnt-kmp-prfv',
                hardwareValueCode: 'mnt-kmp-prfv-1',
                qty: this.roundingQty(calcTotalHeightFt * (2 + Math.round(calcTotalWidthFt / 3) - 1)), // 2 по крям + каждые 3ft в середине
            });
        }

        return hardwareItems;
    }

    static roundingQty(input) {
        const qty = Number(input);

        return (qty % 1) === 0 ? qty.toFixed(0) : qty.toFixed(1);
    }


    static overheadCostsFactory(areaSqFt: number, needInstallation: boolean = true, needDelivery: boolean = true): AdditionalCostItem[] {

        const additionalCostsItems = [];

        additionalCostsItems.push(
            this.additionalCostItemFactory({
                code: generateCode(),
                costTypeCode: 'oh-dr',
                costValueCode: 'oh-dr-01',
                qty: this.calcOhDrawingQty(areaSqFt),
                qtyDisplay: areaSqFt,
            }),
            this.additionalCostItemFactory({
                costTypeCode: 'oh-cut',
                costValueCode: 'oh-cut-01',
                qty: this.calcOhCuttingQty(areaSqFt),
                qtyDisplay: areaSqFt,
            }),
            this.additionalCostItemFactory({
                costTypeCode: 'oh-pack',
                costValueCode: 'oh-pack-01',
                qty: this.calcOhPackaingQty(areaSqFt),
                qtyDisplay: areaSqFt,
            }),
            this.additionalCostItemFactory({
                costTypeCode: 'oh-gas',
                costValueCode: 'oh-gas-01',
                qty: needDelivery ? this.calcOhParkingQty(areaSqFt) : 0,
                qtyDisplay: areaSqFt,
            }),
            this.additionalCostItemFactory({
                costTypeCode: 'oh-msr',
                costValueCode: 'oh-msr-01',
                qty: needInstallation ? this.calcOhMeasureQty(areaSqFt) : 0,
                qryDisplay: areaSqFt,
            }),
        )
        return additionalCostsItems
    }

    /**
     * вычисляет полощадь остекления проема
     * @param structure Structure
     */
    static calcPartitionGlassArea(installedSystem: string, partition: StructureItem, doors: StructureItem[], transoms:StructureItem[]|null = null): number {

        let area = calcAreaFt(partition.dimensions);
        // отнимаем площадь дверей
        if (doors) {
            doors.forEach(door => {
                area = area - calcAreaFt(door.dimensions)
            });
        }
        // отнимаем площадь проемов над дверьми
        if (transoms) {
            transoms.forEach(transom => {
                if (transom.structureTypeCode === 'transom') {
                    area = area - calcAreaFt(transom.dimensions)
                }
                // для steel систем отнимаем и филлера
                if ((transom.structureTypeCode === 'filler') && isSteelSystem(installedSystem)) {
                    area = area - calcAreaFt(transom.dimensions)
                }
            })
        }
        return area;
    }

    static calcHorizontalDividerLines(systemType: string, height: number): number {
        //  rangeMin > totalHeight <= rangeMax
        const cgf350hrz = [
            {rangeMin: -1, rangeMax: 3, value: 0},
            {rangeMin: 3, rangeMax: 6, value: 1},
            {rangeMin: 6, rangeMax: 7, value: 2},
            {rangeMin: 7, rangeMax: 8, value: 2},
            {rangeMin: 8, rangeMax: 9, value: 3},
            {rangeMin: 9, rangeMax: 10, value: 3},
            {rangeMin: 10, rangeMax: 11, value: 3},
            {rangeMin: 11, rangeMax: 12, value: 4},
        ];

        //old
        /*const cgf400hrz = [
            {rangeMin: -1, rangeMax: 3, value: 1},
            {rangeMin: 3, rangeMax: 6, value: 3},
            {rangeMin: 6, rangeMax: 7, value: 3},
            {rangeMin: 7, rangeMax: 8, value: 4},
            {rangeMin: 8, rangeMax: 9, value: 5},
            {rangeMin: 9, rangeMax: 10, value: 6},
            {rangeMin: 10, rangeMax: 11, value: 7},
            {rangeMin: 11, rangeMax: 12, value: 8},
        ]*/
        const cgf400hrz = [
            {rangeMin: -1, rangeMax: 3, value: 0},
            {rangeMin: 3, rangeMax: 6, value: 1},
            {rangeMin: 6, rangeMax: 7, value: 2},
            {rangeMin: 7, rangeMax: 8, value: 2},
            {rangeMin: 8, rangeMax: 9, value: 3},
            {rangeMin: 9, rangeMax: 10, value: 3},
            {rangeMin: 10, rangeMax: 11, value: 3},
            {rangeMin: 11, rangeMax: 12, value: 4},
        ]

        let rangeArray = [];
        switch (systemType) {
            case 'cgf350':
                rangeArray = cgf350hrz;
                break;
            case 'cgf400':
                rangeArray = cgf400hrz;
                break;
            default:
                return 0;
        }

        const rangeRec = rangeArray.find(item => (item.rangeMin < height && item.rangeMax >= height));

        if (rangeRec) {
            return rangeRec.value;
        }

        // если не нашли подходящую запись
        console.error('calcHorizontalDividerLines calc error, totalHeight not found in range', height);
        console.log('calcHorizontalDividerLines calc error, totalHeight not found in range', height);
        return 0;

    }

    static calcVerticalDividerLines(systemType: string, width: number): number {
        let lines: number = 0;
        switch (systemType) {
            case 'cgf350':
                if (width < 3) {
                    lines = 0;
                } else {
                    lines = Math.round(width / 3 - 1)
                }
                break;
            case 'cgf400':
                if (width < 3) {
                    //lines = 1;
                    lines = 0;
                } else {
                    //lines = Math.round(width / 1.5 - 1)
                    lines = Math.round(width / 3 - 1)
                }
                break;
            default:
                lines = 0;
        }
        return lines;
    }

    /**
     * Возвращает код элемента для TopTrack Sliding Door в зависимости от длины
     * @param trackLengthFt
     * @return string mountHardwareValues element code
     */
    static calcOverheadSlidingTopTrackCode(trackLengthFt: number): string {

        // range in inches
        const tracks = [
            {rangeMin: -1, rangeMax: 118, value: "A"},
            {rangeMin: 118, rangeMax: 157, value: "B"},
            {rangeMin: 157, rangeMax: 236, value: "AA"},
            {rangeMin: 236, rangeMax: 275, value: "AB"},
            {rangeMin: 275, rangeMax: 314, value: "BB"},
            {rangeMin: 314, rangeMax: 393, value: "AAB"},
            {rangeMin: 393, rangeMax: 432, value: "BBA"},
            {rangeMin: 432, rangeMax: 471, value: "BBB"},
            {rangeMin: 471, rangeMax: 708, value: "BBBA"},
            {rangeMin: 708, rangeMax: 825, value: "AAAAA"},
            {rangeMin: 825, rangeMax: 942, value: "BBBB"},
        ]
        const track = tracks.find((item => (trackLengthFt * 12 > item.rangeMin && trackLengthFt * 12 <= item.rangeMax)))

        if (track) {
            return 'mnt-toptrack-sl-' + track.value.toLowerCase();
        }

        console.error('top track asset not found for length', trackLengthFt);
        return 'mnt-toptrack-sl-none';
    }

    // installation & delivery overhead cost items qty calc

    static calcOhDrawingQty(areaSqFt: number): number {
        return 2 + (areaSqFt <= 100 ? 0 : Math.ceil((areaSqFt - 100) / 100));
    }

    static calcOhCuttingQty(areaSqFt: number): number {
        return 2 + (areaSqFt <= 100 ? 0 : Math.ceil((areaSqFt - 100) / 100));
    }

    static calcOhPackaingQty(areaSqFt: number): number {
        return 2 + (areaSqFt <= 100 ? 0 : Math.ceil((areaSqFt - 100) / 100));
    }

    static calcOhParkingQty(areaSqFt: number): number {
        return (1 + (areaSqFt <= 300 ? 0 : (Math.ceil((areaSqFt - 300) / 200))));
    }

    static calcOhMeasureQty(areaSqFt: number): number {
        return (3 + (areaSqFt <= 300 ? 0 : (Math.ceil((areaSqFt - 300) / 200))));
    }

    /**
     * возвращает суммарную площадь всех partition расчета
     * @param estimate
     */
    static calculateEstimatePartitionsArea(estimate: Estimate): number {
        let areaSum = 0;
        estimate.spaces.forEach(space =>
            space.structures.forEach(
                structure => areaSum += calcAreaFt(structure.partition.dimensions) * structure.partition.qty
            )
        )
        return areaSum;
    }
}

export function isSwingDoor(doorTypeCode: string): boolean {
    return ["s-sw", "d-sw", "st-s-sw", "st-d-sw", "st-s-ar", "st-d-ar",].includes(doorTypeCode);
}

export function isSlideDoor(doorTypeCode: string): boolean {
    return ["s-sl", "d-sl", "st-s-sl", "st-d-sl",].includes(doorTypeCode);
}

export function isSingleDoor(doorTypeCode: string): boolean {
    return ["s-sw", "s-sl", "kmp-s", "st-s-sw", "st-s-sl", "st-s-ar",].includes(doorTypeCode);
}

export function isDoubleDoor(doorTypeCode: string): boolean {
    return ["d-sw", "d-sl", "kmp-d", "st-d-sw", "st-d-sl", "st-d-ar",].includes(doorTypeCode);
}

export function isKompasDoor(doorTypeCode: string): boolean {
    return ["kmp-d", "kmp-s"].includes(doorTypeCode);
}

export function isCgfSystem(systemType: string): boolean {
    return ["cgf300", "cgf350", "cgf400"].includes(systemType);
}

export function isCgsSystem(systemType: string): boolean {
    return ["cgs200"].includes(systemType);
}

export function isKompasSystem(systemType: string): boolean {
    return ["kompas-sgl", 'kompas-dbl'].includes(systemType);
}

export function isSteelSystem(systemType: string): boolean {
    return ["trevi", 'romano'].includes(systemType);
}

