<template>
    <div class="container">
        <h3>Config page</h3>
        <div class="row">
            <select v-model="selectedLoadDict" >
                <option value="" disabled>select</option>
                <option v-for="item of dictList" :key="item" :value="item">{{item}}</option>
            </select>
            <button @click="loadDicts">load dict</button>
            <button @click="loadDicts">load all dicts</button>
            {{ selectedLoadDict}}
        </div>

        <div class="row">
            <select v-model="selectedSaveDict" >
                <option value="" disabled>select</option>
                <option v-for="item of dictList" :key="item" :value="item">{{item}}</option>
            </select>

            <button @click="saveDicts">save dicts</button>
            <button @click="saveAllDicts">save all dicts</button>
            {{ selectedSaveDict }}
        </div>

        <div class="row autoload-block" >
            <input type="checkbox" id="autoload-enabled" v-model="autoloadEnabled">
            <label for="autoload-enabled">enable dict autoload</label>
        </div>

    </div>
</template>

<script setup lang="ts">

    import { useDictStore } from "../stores/dictStore";
    import { computed, ref, watch } from "vue";

    const dictStore = useDictStore();

    const dictList = computed(()=>dictStore.dictsList);
    const selectedSaveDict = ref("");
    const selectedLoadDict = ref("");

    const autoloadEnabled = ref(dictStore.getDictAutoload());

    function saveDicts() {
        console.debug('saveDicts', selectedSaveDict.value);
        dictStore.saveDict(selectedSaveDict.value);
    }
    function saveAllDicts() {
        console.debug('saveAllDicts', selectedSaveDict.value);
        dictStore.saveAllDicts();
    }


    function loadDicts() {
        console.debug('loadDicts', selectedLoadDict.value);
        dictStore.loadDict(selectedLoadDict.value);
    }

    watch(()=>autoloadEnabled.value, (val)=>{
        dictStore.setDictAutoload(val);
    })

</script>

<style scoped>
    .container {
        width:80%;
        margin: 20px auto;
    }
    .row {
        width:100%;
        margin: 10px auto;
    }
    button {
        margin: 5px;
        border:  #0b4e58 thin solid;
    }

    .autoload-block {
        margin: auto;
    }
    .autoload-block label {
        margin-left: 2rem;
    }
</style>
