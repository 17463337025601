/* eslint-disable @typescript-eslint/no-unused-vars */
import { createRouter, createWebHistory, RouterOptions } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import ConfigView from '@/views/ConfigView.vue'
import CalculationView from '@/views/CalculationView.vue'
import CalculationTable from '@/components/CalculationTable.vue'
import CalculateQuestion from '@/components/CalculateQuestion.vue'
import NotFound from '@/views/NotFound.vue'
import { useEstimateStore } from "../stores/estimateStore";
import { useUserStore} from "../stores/userStore";


const authGuard = async (to, from) => {
    await useUserStore().checkLogin();
    console.log('userStore.isLogged', useUserStore().isLogged);
    if (to.meta.requiredAuth && !(useUserStore().isLogged)) {
        console.error("auth required")
        return {name: "login"}
    }

    if (to.meta.skipAuth && useUserStore().isLogged) {
        return {name: "calculation"}
    }
}

const activeEstimateGuard = (to, from) => {
    const estimateStore = useEstimateStore();
    if (!estimateStore.getActiveEstimateCode()) {
        console.info("no active estimate");
        return {name: "calculation"}
    }
}

const logoutRouteGuard = (to, from) => {
    const userStore = useUserStore();
    userStore.logout();
    console.info("logout action");
    return {name: "login"};
}

const router = createRouter(<RouterOptions>{
    history: createWebHistory('/'),
    routes: [
        {
            path: "/",
            name: "login",
            component: LoginView,
//            alias: '/',
            meta: {skipAuth: true},
        },
        {
            name: "logout",
            path: "/logout",
            meta: {requiredAuth: true},
            //meta: {skipAuth: true},
            beforeEnter: (to, from) => logoutRouteGuard(to, from)
        },
        {
            path: "/calculation",
            name: "calculation",
            component: CalculationView,
            meta: {requiredAuth: true},
            children: [
                {
                    path: ":",
                    name: "calculationTable",
                    component: CalculationTable
                },
                {
                    path: '/calculation/:id',
                    name: "calculationPages",
                    component: CalculateQuestion,
                    beforeEnter: (to, from) => activeEstimateGuard(to, from),
                },
             ],
        },
        {
            // system configuration page
            path: "/rTdsr3fsfg3",
            name: "config",
            component: ConfigView,
            meta: {requiredAuth: true},
        },

        // component: () => import('../views/AboutView.vue')
        {path: '/:notFound(.*)', component: NotFound}
    ],
    linkActiveClass: "active-step",
    linkExactActiveClass: "active-step",
});

router.beforeEach(authGuard);

export default router
