<template>
    <section class="questions-section">
        <div class="questions-section__wrapper">
            <div class="questions-section__nav">
                <h3 class="questions-section__title"><router-link :to="{name:'calculationTable'}">Summary</router-link></h3>
                <nav>
                    <ul class="questions-section__list">
                        <li v-for="(step, idx) in arraySteps" :key="step">
                            <router-link :to="`/calculation/` + (+idx + 1)"  @click="updateData(idx + 1)"
                            ><span>Step {{ idx + 1 }}</span>
                                {{ step }}
                            </router-link>
                        </li>
                    </ul>
                </nav>
                <button
                    @click="startNewEstimate"
                    v-if="!$route.params.id"
                    class="questions-section__button"
                >
                    Start New Project
                </button>

                <button
                    @click="loadDraftEstimate"
                    v-if="!$route.params.id"
                    class="continue-last-project-button"
                >
                    Continue last project
                </button>

                <button
                    @click="reloadDicts"
                    v-if="!$route.params.id"
                    class="reload-dicts-button"
                >
                    Reload prices
                </button>

                <button
                    @click="logoutClick"
                    v-if="!$route.params.id"
                    class="logout-button"
                >
                    Logout
                </button>
              <button
                @click="startNewEstimate"
                v-if="$route.params.id == 8"
                class="reload-dicts-button"
              >
                Start New Project
              </button>
              <button
                @click="returnToList"
                v-if="$route.params.id == 8"
                class="logout-button"
              >
                Return to estimates list
              </button>


                <CalculateBlock
                    v-if="$route.params.id > 1 && $route.params.id < 6"
                    @transferToWidth="transferToWidth"
                    @transferToHeight="transferToHeight"
                />

            </div>
            <div class="questions-section__block">
                <router-view></router-view>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import CalculateBlock from "@/components/CalculateBlock.vue";
import { useRouter } from "vue-router";
import { useEstimateStore } from "@/stores/estimateStore";
import { useUiStore } from "@/stores/uiStore";
import { OneDimension } from "@/models/structure";
import { useDictStore } from "@/stores/dictStore";

const router = useRouter();
const uiStore = useUiStore();
const estimateStore = useEstimateStore();
const dictStore = useDictStore();


console.debug('loadAllDicts() from CalculateView');
dictStore.loadAllDicts();

const arraySteps: string[] = uiStore.steps;

const validatedPageNo = estimateStore.estimate?.progress?.validatedPage.toFixed(0);

function updateData (idx: number) {
  uiStore.changePage(idx);
}

async function startNewEstimate() {
  try {
    await reloadDictsSilent().then(() => {
      estimateStore.newEstimate();
      router.push(`/calculation/1`);
    })
  } catch (e) {
    console.error(e);
  }
}

function returnToList() {
  router.push(`/calculation/`);
}
async function reloadDicts() {
    dictStore.clearAllDicts();
    await dictStore.loadAllDictsCurrentDay();
    alert('Dicts and prices reloaded');
}

async function reloadDictsSilent() {
  dictStore.clearAllDicts();
  await dictStore.loadAllDictsCurrentDay();
}

function logoutClick() {
    router.push('/logout');
}

function loadDraftEstimate() {
    estimateStore.loadDraft();
    const redirectedToPage = estimateStore.estimate?.progress?.activePage ? estimateStore.estimate.progress.activePage : 1;
    router.push(`/calculation/${redirectedToPage}`);
}

function transferToWidth(value:OneDimension) {
    uiStore.transferToWidth(value)
}
function transferToHeight(value: OneDimension) {
    uiStore.transferToHeight(value)
}

</script>

<style scoped>
.questions-section__button {
    position: relative;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: #2491eb;
    width: 100%;
    padding: 14px 0;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;
    text-transform: uppercase;
}

.questions-section__button::before {
    position: absolute;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    content: "";
    left: -4px;
    top: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 30px;
    background: rgba(36, 145, 235, 0.4);
}

.questions-section__list {
    display: grid;
    row-gap: 20px;
    margin-bottom: 30px;
}

.questions-section__list a {
    position: relative;
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 0;
    transition: 0.5s ease;
}

.questions-section__list a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: #2491eb;
    opacity: 0;
    transition: 0.5s ease;
}

.questions-section__list a.active-step {
    color: #fff;
    padding-left: 20px;
    transition: 0.5s ease;
}

.questions-section__list a.active-step:before {
    opacity: 1;
    transition: 0.8s ease;
}

.questions-section__list a span {
    font-weight: 400;
}

.questions-section__title {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 19px;
    margin-bottom: 20px;
    text-decoration: none;
}
.questions-section__title  a, .questions-section__title a:visited, .questions-section__title a:hover, .questions-section__title a:active {
    color: inherit;
}

.questions-section__nav {
    margin: 20px 0;
    padding: 20px;
    border-radius: 20px;
    background: rgba(247, 247, 250, 0.2);
    height: calc(100vh - 40px);
    overflow-y: auto;
}

.questions-section {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #052040 0%, #1b62a4 100%), #fff;
}

.questions-section__wrapper {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 320px 1fr;
    height: 100%;
    column-gap: 20px;
    overflow: hidden;
}

.questions-section__block {
    width: 100%;
}

.continue-last-project-button {
    display: inline-block;
    width: 276px;
    padding: 14px 20px;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 30px;
    cursor: pointer;
    position: absolute;
    top: 512px;
    left: 40px;
    gap: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.continue-last-project-button:hover {
    background-color: #fff;
    color: #000;
}

.reload-dicts-button {
    display: inline-block;
    width: 276px;
    padding: 14px 20px;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 30px;
    cursor: pointer;
    position: absolute;
    top: 712px;
    left: 40px;
    gap: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.logout-button {
    display: inline-block;
    width: 276px;
    padding: 14px 20px;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 30px;
    cursor: pointer;
    position: absolute;
    top: 775px;
    left: 40px;
    gap: 10px;
    transition: background-color 0.3s, color 0.3s;
}



</style>
