<template>
    <div class="step__block-doortype">
        <div>
            <span>Perimeter frame:</span>
        </div>
        <div>
            <select-dict
                v-model="mounts.structureTypeCode"
                :select-list="mountsTypes"
                :select-first-item="true"
            />
        </div>
    </div>
    <div class="step__block-wrapper">
        <div class="step__block-container step__block-container--top">
            <div></div>
            <div></div>
            <div class="step__block-div">
                <span>Q-ty</span>
            </div>
            <div>
                <span>{{ priceCode }}</span>
            </div>
            <div>
                <span>SUM</span>
            </div>
        </div>
        <template v-for="hardware of mounts.hardwares" v-bind:key="hardware.hardwareTypeCode">
            <div class="step__block-container">
                <div>
                    <span>{{ getHardwareItemTitle(hardware.hardwareTypeCode) }}:</span>
                </div>
                <div>
                    <select-dict
                        v-model="hardware.hardwareValueCode"
                        :selectList="getHardwareItemDict(hardware.hardwareTypeCode)"
                    />
                </div>
                <div class="step__block-count">
                    <input type="number" v-model="hardware.qty" min="0" max="9999" step="1"  @input="up($event)"/>
                    <span>{{ getHardwareItemUnit(hardware.hardwareTypeCode) }}</span>
                </div>
                <div>
                    <span>{{
                            formatMoney(getHardwareItemPrice(hardware.code))
                        }}</span>
                </div>
                <div>
                    <span>{{
                            formatMoney(getHardwareItemSum(hardware.code))
                        }}</span>
                </div>
            </div>
        </template>
    </div>
    <div class="step__block-field">
        <div class="step__block-field-block">
            <h3>page Total:</h3>
            <span class="step__block-field-price">{{ formatMoney(pageSum) }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">

import SelectDict from "@/components/UI/SelectDict.vue";
import { cloneDeep } from "loadsh";
import { computed, ref, Ref, watch } from "vue";
import { useEstimateStore } from "@/stores/estimateStore";
import { Structure, StructureItem } from "@/models/structure";
import { useDictStore } from "@/stores/dictStore";
import { CalculationItem } from "../../models/calculate";
import { getFromDictByCode } from "../../stores/dictStore";
import { Dictionary } from "../../models/dictionary";
import { CalculateService, MountsCalculatorBuilder } from "../../services/calculateService";
import { formatMoney } from "../../utils/common";
import { EstimateService } from "../../services/estimateService";
import {usePriceStore} from "../../stores/priceStore";

let sumKey = usePriceStore().data.currentSumKey;
let priceCode = usePriceStore().data.currentPriceKey;

function up(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.indexOf(".") !== -1) {
        const decimalIndex = inputElement.value.indexOf(".");
        const decimalPart = inputElement.value.substring(decimalIndex + 1);

        if (decimalPart.length > 1) {
            inputElement.value = inputElement.value.substring(0, decimalIndex + 2);
        }

        setTimeout(() => {
            inputElement.dispatchEvent(new Event('input'));
        }, 0);
    }
}



defineExpose({validatePage, savePage, reloadPage});

const dictStore = useDictStore();
const estimateStore = useEstimateStore();
let activeStructure: Ref<Structure> = ref(cloneDeep(estimateStore.getActiveStructure()));

const mounts: Ref<StructureItem> = ref(<StructureItem>cloneDeep(estimateStore.getActiveMounts()));
const pageCalculation: Ref<CalculationItem> = ref(null);

let mountsUpdatingFlag: boolean = false;

const mountsTypes = dictStore.getStructureTypes('mounts')
const hardwareTypes = dictStore.getHardwareTypes('mounts')

const pageSum = computed(() => (pageCalculation.value?.[priceCode] + pageCalculation.value.subitems?.[sumKey]) * pageCalculation.value.qty)


function getHardwareItemTitle(hardwareTypeCode: string): string {
    let title = getFromDictByCode(dictStore.getHardwareTypes('mounts'), hardwareTypeCode)?.title;
    // Replace title for mounts CGF systems
    return title.replace("CGF ", "")
}

function getHardwareItemUnit(hardwareTypeCode: string): string {
    return getFromDictByCode(dictStore.getHardwareTypes('mounts'), hardwareTypeCode)?.unit;
}

function getHardwareItemDict(hardwareTypeCode: string): Dictionary[] {
    return dictStore.getHardwareValues(hardwareTypeCode);
}

function getHardwareItemPrice(code: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, code)?.[priceCode]
}

function getHardwareItemSum(code: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, code)?.[sumKey]
}

watch(() => mounts.value.structureTypeCode, () => {
    if (mountsUpdatingFlag) {
        console.debug('     !!! mounts updating');
        return;
    }
    mountsUpdatingFlag = true;
    mounts.value.hardwares = EstimateService.mountsHardwareFactory(
        mounts.value,
        estimateStore.estimate.info.installedSystemCode,
        estimateStore.getActiveStructure().partition,
        estimateStore.getActiveDoor(),
        estimateStore.getActiveStructure().fillers.find(item => item.structureTypeCode === 'transom')  // первый попавшийся трансом
    )
    mountsUpdatingFlag = false;
});

watch(mounts, () => {
        if (mountsUpdatingFlag) {
            console.debug('     !!! mounts updating');
            return;
        }
        mountsUpdatingFlag = true;
        const pageCalculationLocal = MountsCalculatorBuilder.fromStructure(
            mounts.value,
            estimateStore.estimate.info.installedSystemCode,
        );
        CalculateService.deepCalculateSubitems(pageCalculationLocal);
        CalculateService.deepCalculateSubItemsB2C(pageCalculationLocal);
        CalculateService.deepCalculateSubItemsB2B(pageCalculationLocal);
        pageCalculation.value = pageCalculationLocal;
        mountsUpdatingFlag = false;
    }, {deep: true, immediate: true}
)


function savePage() {
    estimateStore.updateMounts(mounts.value);
}

function validatePage() {
    return true;
}

function reloadPage() {
    mounts.value = cloneDeep(estimateStore.getActiveMounts());
}

</script>

<style scoped>
.step__block-wrapper {
    display: grid;
    row-gap: 5px;
    padding: 15px 0;
    border-top: 1px solid #d2d8e3;
}

.step__block-count {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    column-gap: 15px;
}

.step__block-container {
    display: grid;
    grid-template-columns: 120px 280px 92px repeat(2, 1fr);
    column-gap: 15px;
}

.step__block-wrapper--bottom .step__block-container {
    grid-template-columns: 180px 220px 72px repeat(2, 1fr);
}

.step__block-container--top {
    margin-bottom: 10px;
}

.step__block-container div:first-child {
    align-items: flex-start;
}

.step__block-container div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.step__block-container div.step__block-div {
    align-items: center;
}

.step__block-container div input {
    text-align: center;
    padding: 0 10px;
    width: 70px;
    height: 35px;
    border-radius: 20px;
    background: #fff;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-field {
    padding: 15px 0;
    border-top: 1px solid #d2d8e3;
    border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
    color: #404d63;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field-price {
    color: #404d63;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.step__block-field--hardware .step__block-field-block {
    justify-content: flex-start;
    column-gap: 10px;
}

.step__block-doortype {
    display: grid;
    grid-template-columns: minmax(0, max-content) 316px;
    column-gap: 26px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 15px;
}

.step__block-doortype--transform {
    padding-top: 15px;
}

.step__block-doortype div:first-child {
    align-self: center;
    justify-self: center;
}

.select {
    margin-right: 20px;
}
</style>
