<template>
    <div>
        <div class="step__block-field">
            <div class="step__block-field-block">
                <h3>EXTRA:</h3><span></span>
                <span v-if="deliveryInfo?.extraCosts.length === 0">no extra</span>
                <span v-else>{{ formatMoney(extraSum) }}</span>
            </div>
        </div>
        <div class="step__block-table">
            <div class="step__block-item step__block-item--head">
                <div>
                    <span>Name</span>
                </div>
                <div>
                    <span>Qty</span>
                </div>
                <div>
                    <span>Price</span>
                </div>
                <div>
                    <span>Sum</span>
                </div>
                <div>
                    <span></span>
                </div>
            </div>
            <template v-if="deliveryInfo?.extraCosts.length > 0">
                <!-- extra table (extra mode)-->
                <div class="step__block-extra-block" v-for="item of deliveryInfo.extraCosts" :key="item.code">
                    <div>
                        <select-dict
                            v-model="item.costValueCode"
                            :selectList="extraItemsDict"
                            :is-extra="true"
                        ></select-dict>
                    </div>
                    <div>
                        <input-num v-model.number="item.qty"/>
                    </div>
                    <div>
                        <span>{{ formatMoney(getExtraItem(item.costValueCode)?.[priceCode]) }}</span>
                    </div>
                    <div>
                        <span>{{ formatMoney(item.qty * getExtraItem(item.costValueCode)?.[priceCode]) }}</span>
                    </div>
                    <div>
                        <button @click="removeExtraPosition(item.code)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                                <path
                                    d="M15 5L5 15"
                                    stroke="#404D63"
                                    stroke-opacity="0.3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M5 5L15 15"
                                    stroke="#404D63"
                                    stroke-opacity="0.3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </template>
            <button @click="addExtraPosition" class="step__block-extra-add">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                    <path
                        d="M7 0.5V12.5M1 6.5H13"
                        stroke="#404D63"
                        stroke-opacity="0.6"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                    />
                </svg>
                <span>Add position</span>
            </button>

        </div>
        <div class="step__block-field">
            <div class="step__block-field-block">
                <h3>SUBTOTAL:</h3>
                <span class="step__block-field-price">{{ formatMoney(subTotal) }}</span>
            </div>
        </div>
        <div :style="{display: multiplicatorDisplay}" class="step__block-list">
            <div class="step__block-wrapper">
                <div>
                    <span>Multiplicator:</span>
                </div>
                <div class="step__block-wrapper-right">
                    <span></span>
                    <input type="number" :value="deliveryInfo.multiplicator"
                           @input="changeMultiplicator($event.target.value)" min="0" step="0.01"/>
                </div>
            </div>
        </div>
<!--        temp fix isDiscountVisible needed-->
        <div v-if="true" class="step__block-list">
            <div class="step__block-wrapper">
                <div>
                    <span>Discount:</span>
                </div>
                <div class="step__block-wrapper-right">
                    <span class="discount-info">({{ formatMoney(discountPercentInfo) }})</span>
                    <span>%</span>
                    <input type="number" :value="deliveryInfo.discountPercent"
                           @input="changeDiscountPercent($event.target.value)" min="0" step="0.01" max="100"/>
                </div>
            </div>
            <div class="step__block-wrapper">
                <div>
                    <span>Discount SUM:</span>
                </div>
                <div class="step__block-wrapper-right">
                    <span class="">({{ discountSumInfo }} %)</span>
                    <span>$</span>
                    <input type="number" :value="deliveryInfo.discountSum"
                           @input="changeDiscountSum($event.target.value)" min="0" step="0.01" max="99000"/>
                </div>
            </div>
        </div>
        <div class="step__block-field step__block-field--estimate">
            <div class="step__block-field-block">
                <h3>ESTIMATE PRICE:</h3>
                <span class="step__block-field-price">{{ formatMoney(estimateSum) }}</span>
            </div>
        </div>
        <div class="step__block-list step__block-list--first">
            <template v-if="deliveryInfo?.deliveryCosts.length > 0">
                <div v-for="deliveryItem of deliveryInfo?.deliveryCosts" :key="deliveryItem.code"
                     class="step__block-wrapper">
                    <div>
                        <span>{{ getDeliveryItemTitle(deliveryItem.costTypeCode) }}</span>
                    </div>
                    <div class="step__block-wrapper-right">
                        <span>{{ getDeliveryItemUnit(deliveryItem.costTypeCode) }}</span>
                        <input type="number" v-model.number="deliveryItem.qty"/>
                    </div>
                </div>
            </template>
        </div>


    </div>
</template>

<script setup lang="ts">
import SelectDict from "@/components/UI/SelectDict.vue";
import {getFromDictByCode, useDictStore} from "@/stores/dictStore";
import {useEstimateStore} from "@/stores/estimateStore";
import {computed, reactive, Ref, ref, watch} from "vue";
import {cloneDeep} from "loadsh";
import {DeliveryInfo} from "@/models/estimate";
import {formatMoney} from "@/utils/common";
import {CalculateService, DeliveryCalculatorBuilder, EstimateCalulatorBuilder} from "@/services/calculateService";
import {CalculationItem} from "@/models/calculate";
import {EstimateService} from "@/services/estimateService";
import {useUserStore} from "@/stores/userStore";
import {Dictionary} from "@/models/dictionary";
import InputNum from "../UI/InputNum.vue";
import {useUiStore} from "@/stores/uiStore";
import { usePriceStore } from "../../stores/priceStore";

defineExpose({savePage, validatePage, addExtra, saveExtra, reloadPage});

let sumKey = usePriceStore().data.currentSumKey;
let priceCode = usePriceStore().data.currentPriceKey;

const dictStore = useDictStore();
const estimateStore = useEstimateStore();
const uiStore = useUiStore();

const deliveryInfo: Ref<DeliveryInfo> = ref(cloneDeep(estimateStore.getDeliveryInfo()));

const pageCalculation: Ref<CalculationItem> = ref(null);

const estimateCalculation: Ref<CalculationItem> = ref(null);

const isMultiplicatorVisible = !useUserStore().isSalesManager;

const multiplicatorDisplay = ref(isMultiplicatorVisible ? 'block' : 'none')

const isDiscountVisible = !useUserStore().isSalesManager;

// remove `none` items from list
const extraItemsDict = dictStore.getHardwareValues().filter(item => item.title !== 'none');

const discountPercentInfo = ref(0);

const discountSumInfo = ref(0);

if (deliveryInfo.value?.deliveryCosts === undefined) {
    deliveryInfo.value = EstimateService.deliveryInfoFactory();
}
estimateCalculation.value = EstimateCalulatorBuilder.fromEstimate(estimateStore.estimate);
CalculateService.deepCalculateSubitems(estimateCalculation.value);
CalculateService.deepCalculateSubItemsB2C(estimateCalculation.value);
CalculateService.deepCalculateSubItemsB2B(estimateCalculation.value);

pageCalculation.value = DeliveryCalculatorBuilder.fromDeliveryInfo(deliveryInfo.value);
CalculateService.deepCalculateSubitems(pageCalculation.value);
CalculateService.deepCalculateSubItemsB2C(pageCalculation.value);
CalculateService.deepCalculateSubItemsB2B(pageCalculation.value);

const extraSum = computed(() => CalculateService.getSubitemByCode(pageCalculation.value, 'extracosts')?.[sumKey]);

// total sum - delvery sum (with extra)
const subTotalNoDeliverySum = (estimateCalculation.value?.[sumKey] - CalculateService.getSubitemByCode(pageCalculation.value, 'delivery')?.[sumKey]);
// estimate overall sum with extra
const subTotal = computed(() => subTotalNoDeliverySum * deliveryInfo.value.multiplicator + extraSum.value);
// estimate overall sum with discount
changeDiscountPercent(usePriceStore().getDiscountPercent());
changeMultiplicator(usePriceStore().data.multiplicator);
const estimateSum = computed(() => subTotal.value * (1 - deliveryInfo.value.discountPercent / 100) - deliveryInfo.value.discountSum);

estimateStore.estimate.info.subtotal = subTotal;
estimateStore.estimate.info.estimatePrice = estimateSum;

const installationSum = computed(() => CalculateService.getSubitemByCode(pageCalculation.value, 'costs')?.[sumKey]);


function changeMultiplicator(val) {
    if (val !== deliveryInfo.value.multiplicator) {
        deliveryInfo.value.multiplicator = val;
        // на каждое движение сохраняем и пересчитываем
        if (deliveryInfo.value?.discountSum) {

        }

        if (deliveryInfo.value.discountPercent) {
            changeDiscountSum(deliveryInfo.value.discountPercent);
        }
        if (deliveryInfo.value.discountSum) {
            changeDiscountPercent(deliveryInfo.value.discountSum);
        }
        estimateStore.updateDeliveryInfo(deliveryInfo.value);
        uiStore.reloadSummary();
        uiStore.reloadSquare();
    }
}

function changeDiscountSum(val) {
    deliveryInfo.value.discountSum = val;
    if (val !== 0) {
        deliveryInfo.value.discountPercent = 0;
        changeDiscountPercent(0);
    }

    // deliveryInfo.value.discountPercent = Math.round(deliveryInfo.value.discountSum / subTotal.value * 10000) / 100; //
    discountSumInfo.value = val===0 ? 0 : Math.round(deliveryInfo.value.discountSum / subTotal.value * 10000) / 100;
    // на каждое движение сохраняем и пересчитываем
    estimateStore.updateDeliveryInfo(deliveryInfo.value);
    uiStore.reloadSummary();
}

function changeDiscountPercent(val) {
    deliveryInfo.value.discountPercent = val;
    if (val !== 0) {
        deliveryInfo.value.discountSum = 0;
        changeDiscountSum(0);
    }
    // deliveryInfo.value.discountSum = Math.round( subTotal.value * (deliveryInfo.value.discountPercent) ) / 100; //
    discountPercentInfo.value = val===0 ? 0 : Math.round(subTotal.value * (deliveryInfo.value.discountPercent)) / 100; //
    // на каждое движение сохраняем и пересчитываем
    estimateStore.updateDeliveryInfo(deliveryInfo.value);
    uiStore.reloadSummary();
}

function savePage() {
    estimateStore.updateDeliveryInfo(deliveryInfo.value);
}

function validatePage() {
    return true;
}

function addExtra() {
// todo remove later, functional moved away
}

function saveExtra() {
// todo remove later, functional moved away
}

function addExtraPosition() {
    deliveryInfo.value.extraCosts.push(
        EstimateService.additionalCostItemFactory({costTypeCode: '', qty: 1})
    );
}

function removeExtraPosition(code: string) {
    deliveryInfo.value.extraCosts = deliveryInfo.value.extraCosts.filter(item => item.code !== code);
}

function reloadPage() {
    deliveryInfo.value = cloneDeep(estimateStore.getDeliveryInfo());
    if (deliveryInfo.value?.deliveryCosts === undefined) {
        deliveryInfo.value = EstimateService.deliveryInfoFactory();
    }
}

watch(deliveryInfo, () => {
    pageCalculation.value = DeliveryCalculatorBuilder.fromDeliveryInfo(deliveryInfo.value);
    CalculateService.deepCalculateSubitems(pageCalculation.value);
    CalculateService.deepCalculateSubItemsB2C(pageCalculation.value);
    CalculateService.deepCalculateSubItemsB2B(pageCalculation.value);
}, {deep: true});

function getDeliveryItemTitle(deliveryTypeCode: string): string {
    return getFromDictByCode(dictStore.getAdditionalCosts('delivery'), deliveryTypeCode)?.title;
}

function getDeliveryItemUnit(deliveryTypeCode: string): string {
    return getFromDictByCode(dictStore.getAdditionalCosts('delivery'), deliveryTypeCode)?.unit;
}

function getDeliveryItemPrice(deliveryTypeCode: string, deliveryValueCode: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, deliveryTypeCode)?.[priceCode]
}

function getExtraItem(extraValueCode: string): Dictionary {
    return getFromDictByCode(dictStore.getHardwareValues(), extraValueCode);
}

function getExtraItemTitle(extraValueCode: string): string {
    return getFromDictByCode(dictStore.getHardwareValues(), extraValueCode)?.title;
}

function getExtraItemPrice(extraValueCode: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, extraValueCode)?.[priceCode]
}

function getExtraItemSum(extraValueCode: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, extraValueCode)?.[sumKey]
}

function getExtraItemUnit(extraValueCode: string): string {
    return getFromDictByCode(dictStore.getHardwareValues(), extraValueCode)?.unit;
}

</script>

<style scoped>
.step__block-extra-add {
    margin-top: 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px;
    color: rgba(64, 77, 99, 0.6);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.step__block-extra-unit {
    padding-right: 10px;
}

.step__block-extra {
    /*padding: 20px;*/
    /*background-color: #fff;*/
    /*border-radius: 10px;*/
    /*min-height: 450px;*/
}

.step__block-extra-container {
    display: grid;
    row-gap: 5px;
}

.step__block-extra-block {
    display: grid;
    grid-template-columns: 1fr repeat(4, 50px);
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    column-gap: 20px;
}

.step__block-extra-block div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.step__block-extra-block div button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.step__block-extra-block div input {
    width: 50px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
    background: #fbfbfb;
    text-align: center;
    padding: 0 10px;
}

.step__block-extra-block div:first-child {
    align-items: flex-start;
}

.step__block-extra-close {
    color: rgba(64, 77, 99, 0.3);
}

.step__block-extra-block--head {
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 10px;
}

.step__block-list {
    padding: 15px 0;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: grid;
    row-gap: 5px;
}

.step__block-list--first {
    padding-top: 15px;
}

.step__block-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}

.step__block-wrapper-right {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.step__block-wrapper-right input {
    width: 100px;
    height: 35px;
    border-radius: 20px;
    background: #fff;
    padding: 0 17px;
}

.step__block-table {
    padding: 15px 0;
    display: grid;
    row-gap: 5px;
}

.step__block-item {
    display: grid;
    grid-template-columns: 1fr repeat(4, 50px);
    column-gap: 20px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-item--head {
    font-weight: 600;
}

.step__block-item-unit span {
    padding-right: 10px;
}

.step__block-item div:first-child {
    align-items: flex-start;
}

.step__block-item div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.step__block-field {
    padding: 15px 0;
    border-top: 1px solid #d2d8e3;
    border-bottom: 1px solid #d2d8e3;
}

.step__block-field.step__block-field--top {
    padding-top: 0;
    border-top: none;
}

.step__block-field h3 {
    color: #404d63;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field--estimate h3 {
    font-size: 22px;
}

.step__block-field-price {
    color: #404d63;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field--estimate .step__block-field-price {
    font-size: 22px;
}

.step__block-field-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
