import { defineStore } from "pinia";
import { Dictionary } from "../models/dictionary";
import { intersection } from "lodash";
import {useDictLoader, useDictSaver, useUsersLoader, useClientsDictLoader, useDictLoaderByDate} from "../utils/api";
import { useUserStore } from "./userStore";
import {useEstimateStore} from "./estimateStore";
import {reactive} from "vue";
import {StructureItem} from "../models/structure";
import dayjs from "dayjs";

export const useDictStore = defineStore("dict", () => {

    console.debug('init dict store')

    const dicts = {
        usStates: [],
        dealTypes: [],
        spaceDescriptions: [],
        systemTypes: [],
        installationPlaces: [],
        installationUsage: [],
        finishColors: [],
        structureTypes: [],
        doorTypes: [],
        glassTypes: [],
        glassSort: [],
        glassThick: [],
        hardwareTypes: [],
        hardwareValues: [],
        additionalCostTypeExtra: [],
        additionalCostTypes: [],
        transomTypes: [],
        fillerTypes: [],
        aluminumMaterialTypes: [],
        additionalCostValues: [],
        managers: [],
        estimators: [],
        clients: [],
        multiplicator: []

    }

    // для загрузки по f5

    // useUserStore().checkLogin().then(() => {
    //     console.debug('wait for logged user to init dicts', {
    //         'dictLoaded': dictLoaded,
    //         'user logged': useUserStore().isLogged
    //     });
    //     if (!dictLoaded && useUserStore().isLogged) {
    //         console.debug(' before load all dicts');
    //         loadAllDicts();
    //     }
    // })


    function getAllDicts(): string[] {
        return Object.keys(dicts);
    }

    function clearAllDicts() {
        getAllDicts().forEach( (name) => {
            localStorage.removeItem('dict.' + name + '.cache');
            localStorage.removeItem('dict.' + name);
        });
    }

    function loadAllDicts() {
        console.debug('load all dicts');
        getAllDicts().forEach(dict => loadDict(dict));
    }

    async function loadAllDictsCurrentDay() {
        console.debug('load all dicts');
        for (const dict of getAllDicts()) {
            await loadDictByDate(dict, dayjs().format('YYYY-MM-DD'));
        }
    }

    async function loadDict(name: string, force = false) {

        loadDictLocal(name);

        if (dicts[name] && dicts[name].length && !force) {
            return;
        }

        let data = [];

        switch (name) {
            case 'estimators':
                await loadUsers('estimators');
                break;
            case 'managers':
                await loadUsers('managers');
                break;
            case 'clients':
                await loadClients();
                break;
            default:
                data = await useDictLoader(name);
                if (!data) {
                    // console.log(`dict ${name} load error (empty data)`);
                    return;
                }
                dicts[name] = data;
        }
        // ждем пока загрузится
        // while (dicts[name].length === 0) {};

        storeDictLocal(name);
    }

    function loadDictLocal(name: string): void {
        // проверяем на истек ли кэш для справочника
        let dictCache = (localStorage.getItem('dict.' + name + '.cache') != null)
            ? new Date(Number(localStorage.getItem('dict.' + name + '.cache')))
            : null;
        // проверяем, не протух ли кэш для справочника
        if (dictCache && dictCache > (new Date())) {
            dicts[name] = JSON.parse(localStorage.getItem('dict.' + name));
        } else dicts[name] = []

    }

    function loadDictLocalByDate(name: string, date: string): void {
        // проверяем на истек ли кэш для справочника
        let dictCache = (localStorage.getItem('dict.' + name + date + '.cache') != null)
            ? new Date(Number(localStorage.getItem('dict.' + name + date + '.cache')))
            : null;
        // проверяем, не протух ли кэш для справочника
        if (dictCache && dictCache > (new Date())) {
            dicts[name] = JSON.parse(localStorage.getItem('dict.' + name + date));
        } else dicts[name] = []

    }

    function storeDictLocalByDate(name: string, date: string): void {
        localStorage.setItem('dict.' + name + date, JSON.stringify(dicts[name]));
        localStorage.setItem('dict.' + name + date + '.cache', (new Date().getTime() + (30 * 60 * 1000)).toString());  // +30 мин
    }

    function storeDictLocal(name: string): void {
        localStorage.setItem('dict.' + name, JSON.stringify(dicts[name]));
        localStorage.setItem('dict.' + name + '.cache', (new Date().getTime() + (30 * 60 * 1000)).toString());  // +30 мин
    }

    function loadAllDictsByDate(date) {
        console.debug('load all dicts by date');
        getAllDicts().forEach(dict => loadDictByDate(dict, date));
    }

    async function loadDictByDate(name: string, date: string) {
        //loadDictLocalByDate(name, date);

        /*if (dicts[name] && dicts[name].length && !force) {
            return;
        }*/

        let data = [];
        switch (name) {
            case 'estimators':
                await loadUsers('estimators');
                break;
            case 'managers':
                await loadUsers('managers');
                break;
            case 'clients':
                await loadClients();
                break;
            default:
                data = await useDictLoaderByDate(name, date);
                if (!data) {
                    // console.log(`dict ${name} load error (empty data)`);
                    return;
                }
                dicts[name] = data;
        }

        storeDictLocal(name);
        //storeDictLocalByDate(name, date);
    }

    async function loadClients() {
        const data = await useClientsDictLoader();

        if (!data) {
            // console.log(`dict clients load error (empty data)`);
            return;
        }

        dicts['clients'] = data.data;
    }


    async function loadUsers(role) {
        const data = await useUsersLoader(role);

        if (!data) {
            // console.log(`dict ${role} load error (empty data)`);
            return;
        }

        switch (role) {
            case 'estimators':
                dicts['estimators'] = data.data;
                break;
            case 'managers' :
                dicts['managers'] = data.data;
                break;
        }
    }


    function filterAppliedTo(dictName: string, appliedTo: string | string[] = null): Array<Dictionary> {

        loadDict(dictName);

        let dataArray = dicts[dictName];

        if (!dataArray) {
            return [];
        }

        if (!appliedTo) {
            return dataArray;
        }
        appliedTo = (typeof appliedTo === 'string') ? [appliedTo] : appliedTo;
        return dataArray.filter((item) =>
            ((item.applyTo === undefined) || (!item.applyTo.length))
                ? true
                : intersection(item.applyTo, appliedTo).length === appliedTo.length
        );
    }

    function getUsStates(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('usStates', appliedTo);
    }

    function getDealTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('dealTypes', appliedTo);
    }

    // todo заменитьно тексту на installationUsage, 2 раза одни справочник
    function getSystemUsages(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('installationUsage', appliedTo);
    }

    function getManagers(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('managers', appliedTo);
    }

    function getEstimators(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('estimators', appliedTo);
    }

    function getClients(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('clients', appliedTo);
    }

    function getSystemTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('systemTypes', appliedTo);
    }

    function getFinishColors(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('finishColors', appliedTo);
    }

    function getInstallationPlaces(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('installationPlaces', appliedTo);
    }

    function getInstallationUsage(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('installationUsage', appliedTo);
    }

    function getSpaceDescriptions(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('spaceDescriptions', appliedTo);
    }

    function getStructureTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('structureTypes', appliedTo);
    }

    function getDoorTypes(appliedTo: string | string[] = null): Dictionary[] {
        return filterAppliedTo('doorTypes', appliedTo);
    }

    function getGlassTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('glassTypes', appliedTo);
    }

    function getGlassSorts(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('glassSort', appliedTo);
    }

    function getGlassThickness(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('glassThick', appliedTo);
    }

    function getGlassThicknessIncludeDoorCondition(appliedTo: string = null, doorCode: string = null): Dictionary[] {
        let glassThickness = getGlassThickness(appliedTo);
        if (doorCode == null) {
            return glassThickness;
        } else {
            if ((['kmp-s', 'kmp-d'].includes(doorCode))) {
                glassThickness = glassThickness.filter(function (v) {
                    if (v.code == '1-4') {
                        return v;
                    }
                })
            }
            if ((['s-sw', 'd-sw'].includes(doorCode))) {
                glassThickness = glassThickness.filter(function (v) {
                    if (v.code == '1-2' || v.code == '3-8') {
                        return v;
                    }
                })
            }
        }

        return glassThickness;
    }

    function getHardwareTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('hardwareTypes', appliedTo);
    }

    function getHardwareValues(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('hardwareValues', appliedTo);
    }

    function getCostTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('additionalCostTypes', appliedTo);
    }

    function getTransomTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('transomTypes', appliedTo);
    }

    function getFillerTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('fillerTypes', appliedTo);
    }

    function getAluminumMaterialTypes(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('aluminumMaterialTypes', appliedTo);
    }

    function getAdditionalCosts(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('additionalCostTypes', appliedTo);
    }

    function getAdditionalCostExtras(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('additionalCostTypeExtra', appliedTo);
    }

    function getAdditionalCostValues(appliedTo: string = null): Dictionary[] {
        return filterAppliedTo('additionalCostValues', appliedTo);
    }

    // prices
    function findGlassPrice(glassTypeCode: string, glassThicknessCode: string): Dictionary {
        return getFromDictByCode(getGlassTypes(''), `${glassTypeCode}-${glassThicknessCode}`);
    }

    function findDoorHardwarePrice(hardwareTypeCode: string, hardwareValueCode: string): Dictionary {
        return getFromDictByCode(getHardwareValues(hardwareTypeCode), hardwareValueCode);
    }

    function findDoorAdditionalCostsPrice(additionalCostsCode: string): Dictionary {
        return getFromDictByCode(getAdditionalCostValues(), additionalCostsCode);
    }

    function findOutOfSquarePrice(additionalCostsCode: string): Dictionary {
        return getFromDictByCode(getAdditionalCostValues(), additionalCostsCode);
    }

    function findAssetsPrice(additionalCostsCode: string): Dictionary {
        return getFromDictByCode(getAdditionalCostValues(), additionalCostsCode);
    }

    function findAluminumFillerPrice(aluminumTypeCode: string): Dictionary {
        return getFromDictByCode(getAluminumMaterialTypes(), aluminumTypeCode);
    }

    function findTransomHardwarePrice(hardwareTypeCode: string, hardwareValueCode: string): Dictionary {
        return getFromDictByCode(getHardwareValues(hardwareTypeCode), hardwareValueCode);
    }

    function findMountHardwarePrice(hardwareTypeCode: string, hardwareValueCode: string): Dictionary {
        return getFromDictByCode(getHardwareValues(hardwareTypeCode), hardwareValueCode);
    }

    function findAdditionalCostPrice(additionalCostTypeCode: string, additionalCostValueCode: string): Dictionary {
        return getFromDictByCode(getAdditionalCostValues(additionalCostTypeCode), additionalCostValueCode);
    }

    function findHardwarePrice(hardwareTypeCode: string, hardwareValueCode: string): Dictionary {
        return getFromDictByCode(getHardwareValues(hardwareTypeCode), hardwareValueCode);
    }


    return {
        dicts,
        loadDict,
        loadAllDicts,
        loadAllDictsByDate,
        loadAllDictsCurrentDay,
        clearAllDicts,
        getUsStates,
        getDealTypes,
        getFinishColors,
        getInstallationPlaces,
        getInstallationUsage,
        getSpaceDescriptions,
        getSystemUsages,
        getManagers,
        getEstimators,
        getClients,
        // estimate
        getStructureTypes,
        getSystemTypes,
        getDoorTypes,
        getGlassTypes,
        getGlassSorts,
        getGlassThickness,
        getHardwareTypes,
        getCostTypes,
        getTransomTypes,
        getHardwareValues,
        getFillerTypes,
        getAluminumMaterialTypes,
        getAdditionalCosts,
        getAdditionalCostValues,
        getAdditionalCostExtras,
        //prices
        findGlassPrice,
        findDoorHardwarePrice,
        findDoorAdditionalCostsPrice,
        findOutOfSquarePrice,
        findAssetsPrice,
        findAluminumFillerPrice,
        findTransomHardwarePrice,
        findMountHardwarePrice,
        findAdditionalCostPrice,
        findHardwarePrice,
        getGlassThicknessIncludeDoorCondition
    };
});

export function getFromDictByCode(dict: Dictionary[], code): Dictionary {
    if (!dict) {
        console.error(dict + ' is not dictionary');
        return null;
    }
    return dict.find(
        (item) => item.code === code
    )
}

export function getTitleFromDictByCode(dict: Dictionary[], code): string {
    return getFromDictByCode(dict, code)?.title;
}
