<template>
    <template v-if="isDisplayedBlock">
        <template v-if="isBlockWithAccordion">
            <div class="step__accordion-head">
                <div class="step__accordion-overlay" @click="toggleAccordion"></div>
                <svg
                    :class="['step__accordion-svg']"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="9"
                    viewBox="0 0 16 9"
                    fill="none"
                >
                    <path d="M2 2L8 8L14 2" stroke="white" stroke-width="2" stroke-linecap="square"
                          stroke-linejoin="round"/>
                </svg>
                <h3>{{ block.title }}</h3>
            </div>
            <div class="step__accordion-body step__accordion-body--general step__accordion-item--active">
                <template v-if="isHaveSubBlocks">
                    <div v-for="subblock of block.subBlocks" :key="subblock.code" class="step__accordion-wrapper">
                        <SummaryBlock
                            :summaryBlock="subblock"
                            @header-click-edit="(...args) => emit('headerClickEdit', ...args)"
                            @header-click-delete="(...args) => emit('headerClickDelete', ...args)"
                        />
                    </div>
                </template>
                <SummaryHeader v-if="isHaveLines && block.header" :summaryHeader="block.header"
                               @edit-click="emit"></SummaryHeader>
                <ul v-if="isHaveLines">
                    <SummaryLine v-for="line in block.lines" :key="line.code" :summaryLine="line"></SummaryLine>
                </ul>
            </div>
        </template>

        <template v-if="!isBlockWithAccordion">
            <div class="step__accordion-block">
                <SummaryHeader
                    v-if="isHaveLines && block.header"
                    :summaryHeader="block.header"
                    @header-click-edit="(...args) => emit('headerClickEdit', ...args)"
                    @header-click-delete="(...args) => emit('headerClickDelete', ...args)"
                />
                <ul v-if="isHaveLines" class="step__accordion-block-list">
                    <SummaryLine v-for="line in block.lines" :key="line.code" :summaryLine="line" />
                </ul>
            </div>
            <template v-if="isHaveSubBlocks">
                <template v-for="subblock of block.subBlocks" :key="subblock.code">
                    <SummaryBlock
                        :summaryBlock="subblock"
                        @header-click-edit="(...args) => emit('headerClickEdit', ...args)"
                        @header-click-delete="(...args) => emit('headerClickDelete', ...args)"
                    />
                </template>
            </template>
        </template>
    </template>
</template>

<script setup lang="ts">
import { computed, Ref, toRef } from "vue";
import SummaryHeader from "./SummaryHeader.vue";
import SummaryLine from "./SummaryLine.vue";
import { SummaryBlock as SummaryBlockType } from "../../../models/summary";

const props = defineProps({
    summaryBlock: {
        type: Object,
        required: true,
    }
});

const emit = defineEmits(['headerClickEdit', 'headerClickDelete']);
const block: Ref<SummaryBlockType> = toRef(() => props.summaryBlock);

console.debug('summary block', block.value);
const isBlockWithAccordion = computed(() => ['info', 'space', 'delivery', 'overhead'].includes(block.value.type));
const isHaveSubBlocks = computed(() => block.value.subBlocks?.length > 0)
const isHaveLines = computed(() => block.value.lines?.length > 0)
const isDisplayedBlock = computed(() => !!block.value)

function toggleAccordion(e) {
    e.target.parentNode.parentNode.classList.toggle("step__accordion-item--active");
}

</script>

<style scoped>

</style>
