<template>
    <div v-if="$route.params.id === '1'" class="step__block step__block--data">
        <div class="step__block-container">
            <span class="step__block-container-left">Estimate started:</span>
            <span>{{ formatDateTime(estimateInfo.started) }}</span>
        </div>
      <div class="step__block-container">
            <span class="step__block-container-left"
            >Price Date:</span>
        <input
          type="date"
          v-model="estimateInfo.priceDateFormated"
        />
      </div>
        <div class="step__block-container">
            <span class="step__block-container-left label-required">Deal type:</span>
            <select-dict
                v-model="estimateInfo.dealTypeCode"
                :select-list="dealTypes"
                :select-first-item="true"
                v-on:selectChanged="dealTypeCodeChanged"
            >
            </select-dict>
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left">Installation place:</span>
            <select-dict
                v-model="estimateInfo.installationPlaceCode"
                :select-list="installationPlaces"
            >
            </select-dict>
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left">Usage:</span>
            <select-dict
                v-model="estimateInfo.installationUsageCode"
                :select-list="installationUsageFiltered"
            >
            </select-dict>
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left label-required">System:</span>
            <select-dict
                v-model="estimateInfo.installedSystemCode"
                :select-list="systemTypes"
            >
            </select-dict>
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left label-required">Finish (color):</span>
            <select-dict
                v-model="estimateInfo.colorCode"
                :select-list="finishColors"
            >
            </select-dict>
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left label-required"
                >First and Last name (in full):</span>
            <select-input
                v-model="estimateInfo.client.name"
                :select-list="clientsDict"
            />
<!--            <input-->
<!--                type="text"-->
<!--                v-model="estimateInfo.client.name"-->
<!--                placeholder="James"-->
<!--            />-->
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left"
                >Business name (if applicable):</span>
            <input
                type="text"
                v-model="estimateInfo.client.businessName"
                placeholder="James BBB"
            />
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left">Email:</span>
            <input
                type="text"
                v-model="estimateInfo.client.email"
                placeholder="example@gmail/com"
            />
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left">Phone:</span>
            <input
                type="text"
                v-model="estimateInfo.client.phone"
                placeholder="+1 XXX-XXX-XXXX"
            />
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left">Shipping address:</span>
            <input
                type="text"
                v-model="estimateInfo.client.shippingAddress"
                placeholder="NY, Boynton Avenue, 1145"
            />
        </div>
        <div class="step__block-container">
            <span>Delivery:</span>
            <div class="step__block-labels">
                <label class="step__block-label">
                    <input
                        name="delivery"
                        v-model="estimateInfo.delivery"
                        value="true"
                        type="radio"
                    />
                    <span>yes</span>
                </label>
                <label class="step__block-label">
                    <input
                        name="delivery"
                        v-model="estimateInfo.delivery"
                        value="false"
                        type="radio"
                    />
                    <span>no</span>
                </label>
            </div>
        </div>
        <div class="step__block-container">
            <span>Installation:</span>
            <div class="step__block-labels">
                <label class="step__block-label">
                    <input
                        name="installation"
                        v-model="estimateInfo.installation"
                        value="true"
                        type="radio"
                    />
                    <span>yes</span>
                </label>
                <label class="step__block-label">
                    <input
                        name="installation"
                        v-model="estimateInfo.installation"
                        value="false"
                        type="radio"
                    />
                    <span>no</span>
                </label>
            </div>
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left">Shipping ZIP:</span>
            <input
                type="text"
                v-model="estimateInfo.shippingZip"
                placeholder=""
            />
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left">Shipping state:</span>
            <select-dict
                v-model="estimateInfo.shippingState"
                :select-list="states"
            />
        </div>
        <div class="step__block-container">
            <span class="step__block-container-left  label-required">Manager:</span>
            <input v-if="user.isSalesManager" v-model="authManager.title"/>
            <select-input-dict v-else
                   v-model="estimateInfo.managerCode"
                   :select-list="managers"
                   :options="managers"
                   label="title"/>

        </div>
        <div class="step__block-container">
            <span class="step__block-container-left">Estimator:</span>
            <input v-if="user.isSalesEstimator && authEstimator != undefined" v-model="authEstimator.title"/>

            <select-input-dict  v-else
                v-model="estimateInfo.estimatorCode"
                :select-list="estimators"
                :options="estimators"
                label="title"/>

        </div>
    </div>
</template>

<script setup lang="ts">


import {computed, Ref, ref, watch} from "vue";
import SelectDict from "../../components/UI/SelectDict.vue";
import SelectInput from "../../components/UI/SelectInput.vue";
import { cloneDeep } from 'lodash'
import { getTitleFromDictByCode, useDictStore } from "../../stores/dictStore";
import { usePriceStore } from "../../stores/priceStore";
import { useEstimateStore } from "../../stores/estimateStore";
import { EstimateInfo } from "../../models/estimate";
import { Dictionary } from "../../models/dictionary";
import { formatDateTime } from "../../utils/common";
import { info } from "sass";
import {useUserStore} from "../../stores/userStore";
import SelectInputDict from "../../components/UI/SelectInputDict.vue";
import {SummaryService} from "../../services/summaryService";
import dayjs from "dayjs";

defineExpose({ savePage, validatePage, renameEstimate });
const dictStore = useDictStore();
let dealTypes: Dictionary[] = dictStore.getDealTypes();
const systemTypes: Dictionary[] = dictStore.getSystemTypes();
const installationPlaces: Dictionary[] = dictStore.getInstallationPlaces();
const installationUsage: Dictionary[] = dictStore.getInstallationUsage();
const managers: Dictionary[] = dictStore.getManagers();
const estimators: Dictionary[] = dictStore.getEstimators();
const clientsDict: Dictionary = dictStore.getClients();
const states: Dictionary = dictStore.getUsStates();
const user = useUserStore();
//hide cost deal type for managers
if (user.isSalesManager) {
  dealTypes = dealTypes.filter(function (value) {
    if (value.code != "cost") {
      return value;
    }
  });
}
const estimateStore = useEstimateStore();
const estimateInfo: Ref<EstimateInfo> = ref(cloneDeep(estimateStore.estimate.info));

const isReadOnly = estimateStore.getEstimateReadonly();

const managerTitle = getTitleFromDictByCode(managers, estimateInfo.value.estimatorCode);

const estimatorTitle = getTitleFromDictByCode(estimators, estimateInfo.value.estimatorCode);

const authManager = computed(() => managers.filter((item: Dictionary)=> item.code === user.userCode)[0]);
const authEstimator = computed(() => estimators.filter((item: Dictionary)=> item.code === user.userCode)[0]);

const managersFiltered = computed(() => estimators.filter((item: Dictionary)=> item.code !== estimateInfo.value.estimatorCode));

const installationUsageFiltered = computed(() => dictStore.getInstallationUsage(estimateInfo.value.installationPlaceCode));

const systemUsages = computed(() => dictStore.getSystemUsages(estimateInfo.value.installationPlaceCode))

const finishColors = computed( () => {
    // console.log('finishColors', estimateInfo.value.installedSystemCode);
    return dictStore.getFinishColors(estimateInfo.value.installedSystemCode)
});

if (estimateStore?.estimate?.info?.dealTypeCode === null) {
  usePriceStore().initCurrentPriceRule();
}
const dealTypeCodeChanged = (key, value) => {
  estimateStore.estimate.info.dealTypeCode = value;
  console.log(estimateInfo)
}
function savePage() {

    if (user.isSalesEstimator && authEstimator.value != undefined) {
        estimateInfo.value.estimatorCode = authEstimator.value.code;
    }

    if (user.isSalesManager) {
        estimateInfo.value.managerCode = authManager.value.code;
    }

    if (!estimateInfo.value.title) {
        // fill project title only if empty
        renameEstimate( [
            estimateInfo.value.client.name,
            estimateInfo.value.installedSystemCode ? getTitleFromDictByCode(dictStore.getSystemTypes(), estimateInfo.value.installedSystemCode) : '',
            estimateInfo.value.shippingZip,
            estimateInfo.value.shippingState,
        ].filter(item => item ? item.trim() : false).join(', '));
    }
    estimateStore.updateInfo(estimateInfo.value);
}

function validatePage() {
    return estimateInfo.value.dealTypeCode
        && estimateInfo.value.installedSystemCode
        && estimateInfo.value.client?.name
        && estimateInfo.value.colorCode
        && estimateInfo.value.managerCode
}

function renameEstimate(value) {
    estimateInfo.value.title = value;
}

watch(() => estimateInfo.value.installationPlaceCode, () => {
    console.debug('watch estimateInfo.value.installationPlaceCode')
    estimateInfo.value.installationUsageCode = '';
})
watch(() => estimateInfo.value.priceDateFormated, () => {
  let date = dayjs(estimateInfo.value.priceDateFormated, 'YYYY-MM-DD').toDate();
  estimateInfo.value.priceDate = date.getTime();
  useDictStore().loadAllDictsByDate(estimateInfo.value.priceDateFormated);
})

</script>

<style scoped>
input[type="radio"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: rgba(217, 223, 229, 1) !important;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 1px solid transparent;
    -webkit-transform: translateY(-0.075em);
    -ms-transform: translateY(-0.075em);
    transform: translateY(-0.075em);
}

input[type="radio"] {
    display: -ms-grid;
    padding: 0 !important;
    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 120ms -webkit-transform ease-in-out;
    transition: 120ms -webkit-transform ease-in-out;
    -o-transition: 120ms transform ease-in-out;
    transition: 120ms transform ease-in-out;
    transition:
        120ms transform ease-in-out,
        120ms -webkit-transform ease-in-out;
    background: #fff;
}

input[type="radio"]:checked::before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    background: #fff;
}

.step__block {
    border-bottom: 1px solid #d2d8e3;
    padding-bottom: 20px;
}

.step__block--data,
.step__block-wrapper {
    padding-right: 80px;
    display: grid;
    row-gap: 10px;
}

.step__block-container {
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: grid;
    grid-template-columns: 40% 60%;
    column-gap: 10px;
}

.step__block-container-left {
    align-self: center;
}

.step__block-labels {
    display: flex;
    column-gap: 100px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-label {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.step__block--data .step__block-container input,
.step__block-wrapper .step__block-container input {
    padding: 9px 20px;
    background-color: #fff;
    border-radius: 25px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
</style>
