<template>
    <input type="number" :value="value" @input="valueChanged" :min="props.min" :max="props.max" :step="props.step"/>
</template>

<script setup lang="ts">
import { computed} from "vue";
const emit = defineEmits<{
    "update:modelValue": [value: number];
}>();

const props = withDefaults(defineProps<{
    modelValue: any,
    min?: number,
    max?: number,
    step?: number,
}>(), {
  min: 0,
  max: 999,
  step: 1,
})

const value = computed(()=> props.modelValue == "0" ? "" : props.modelValue);

function valueChanged(e:Event) {
    let newValue = parseInt(e.target.value);
    if (isNaN(newValue)) {
        newValue = 0;
    }
    emit('update:modelValue', newValue);
}
</script>

<style scoped>

</style>
