import { DeliveryInfo, Estimate, EstimatedSpace } from "../models/estimate";
import { createPinia, setActivePinia } from "pinia";
import { CalculationItem } from "../models/calculate";
import { AdditionalCostItem, Structure, StructureItem } from "../models/structure";
import { useDictStore } from "../stores/dictStore";
import { useUserStore } from "../stores/userStore";
import { usePriceStore } from "../stores/priceStore";
import {multiply} from "lodash";
import {computed} from "vue";

setActivePinia(createPinia())

export class CalculateService {
    private estimate: Estimate = null

    static getSubitemByCode(calculationItem: CalculationItem, elementCode: string, elementType: string | null = null): CalculationItem {
        if (!calculationItem) {
            return null;
        }

        if (calculationItem.elementCode === elementCode && (elementType ? (calculationItem.elementType === elementType) : true)) {
            return calculationItem;
        }
        //console.debug('getSubitemByCode calculationItem.subitems.items.length', calculationItem.subitems.items.length);
        if (calculationItem.subitems?.items != undefined && calculationItem.subitems.items.length === 0) {
            return null;
        }
        let subitemFound = null;
        for (const subItem of calculationItem.subitems.items) {
            subitemFound = this.getSubitemByCode(subItem, elementCode, elementType);
            if (subitemFound) {
                return subitemFound;
            }
        }
        return null;
    }

    static deepCalculateSubitems(calculatedItem: CalculationItem): number {
        if (!calculatedItem) {
            throw(new Error('calculatedItem undefined'));
        }
        if (calculatedItem?.subitems?.items) {
            calculatedItem.subitems.sum = calculatedItem.subitems.items.reduce(
                (acc, item) => item != null ? acc += this.deepCalculateSubitems(item) : 0,
                0
            )
        } else {
            calculatedItem.subitems.sum = 0;
        }

        calculatedItem.sum = (calculatedItem.subitems.sum ? calculatedItem.subitems.sum : (0 + calculatedItem?.price) ? calculatedItem?.price : 0) * (calculatedItem.qty ? calculatedItem.qty : 0);
        return calculatedItem.sum;
    }

    static deepCalculateSubItemsB2C(calculatedItem: CalculationItem): number {
        if (!calculatedItem) {
            throw(new Error('calculatedItem undefined'));
        }
        if (calculatedItem?.subitems?.items) {
            calculatedItem.subitems.sumB2C = calculatedItem.subitems.items.reduce(
                (acc, item) => item != null ? acc += this.deepCalculateSubItemsB2C(item) : 0,
                0
            )
        } else {
            calculatedItem.subitems.sumB2C = 0;
        }

        calculatedItem.sumB2C = (calculatedItem.subitems.sumB2C ? calculatedItem.subitems.sumB2C : (0 + calculatedItem?.priceB2C) ? calculatedItem?.priceB2C : 0) * (calculatedItem.qty ? calculatedItem.qty : 0);
        return calculatedItem.sumB2C;
    }

    static deepCalculateSubItemsB2B(calculatedItem: CalculationItem): number {
        if (!calculatedItem) {
            throw(new Error('calculatedItem undefined'));
        }
        if (calculatedItem?.subitems?.items) {
            calculatedItem.subitems.sumB2B = calculatedItem.subitems.items.reduce(
                (acc, item) => item != null ? acc += this.deepCalculateSubItemsB2B(item) : 0,
                0
            )
        } else {
            calculatedItem.subitems.sumB2C = 0;
        }

        calculatedItem.sumB2B = (calculatedItem.subitems.sumB2B ? calculatedItem.subitems.sumB2B : (0 + calculatedItem?.priceB2B) ? calculatedItem?.priceB2B : 0) * (calculatedItem.qty ? calculatedItem.qty : 0);
        return calculatedItem.sumB2B;
    }


    static calculationItemFactory(data: object = {}): CalculationItem {
        return {
            ...<CalculationItem>{
                elementCode: null,
                elementType: null,
                elementParams: null,
                subitems: {
                    sum: 0,
                    items: [],
                },
                price: 0,
                priceB2B: 0,
                priceB2C: 0,
                qty: 1,
                sum: 0,
                sumOrig: 0,
                multiplier: 1,
                discountSum: 0,
                discountPercent: 0
            }, ...data
        }
    }
}


export class EstimateCalulatorBuilder {
    public loadFromObject(): void {

    }

    static fromEstimate(estimate: Estimate): CalculationItem {
        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory(<CalculationItem>{
            elementType: 'estimate',
            elementCode: estimate?.info?.code,
            qty: 1,
            multiplier: estimate?.delivery?.multiplicator,
            discountPercent: estimate?.delivery?.discountPercent,
            discountSum: estimate?.delivery?.discountSum ? estimate?.delivery?.discountSum : 0,
        });
        if (estimate.hasOwnProperty('spaces') && Array.isArray(estimate.spaces)) {
            for (const space of estimate.spaces) {
                calculatedItem.subitems.items.push(SpaceCalulatorBuilder.fromSpace(space, estimate.info.installedSystemCode))
            }
        }
        if (estimate.overhead) {
            calculatedItem.subitems.items.push(OverheadCalculatorBuilder.fromOverheadItem(estimate.overhead))
        }
        if (estimate.delivery) {
            calculatedItem.subitems.items.push(DeliveryCalculatorBuilder.fromDeliveryInfo(estimate.delivery))
        }

        CalculateService.deepCalculateSubitems(calculatedItem);
        CalculateService.deepCalculateSubItemsB2C(calculatedItem);
        CalculateService.deepCalculateSubItemsB2B(calculatedItem);

        // в общей сумме не учитывается доставка
        // считаем наценки и скидки пока просто здесь и пишем в сумму
        // возможно если будет меняться алгоритм расчета то нужно будет куда-то перенести
        // !!!! такой же расчет делается на 7 шаге, править в обеих местах !!!
        calculatedItem.sum = (calculatedItem.sum
            - (CalculateService.getSubitemByCode(calculatedItem, 'delivery')?.sum ?? 0)) * calculatedItem.multiplier
            + (CalculateService.getSubitemByCode(calculatedItem, 'extracosts')?.sum ?? 0);

        calculatedItem.sumOrig = (calculatedItem.sum) / calculatedItem.multiplier +
            (CalculateService.getSubitemByCode(calculatedItem, 'extracosts')?.sum ?? 0);

        calculatedItem.subSum = (calculatedItem.sum
            - (CalculateService.getSubitemByCode(calculatedItem, 'delivery')?.sum ?? 0)) * calculatedItem.multiplier
            + (CalculateService.getSubitemByCode(calculatedItem, 'extracosts')?.sum ?? 0);

        calculatedItem.sum = calculatedItem.sum * (1 - calculatedItem.discountPercent / 100) - calculatedItem.discountSum;



        return calculatedItem;
    }
}


export class SpaceCalulatorBuilder {
    public loadFromObject(): void {

    }

    static fromSpace(space: EstimatedSpace, installedSystemCode: string | null = null): CalculationItem {
        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory({
            elementType: 'space',
            elementCode: space.code,
            qty: 1,
        });
        for (const structure of space.structures) {
            calculatedItem.subitems.items.push(StructureCalulatorBuilder.fromStructure(structure, installedSystemCode));
        }
        return calculatedItem;
    }
}


export class StructureCalulatorBuilder {
    public loadFromObject(): void {

    }

    static fromStructure(structure: Structure, installedSystemCode: string | null = null): CalculationItem {
        if (!structure) return null;

        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory({
            elementType: 'structure',
            elementCode: structure.code,
            qty: 1,
        });

        calculatedItem.subitems.items.push(PartitionCalculatorBuilder.fromStructure(structure.partition, installedSystemCode))

        for (const door of structure.doors) {
            calculatedItem.subitems.items.push(DoorCalculatorBuilder.fromStructure(door, installedSystemCode))
        }
        for (const filler of structure.fillers) {
            calculatedItem.subitems.items.push(FillerCalculatorBuilder.fromStructure(filler, installedSystemCode))
        }

        // обратная совместимость, когда моунтс были массивом
        if (structure.mounts) {
            if (Array.isArray(structure.mounts)) {
                for (const mount of structure.mounts) {
                    calculatedItem.subitems.items.push(MountsCalculatorBuilder.fromStructure(mount, installedSystemCode))
                }
            } else {
                calculatedItem.subitems.items.push(MountsCalculatorBuilder.fromStructure(structure.mounts, installedSystemCode))
            }
        }

        // for (const costs of structure.additionalCosts) {
        //     calculatedItem.subitems.items.push(Costs.fromStructure(costs))
        // }

        return calculatedItem;
    }
}

export class PartitionCalculatorBuilder {
    public loadFromObject(): void {
    }

    static fromStructure(structure: StructureItem, installedSystemCode: string | null = null): CalculationItem {
        if (!structure) return null;
        console.debug('rebuild PartitionCalculator');
        const dictStore = useDictStore();

        let glassPriceMultiplier = 1;
        // для систем kompas double цена на стекло удваивается (на самом деле удваивается количество стекла)
        if (installedSystemCode === 'kompas-dbl') {
            glassPriceMultiplier = 2;
        }

        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory({
            elementCode: structure.code,
            elementType: 'partition',
            qty: structure.qty,
        });

        // push priced elements
        calculatedItem.subitems.items.push(
            CalculateService.calculationItemFactory({
                elementCode: 'glass',
                elementType: 'glass',
                params: [structure.glassSortCode, structure.glassThicknessCode],
                price:(dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.price * glassPriceMultiplier).toFixed(2),
                priceB2C:(dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.priceB2C * glassPriceMultiplier).toFixed(2),
                priceB2B:(dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.priceB2B * glassPriceMultiplier).toFixed(2),
                qty: structure.dimensions.areaFt,
            })
        );
        let outOfSquarePrice = dictStore.findOutOfSquarePrice('outsq-01');
        if (!dictStore.findOutOfSquarePrice('outsq-01')?.price) {
            outOfSquarePrice = dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode);
        }
        calculatedItem.subitems.items.push(
            CalculateService.calculationItemFactory({
                elementCode: 'outOfSquare',
                elementType: 'outofsquare',
                params: [],
                price: outOfSquarePrice?.price,
                priceB2C: outOfSquarePrice?.priceB2C,
                priceB2B: outOfSquarePrice?.priceB2B,
                qty: structure.outOfSquareQty,
            })
        );
        CalculateService.deepCalculateSubitems(calculatedItem);
        CalculateService.deepCalculateSubItemsB2C(calculatedItem);
        CalculateService.deepCalculateSubItemsB2B(calculatedItem);
        return calculatedItem;
    }
}

export class DoorCalculatorBuilder {
    public loadFromObject(): void {
    }

    static fromStructure(structure: StructureItem, installedSystemCode: string | null = null): CalculationItem {
        if (!structure) return null;
        //console.debug('rebuild DoorCalculator');
        const dictStore = useDictStore();
        // create top item
        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory({
            elementCode: structure.code,
            elementType: 'door',
            qty: structure.qty,
        });
        // признак особенных цен
        let glassSpecialPrice = null
        // если надо умножить цену
        let glassPriceMultiplier = 1;

        // !!! для дверей kmp-s kmp-d цена для стекла по умолчанию clear 1/4 = 0
        if (['kmp-s', 'kmp-d'].includes(structure.structureTypeCode)) {
            if (structure.glassSortCode === 'clear' && structure.glassThicknessCode === '1-4') {
                glassSpecialPrice = 0;
            }
        }

        // для дверей kompas double цена не удваивается
        if (['kompas-sgl', 'kompas-dbl'].includes(installedSystemCode)) {
            glassPriceMultiplier = 1;
        }

        // add priced elements
        // glass
        calculatedItem.subitems.items.push(
            CalculateService.calculationItemFactory({
                elementCode: 'glass',
                elementType: 'glass',
                params: [structure.glassSortCode, structure.glassThicknessCode],
                price: (glassSpecialPrice === null)
                    ? (dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.price * glassPriceMultiplier).toFixed(2)
                    : glassSpecialPrice,
                priceB2C: (glassSpecialPrice === null)
                    ? (dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.priceB2C * glassPriceMultiplier).toFixed(2)
                    : glassSpecialPrice,
                priceB2B: (glassSpecialPrice === null)
                    ? (dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.priceB2B * glassPriceMultiplier).toFixed(2)
                    : glassSpecialPrice,
                qty: structure.dimensions.areaFt,
            })
        );
        // hardwares
        for (const hardwareItem of structure.hardwares) {
            calculatedItem.subitems.items.push(
                CalculateService.calculationItemFactory({
                    elementCode: hardwareItem.code,
                    elementType: 'hardware',
                    params: [hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode],
                    price: dictStore.findDoorHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.price,
                    priceB2C: dictStore.findDoorHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.priceB2C,
                    priceB2B: dictStore.findDoorHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.priceB2B,
                    qty: hardwareItem.qty,
                })
            );
        }
        for (const additionalCost of structure.additionalCosts) {
            calculatedItem.subitems.items.push(
                CalculateService.calculationItemFactory({
                    elementCode: additionalCost.code,
                    elementType: 'additionalcost',
                    params: [additionalCost.costTypeCode, additionalCost.costValueCode],
                    price: dictStore.findDoorAdditionalCostsPrice(additionalCost.costValueCode)?.price,
                    priceB2C: dictStore.findDoorAdditionalCostsPrice(additionalCost.costValueCode)?.priceB2C,
                    priceB2B: dictStore.findDoorAdditionalCostsPrice(additionalCost.costValueCode)?.priceB2B,
                    qty: additionalCost.qty,
                })
            );
        }

        CalculateService.deepCalculateSubitems(calculatedItem);
        CalculateService.deepCalculateSubItemsB2C(calculatedItem);
        CalculateService.deepCalculateSubItemsB2B(calculatedItem);
        return calculatedItem;
    }
}

export class FillerCalculatorBuilder {
    public loadFromObject(): void {
    }

    static fromStructure(structure: StructureItem, systemType: string | null = null): CalculationItem {
        let glassPriceMultiplier = 1;
        const installedSystemCode = '';
        const dictStore = useDictStore();
        // create top item
        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory({
            elementCode: structure.code,
            elementType: 'filler',
            qty: structure.qty,
        });

        // if type=none, still need out of square
        if (!structure.structureTypeCode) {
            return calculatedItem;
        }

        if (systemType === 'kompas-dbl') {
            glassPriceMultiplier = 2;
        }

        // add priced elements
        // glass
        if (structure.fillerTypeCode === 'glass') {
            calculatedItem.subitems.items.push(
                CalculateService.calculationItemFactory({
                    elementCode: 'glass',
                    params: [structure.glassSortCode, structure.glassThicknessCode],
                    price: (dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.price * glassPriceMultiplier).toFixed(2),
                    priceB2C: (dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.priceB2C * glassPriceMultiplier).toFixed(2),
                    priceB2B: (dictStore.findGlassPrice(structure.glassSortCode, structure.glassThicknessCode)?.priceB2B * glassPriceMultiplier).toFixed(2),
                    qty: structure.dimensions.areaFt,
                })
            );
        }
        // transom/filler - aluminum type
        if (structure.fillerTypeCode === 'aluminum') {
            calculatedItem.subitems.items.push(
                CalculateService.calculationItemFactory({
                    elementCode: 'aluminum',
                    params: [structure.fillerTypeCode, structure.fillerMaterialCode],
                    price: dictStore.findAluminumFillerPrice(structure.fillerMaterialCode)?.price,
                    priceB2C: dictStore.findAluminumFillerPrice(structure.fillerMaterialCode)?.priceB2C,
                    priceB2B: dictStore.findAluminumFillerPrice(structure.fillerMaterialCode)?.priceB2B,
                    qty: structure.dimensions.areaFt,
                })
            );
        }
        // outofsquare  qty
        calculatedItem.subitems.items.push(
            CalculateService.calculationItemFactory({
                elementCode: 'outOfSquare',
                params: [],
                price: dictStore.findOutOfSquarePrice('outsq-01')?.price,
                priceB2C: dictStore.findOutOfSquarePrice('outsq-01')?.priceB2C,
                priceB2B: dictStore.findOutOfSquarePrice('outsq-01')?.priceB2B,
                qty: structure.outOfSquareQty,
            })
        );

        // assets qty
        calculatedItem.subitems.items.push(
            CalculateService.calculationItemFactory({
                elementCode: 'assets',
                elementType: 'assets',
                params: [],
                price: dictStore.findAssetsPrice('assets-01')?.price,
                priceB2C: dictStore.findAssetsPrice('assets-01')?.priceB2C,
                priceB2B: dictStore.findAssetsPrice('assets-01')?.priceB2B,
                qty: structure.fillerAssetsQty,
            })
        );

        if (structure.structureTypeCode === 'transom') {
            // hardwares
            for (const hardwareItem of structure.hardwares) {
                calculatedItem.subitems.items.push(
                    CalculateService.calculationItemFactory({
                        elementCode: hardwareItem.code,
                        elementType: 'hardware',
                        params: [hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode],
                        price: dictStore.findTransomHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.price,
                        priceB2C: dictStore.findTransomHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.priceB2C,
                        priceB2B: dictStore.findTransomHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.priceB2B,
                        qty: hardwareItem.qty,
                    })
                );
            }
        }
        return calculatedItem;
    }
}


export class MountsCalculatorBuilder {
    public loadFromObject(): void {
    }

    /**
     * Создание объекта расчета для монтажных элементов
     * @param structure StructureItem структура
     * @param installedSystemCode string код установленной системы
     */
    static fromStructure(structure: StructureItem, installedSystemCode: string | null = null): CalculationItem {
        let glassPriceMultiplier = 1;

        const dictStore = useDictStore();
        // create top item
        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory({
            elementCode: structure.code,
            elementType: 'mounts',
            qty: structure.qty,
        });

        // if none
        if (!structure.structureTypeCode || structure.structureTypeCode === 'none') {
            return calculatedItem;
        }

        // для систем kompas double цена удваивается
        if (['kompas-sgl', 'kompas-dbl'].includes(installedSystemCode)) {
            glassPriceMultiplier = 2;
        }

        // add priced elements
        // hardwares
        for (const hardwareItem of structure.hardwares) {
            calculatedItem.subitems.items.push(
                CalculateService.calculationItemFactory({
                    elementCode: hardwareItem.code,
                    elementType: 'hardware',
                    params: [hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode],
                    price: (dictStore.findMountHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.price * glassPriceMultiplier).toFixed(2),
                    priceB2C: (dictStore.findMountHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.priceB2C * glassPriceMultiplier).toFixed(2),
                    priceB2B: (dictStore.findMountHardwarePrice(hardwareItem.hardwareTypeCode, hardwareItem.hardwareValueCode)?.priceB2B * glassPriceMultiplier).toFixed(2),
                    qty: hardwareItem.qty,
                })
            );
        }
        return calculatedItem;
    }
}


export class OverheadCalculatorBuilder {
    public loadFromObject(): void {
    }

    static fromOverheadItem(overheadItems: AdditionalCostItem[]): CalculationItem {
        if (!overheadItems) {
            return null
        }
        const dictStore = useDictStore();
        // create top item
        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory({
            elementCode: 'overheads',
            elementType: 'overheads',
            qty: 1,
        });

        // add priced elements
        // additionCost items
        if (overheadItems) {
            for (const item of overheadItems) {
                calculatedItem.subitems.items.push(
                    CalculateService.calculationItemFactory({
                        elementCode: item.code,
                        elementType: 'overhead',
                        params: [item.costTypeCode, item.costValueCode],
                        price: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.price,
                        priceB2C: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.priceB2C,
                        priceB2B: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.priceB2B,
                        qty: item.qty,
                    })
                );
            }
        }
        return calculatedItem;
    }
}

export class DeliveryCalculatorBuilder {
    public loadFromObject(): void {
    }

    static fromDeliveryInfo(deliveryInfo: DeliveryInfo): CalculationItem {
        const dictStore = useDictStore();
        // create top item
        const calculatedItem: CalculationItem = CalculateService.calculationItemFactory({
            elementCode: 'delivery',
            elementType: 'delivery',
            qty: 1,
        });
        const costs: CalculationItem = CalculateService.calculationItemFactory({
            elementCode: 'costs',
            qty: 1,
        });
        // add priced elements
        // additionCost items
        for (const item of deliveryInfo.deliveryCosts) {
            costs.subitems.items.push(
                CalculateService.calculationItemFactory({
                    elementCode: item.code,
                    elementType: 'deliverycost',
                    params: [item.costTypeCode, item.costValueCode],
                    price: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.price,
                    priceB2C: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.priceB2C,
                    priceB2B: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.priceB2B,
                    qty: item.qty,
                })
            );
        }
        // extra costs
        const extraCosts: CalculationItem = CalculateService.calculationItemFactory({
            elementCode: 'extracosts',
            qty: 1,
        });

        for (const item of deliveryInfo.extraCosts) {
            extraCosts.subitems.items.push(
                CalculateService.calculationItemFactory({
                    elementCode: item.code,
                    elementType: 'extracost',
                    params: [item.costTypeCode, item.costValueCode],
                    price: dictStore.findHardwarePrice(item.costTypeCode, item.costValueCode)?.price,
                    priceB2C: dictStore.findHardwarePrice(item.costTypeCode, item.costValueCode)?.priceB2C,
                    priceB2B: dictStore.findHardwarePrice(item.costTypeCode, item.costValueCode)?.priceB2B,
                    qty: item.qty,
                })
            );
        }
        calculatedItem.subitems.items.push(costs);
        calculatedItem.subitems.items.push(extraCosts);

        return calculatedItem;
    }
}
