<template>
    <div class="step__block">
        <div class="step__block-wrapper">
            <div class="step__block-container">
                <span class="step__block-container-left">Space description:</span>
                <SelectInput
                    v-model="activeSpace.title"
                    :select-list="spaceDescriptions"
                    :select-first="true"
                />
            </div>
            <div class="step__block-container">
                <span class="step__block-container-left">Nr. # at the plan:</span>
                <input type="text" v-model="partition.title" placeholder=""/>
            </div>
            <div class="step__block-container">
                <span class="step__block-container-left">Partition type:</span>
                <select-dict
                    v-model="partition.structureTypeCode"
                    :selectList="structureTypes"
                ></select-dict>
            </div>
        </div>
        <template v-if="isPartitionBlockVisible">
            <div class="step__block-field">
                <div class="step__block-field-block">
                    <h3>PARTITION dimensions:</h3>
                </div>
                <div v-if="errorMessages.dimensions" class="step__block-dimensions-warning" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g clip-path="url(#clip0_251_7435)">
                            <path
                                d="M7.99992 14.6654C11.6818 14.6654 14.6666 11.6806 14.6666 7.9987C14.6666 4.3168 11.6818 1.33203 7.99992 1.33203C4.31802 1.33203 1.33325 4.3168 1.33325 7.9987C1.33325 11.6806 4.31802 14.6654 7.99992 14.6654Z"
                                stroke="#FF6255"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path d="M8 5.33203V7.9987" stroke="#FF6255" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M8 10.668H8.00708" stroke="#FF6255" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_251_7435">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <span><p v-html="errorMessages.dimensions"></p></span>
                </div>
            </div>
            <div class="step__block-dimensions">
                <div class="step__block-dimensions-block">
                    <div class="step__block-dimensions-left">
                        <div></div>
                        <div class="step__block-dimensions-units">
                            <div>
                                <span>Ft</span>
                            </div>
                            <div>
                                <span>Inch</span>
                            </div>
                            <div>
                                <span>Fractions</span>
                            </div>
                        </div>
                    </div>
                    <div class="step__block-dimensions-right">
                        <div>
                            <span>Size, sq.ft:</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="step__block-dimensions-block step__block-dimensions-block--table">
                        <div class="step__block-dimensions-left">
                            <div class="step__block-dimensions-name">
                                <span>Width</span>
                            </div>
                            <div class="step__block-dimensions-container">
                                <div>
                                    <input-num v-model.number="partition.dimensions.width.ft" />
                                </div>
                                <div>
                                    <input-num v-model.number="partition.dimensions.width.inch"/>
                                </div>
                                <div>
                                    <input-num v-model.number="partition.dimensions.width.fractionA"/>
                                </div>
                                <div>
                                    <input-num v-model.number="partition.dimensions.width.fractionB" />
                                </div>
                            </div>
                        </div>
                        <div class="step__block-dimensions-right">
                            <div>
                                <span>{{ formatDimension(dimensionToFt(partition.dimensions.width)) }}</span>
                            </div>
                            <div></div>
                        </div>

                    </div>
                    <div class="step__block-dimensions-block step__block-dimensions-block--table">
                        <div class="step__block-dimensions-left">
                            <div class="step__block-dimensions-name">
                                <span>Height</span>
                            </div>
                            <div class="step__block-dimensions-container">
                                <div>
                                    <input-num  v-model.number="partition.dimensions.height.ft" />
                                </div>
                                <div>
                                    <input-num  v-model.number="partition.dimensions.height.inch" />
                                </div>
                                <div>
                                    <input-num  v-model.number="partition.dimensions.height.fractionA" />
                                </div>
                                <div>
                                    <input-num  v-model.number="partition.dimensions.height.fractionB" />
                                </div>
                            </div>
                        </div>
                        <div class="step__block-dimensions-right">
                            <div>
                                <span>{{ formatDimension(dimensionToFt(partition.dimensions.height)) }}</span>
                            </div>
                            <div>
                                <span>{{ formatDimension(partition.dimensions?.areaFt) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step__block-field">
                <div class="step__block-field-block">
                    <h3>Partition Glass:</h3>
                </div>
            </div>
            <div class="step__block-partitionglass">
                <div class="step__block-partitionglass-block">
                    <div>
                        <span>Glass sort:</span>
                    </div>
                    <div class="step__block-partitionglass-subblock">
                        <span>Glass thickness:</span>
                    </div>
                    <div class="step__block-partitionglass-subblock">
                        <span>Per sq.ft.</span>
                    </div>
                    <div class="step__block-partitionglass-subblock">
                        <span>SUM</span>
                    </div>
                </div>
                <div class="step__block-partitionglass-block step__block-partitionglass-block--selects">
                    <div>
                        <select-dict
                            v-model="partition.glassSortCode"
                            :selectList="glassSorts"
                            :select-first-item="true"
                            />
                    </div>
                    <div class="step__block-partitionglass-subblock">
                        <select-dict
                            v-model="partition.glassThicknessCode"
                            :selectList="glassThickness"
                            :select-first-item="true"
                        />
                    </div>
                    <div class="step__block-partitionglass-subblock">
                        <span>{{ formatMoney(glassPrice) }}</span>
                    </div>
                    <div class="step__block-partitionglass-subblock">
                        <span>{{ formatMoney(glassSum) }}</span>
                    </div>
                </div>
                <div class="step__block-partitionglass-total">
                    <div class="step__block-partitionglass-block">
                        <div></div>
                        <div class="step__block-partitionglass-subblock step__block-partitionglass-subblock--unit">
                            <span>Q-ty</span>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <span>{{ priceCode }}</span>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <span>SUM</span>
                        </div>
                    </div>
                    <div class="step__block-partitionglass-block">
                        <div
                            class="step__block-partitionglass-subblock step__block-partitionglass-subblock step__block-partitionglass-subblock--first"
                        >
                            <span>Out of square:</span>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <input-num v-model="partition.outOfSquareQty" />
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <span>{{ formatMoney(outOfSquarePrice) }}</span>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <span>{{ formatMoney(outOfSquareSum) }}</span>
                        </div>
                    </div>
                    <div class="step__block-partitionglass-block">
                        <div></div>
                        <div class="step__block-partitionglass-subblock step__block-partitionglass-subblock--unit">
                            <span>Q-ty</span>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <span>{{ priceCode }}</span>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <span>SUM</span>
                        </div>
                    </div>
                    <div class="step__block-partitionglass-block">
                        <div
                            class="step__block-partitionglass-subblock step__block-partitionglass-subblock step__block-partitionglass-subblock--first"
                        >
                            <span>Partition:</span>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <input v-model="partition.qty" type="number" min="0" step="1"/>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <span>{{ formatMoney(partitionPrice) }}</span>
                        </div>
                        <div class="step__block-partitionglass-subblock">
                            <span>{{ formatMoney(partitionSum) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step__block-field">
                <div class="step__block-field-block">
                    <h3>page Total:</h3>
                    <span class="step__block-field-price">{{ formatMoney(partitionSum) }}</span>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">

import { computed, ref, watch, Ref, watchEffect } from "vue";
import { cloneDeep } from 'lodash'
import SelectDict from "@/components/UI/SelectDict.vue";
import InputNum from "@/components/UI/InputNum.vue";
import { useDictStore } from "../../stores/dictStore";
import { useEstimateStore } from "../../stores/estimateStore";
import { EstimatedSpace } from "../../models/estimate";
import { Structure, StructureItem } from "../../models/structure";
import {
    formatMoney,
    formatDimension,
    formatDateTime,
    calcAreaFt,
    calcPerimerFt,
    dimensionToFt,
    addTextToText
} from "../../utils/common";
import { Dictionary } from "../../models/dictionary";
import { CalculationItem, PriceListItem } from "../../models/calculate";
import { CalculateService, PartitionCalculatorBuilder } from "../../services/calculateService";
import { useUiStore } from "../../stores/uiStore";
import SelectInput from "../UI/SelectInput.vue";
import { getEstimate } from "../../utils/api";
import { EstimateService } from "../../services/estimateService";
import { usePriceStore } from "../../stores/priceStore";

defineExpose({validatePage, savePage, reloadPage});

const uiStore = useUiStore();
const dictStore = useDictStore();
const estimateStore = useEstimateStore();
const structureTypes: Dictionary[] = dictStore.getStructureTypes('partition');
const spaceDescriptions: Dictionary = dictStore.getSpaceDescriptions();

const glassSorts: Dictionary[] = dictStore.getGlassSorts();
const glassThickness: Dictionary[] = dictStore.getGlassThickness(estimateStore.getEstimateSystemTypeCode());
const glassTypes: Dictionary[] = dictStore.getGlassTypes();

const priceStore = usePriceStore();
let sumKey = priceStore.data.currentSumKey;
let priceCode = priceStore.data.currentPriceKey;

let activeSpace: Ref<EstimatedSpace> = ref(null);
let activeStructure: Ref<Structure> = ref(null);
let partition: Ref<StructureItem> = ref(null);
let pageCalculation = ref(null);

let prevPartitionTypeCode = null;

const errorMessages = ref({
  dimensions: ''
})

reloadPage();


function reloadPage() {
    activeSpace.value = cloneDeep(estimateStore.getActiveSpace());
    activeStructure.value = cloneDeep(estimateStore.getActiveStructure());
    partition.value = activeStructure.value.partition;
    prevPartitionTypeCode = partition.value.structureTypeCode;
    uiStore.reloadSquare();
}


function validatePage() {
    errorMessages.value = {
        dimensions: '',
    }
    if (dimensionToFt(partition.value.dimensions.width) === 0 || dimensionToFt(partition.value.dimensions.height)===0 ) {
        errorMessages.value.dimensions = 'Width and height must be greater then 0';
    }
    return true;
}

function savePage() {
    estimateStore.renameSpace(activeSpace.value.title);
    estimateStore.updatePartition(partition.value);
}


const isPartitionBlockVisible = computed(() => {
    return true;
    //return ["fxp", "foldp", "slidep"].includes(partition.value.structureTypeCode);
});

function isPartitionChanged(oldType: string, newType: string): boolean {
    const partitionTypes = ["fxp", "foldp", "slidep"];
    const doorTypes = ["fxw", "dr", "div"];
    return (
        (partitionTypes.includes(oldType) && doorTypes.includes(newType)) ||
        (doorTypes.includes(oldType)) && (partitionTypes.includes(newType))
    );
}

const glassPrice = computed(() => CalculateService.getSubitemByCode(pageCalculation.value, 'glass')?.[priceCode]);
const glassSum = computed(() => CalculateService.getSubitemByCode(pageCalculation.value, 'glass')?.[sumKey]);

const outOfSquarePrice = computed(() =>
    CalculateService.getSubitemByCode(pageCalculation.value, 'outOfSquare')?.[priceCode])
const outOfSquareSum = computed(() => CalculateService.getSubitemByCode(pageCalculation.value, 'outOfSquare')?.[sumKey])

const partitionPrice = computed(() => {
    return pageCalculation.value?.[priceCode] + pageCalculation.value.subitems?.[sumKey];
    // return CalculateService.getSubitemByCode(pageCalculation.value, partition.value.code)?.price;
});
const partitionSum = computed(() => {
    return CalculateService.getSubitemByCode(pageCalculation.value, partition.value.code)?.[sumKey];
});

// watchers order is important: global ot top !!!!
watch(partition, () => {
        const pageCalculationLocal = PartitionCalculatorBuilder.fromStructure(partition.value, estimateStore.estimate.info.installedSystemCode);
        CalculateService.deepCalculateSubitems(pageCalculationLocal);
        CalculateService.deepCalculateSubItemsB2C(pageCalculationLocal);
        CalculateService.deepCalculateSubItemsB2B(pageCalculationLocal);
        pageCalculation.value = pageCalculationLocal;
        validatePage();
    }, {deep: true, immediate: true}
)

watch(() => partition.value.dimensions,
    () => {
        // partition.value.dimensions.areaFt = calcAreaFt(cloneDeep(partition.value.dimensions));
        partition.value.dimensions.areaFt = EstimateService.calcPartitionGlassArea(
            estimateStore.estimate.info.installedSystemCode,
            partition.value,
            activeStructure.value.doors,
            activeStructure.value.fillers
        );
        partition.value.dimensions.perimeterFt = calcPerimerFt(cloneDeep(partition.value.dimensions));
    }, {deep: true}
);

watch(
    () => partition.value.structureTypeCode,
    (structureTypeCode) => {
        prevPartitionTypeCode = structureTypeCode;
        activeStructure.value.structureTypeCode = structureTypeCode;
    }, {immediate: true}
);

// calcTransfer
watch(() => uiStore.transferToWidthFlag , ()=>{
    partition.value.dimensions.width = uiStore.calcWidth
}, )

watch(() => uiStore.transferToHeightFlag , ()=>{
    partition.value.dimensions.height = uiStore.calcHeight
}, )
watch(() => glassPrice , ()=>{
  console.log('chGlPrice', glassPrice)
}, )

</script>

<style scoped>
.step__block--data,
.step__block-wrapper {
    padding-right: 80px;
    display: grid;
    row-gap: 10px;
}

.step__block--data .step__block-container input,
.step__block-wrapper .step__block-container input {
    padding: 9px 20px;
    background-color: #fff;
    border-radius: 25px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-wrapper {
    margin-bottom: 15px;
}

.step__block-container {
    display: grid;
    grid-template-columns: 40% 60%;
    column-gap: 10px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-container-left {
    align-self: center;
}

.step__block-field {
    padding: 15px 0;
    border-top: 1px solid #d2d8e3;
    border-bottom: 1px solid #d2d8e3;
    display: flex;
}

.step__block-field-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.step__block-field-price {
    color: #404d63;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field h3 {
    color: #404d63;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-dimensions {
    padding: 15px 0;
}

.step__block-dimensions-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.step__block-dimensions-right {
    display: flex;
    column-gap: 92px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-dimensions-right div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
    width: 65px;
}

.step__block-dimensions {
    padding: 15px 0;
}

.step__block-dimensions-left {
    display: grid;
    grid-template-columns: minmax(0, 46px) repeat(2, 1fr);
    column-gap: 10px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-dimensions-units {
    display: grid;
    grid-template-columns: repeat(3, 60px);
    padding-bottom: 11px;
}

.step__block-dimensions-units div {
    padding-left: 11px;
}

.step__block-dimensions-container {
    display: grid;
    grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
    background-color: #fff;
    height: 35px;
}

.step__block-dimensions-container div input {
    width: 100%;
    height: 100%;
    padding-left: 15px;
    padding-right: 7px;
}

.step__block-dimensions-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step__block-dimensions-block--table:first-child .step__block-dimensions-container div {
    border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
    border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div:last-child {
    border-right: none;
}

.step__block-partitionglass {
    padding: 15px 0;
}

.step__block-partitionglass-block {
    display: grid;
    grid-template-columns: 40% 22% repeat(2, 1fr);
    column-gap: 15px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    column-gap: 15px;
}

.step__block-partitionglass-block--selects {
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d2d8e3;
}

.step__block-partitionglass-subblock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
    align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
    padding-right: 10px;
}

.step__block-partitionglass-subblock input {
    width: 70px;
    height: 35px;
    border-radius: 20px;
    background: #fff;
    padding: 0 10px;
    text-align: center;
}

.step__block-partitionglass-total {
    display: grid;
    row-gap: 15px;
}

.step__block-dimensions-warning {
    align-self: flex-start;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #ff6255;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 25px;
}
</style>
