<template>
    <div
        :class="[
            'select',
            { 'select--extra': isExtra },
            { 'select--finish': isFinishSelect },
        ]"
    >
        <div @click="isVisibleSelect = !isVisibleSelect" class="select__head">
            <span :style="[value == 'select' ? 'color: #e0e0e0' : '']">{{ value }}</span>
            <svg
                v-if="isFinishSelect"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
            >
                <mask id="path-1-inside-1_327_29935" fill="white">
                    <path d="M12 0L6 6L0 -1.04907e-06" />
                </mask>
                <path
                    d="M11.0808 -0.919239L12 -1.83848L13.8385 1.60725e-07L12.9192 0.919239L11.0808 -0.919239ZM6 6L6.91924 6.91924C6.41156 7.42692 5.58844 7.42692 5.08076 6.91924L6 6ZM-0.919239 0.919238L-1.83848 -1.2098e-06L1.60725e-07 -1.83848L0.919239 -0.91924L-0.919239 0.919238ZM12.9192 0.919239L6.91924 6.91924L5.08076 5.08076L11.0808 -0.919239L12.9192 0.919239ZM5.08076 6.91924L-0.919239 0.919238L0.919239 -0.91924L6.91924 5.08076L5.08076 6.91924Z"
                    fill="white"
                    mask="url(#path-1-inside-1_327_29935)"
                />
            </svg>
            <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
            >
                <path
                    d="M1 1L7 7L13 1"
                    stroke="#404D63"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
        <ul
            :class="[
                'select__list',
                { 'select__list--active': isVisibleSelect },
            ]"
        >
            <li
                @click="changeValueSelect"
                class="select__item"
                v-for="item in selectList"
                :key="item.code"
                :data-code="item.code"
            >
                {{ item.title }}
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch } from "vue";
import { Dictionary } from "@/models/dict/dictionary";

const emit = defineEmits<{
    "update:modelValue": [value: string];
    selectChanged: [key: string, value: string];
}>();

const props = defineProps({
    isFinishSelect: {
        type: Boolean,
        default: false,
        required: false,
    },
    isExtra: {
        type: Boolean,
        default: false,
        required: false,
    },
    modelValue: null,
    emptyText: null,
    selectFirstItem: {
        type: Boolean,
        required: false,
        default: false,
    },
    selectList: Array as PropType<Dictionary[]>,
});

let isVisibleSelect = ref(false);

let key: ref<string> = ref(
    props.modelValue
        ? props.modelValue
        : props.selectFirstItem
        ? props.selectList[0]?.code
        : null,
);
let value: ref<string> = ref(
    key.value
        ? props.selectList.filter((item) => item.code == key.value)[0]?.title
        : props.emptyText
        ? props.emptyText
        : 'select',
);

if (key.value && key.value != props.modelValue) {
    emit("selectChanged", key.value, value.value);
    emit("update:modelValue", key.value);
}

function changeValueSelect(e) {
    key.value = e.target.dataset["code"];
    value.value = e.target.textContent;
    emit("selectChanged", key.value, value.value);
    emit("update:modelValue", key.value);
    isVisibleSelect.value = false;
}
watch(() => props.modelValue, () => {
    console.debug('model updated', props.modelValue);
    key.value = props.modelValue;
    value.value = props.selectList.filter((item) => item.code == key.value)[0]?.title;
});

</script>

<style scoped>
.select__item {
  text-align: left;
}
.select {
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    background: #fff;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 35px;
}

.select.select--extra {
    background: #fbfbfb;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
}

.select.select--finish {
    background: #2491eb;
    color: #fff;
    min-height: 49px;
}

.select__list {
    position: absolute;
    top: 80%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease;
    box-shadow: 0 0 24px 0 rgba(32, 92, 148, 0.15);
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
}

.select.select--finish .select__list {
    top: unset;
    bottom: 80%;
    max-height: unset;
}

.select.select--finish .select__list {
    color: #404d63;
}

.select__list--active {
    top: 100%;
    opacity: 1;
    pointer-events: auto;
    transition: 0.5s ease;
}

.select.select--finish .select__list--active {
    top: unset;
    bottom: 100%;
}

.select__list li {
    padding: 10px;
    background-color: #fff;
    cursor: pointer;
    transition: 0.5s ease;
}

.select.select--finish .select__list li:hover {
    background-color: #fff;
    color: #2491eb;
}

.select__list li:hover {
    background-color: #2491eb;
    transition: 0.5s ease;
    color: #fff;
}

.select__head {
    width: 100%;
    min-height: 35px;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.select.select--finish .select__head {
    min-height: 49px;
}

.select__head span {
    max-width: calc(100% - 70px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
