<template>
    <section class="entry-section">
        <div class="entry-section__overlay block-overlay"></div>
        <div :class="['entry-modal__notification', { 'entry-modal__notification--visible': isVisibleNotification }]">
            <div class="entry-modal__notification-wrapper">
                <div class="entry-modal__notification-block">
                    <h3 class="entry-modal__notification-title">Oops, something went wrong...</h3>
                    <button @click="isVisibleNotification = false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15 5L5 15" stroke="#404D63" stroke-width="2" stroke-linecap="square"
                                  stroke-linejoin="round"/>
                            <path d="M5 5L15 15" stroke="#404D63" stroke-width="2" stroke-linecap="square"
                                  stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <p class="entry-modal__notification-text">{{ textNotification }}</p>
            </div>
        </div>
        <div class="entry-section__modal">
            <form class="entry-section__form" action="">
                <h3 class="entry-section__title">Calculator</h3>
                <div class="entry-section__block">
                    <div class="entry-section__input">
                        <svg
                            class="entry-section__input-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                                stroke="white"
                                stroke-width="1.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M22 6L12 13L2 6"
                                stroke="white"
                                stroke-width="1.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <input type="email" v-model="emailValue" @input="handlerInputEmail" placeholder="Email"/>
                        <svg
                            v-if="isErrorValidateEmail"
                            class="entry-section__input-alert"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M10.2898 3.85996L1.81978 18C1.64514 18.3024 1.55274 18.6453 1.55177 18.9945C1.55079 19.3437 1.64127 19.6871 1.8142 19.9905C1.98714 20.2939 2.2365 20.5467 2.53748 20.7238C2.83847 20.9009 3.18058 20.9961 3.52978 21H20.4698C20.819 20.9961 21.1611 20.9009 21.4621 20.7238C21.7631 20.5467 22.0124 20.2939 22.1854 19.9905C22.3583 19.6871 22.4488 19.3437 22.4478 18.9945C22.4468 18.6453 22.3544 18.3024 22.1798 18L13.7098 3.85996C13.5315 3.56607 13.2805 3.32308 12.981 3.15444C12.6814 2.98581 12.3435 2.89722 11.9998 2.89722C11.656 2.89722 11.3181 2.98581 11.0186 3.15444C10.7191 3.32308 10.468 3.56607 10.2898 3.85996Z"
                                stroke="#FFE600"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path d="M12 9V13" stroke="#FFE600" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                                d="M12 17H12.01"
                                stroke="#FFE600"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <svg
                            v-if="isSuccessValidateEmail"
                            class="entry-section__input-alert"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                stroke="#2491EB"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M22 4L12 14.01L9 11.01"
                                stroke="#2491EB"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div class="entry-section__input">
                        <svg
                            class="entry-section__input-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M11.8889 0C8.47111 0 5.66667 2.80444 5.66667 6.22222V8.77778C4.82283 9.60268 4.15227 10.5878 3.69434 11.6754C3.23642 12.763 3.00035 13.9311 3 15.1111C3 20.0089 6.99111 24 11.8889 24C16.7867 24 20.7778 20.0089 20.7778 15.1111C20.7774 13.9311 20.5414 12.763 20.0834 11.6754C19.6255 10.5878 18.9549 9.60268 18.1111 8.77778V6.22222C18.1111 2.80444 15.3067 0 11.8889 0ZM11.8889 1.77778C14.3378 1.77778 16.3333 3.77333 16.3333 6.22222V7.44444C15.0222 6.68 13.512 6.22222 11.8889 6.22222C10.2658 6.22222 8.75556 6.68089 7.44444 7.44444V6.22222C7.44444 3.77333 9.44 1.77778 11.8889 1.77778ZM11.8889 8C15.8267 8 19 11.1733 19 15.1111C19 19.0489 15.8267 22.2222 11.8889 22.2222C7.95111 22.2222 4.77778 19.0489 4.77778 15.1111C4.77778 11.1733 7.95111 8 11.8889 8ZM11.8889 13.3333C11.4174 13.3333 10.9652 13.5206 10.6318 13.854C10.2984 14.1874 10.1111 14.6396 10.1111 15.1111C10.1111 15.7689 10.4693 16.3316 11 16.64V19.5556H12.7778V16.64C13.3084 16.3316 13.6667 15.7689 13.6667 15.1111C13.6667 14.6396 13.4794 14.1874 13.146 13.854C12.8126 13.5206 12.3604 13.3333 11.8889 13.3333Z"
                                fill="white"
                            />
                        </svg>
                        <input
                            :type="!isVisibleButtonPassword ? 'password' : 'text'"
                            @input="handlerInputPassword"
                            v-model="passwordValue"
                            ref="inputPassword"
                            placeholder="Password"
                        />
                        <button
                            @click="isVisibleButtonPassword = !isVisibleButtonPassword"
                            v-if="passwordValue.length"
                            type="button"
                            class="entry-section__input-button"
                        >
                            <svg
                                v-if="!isVisibleButtonPassword"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M1 12C1 12 5 6 12 6C19 6 23 12 23 12C23 12 19 18 12 18C5 18 1 12 1 12Z"
                                    stroke="#8093A7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                    stroke="#8093A7"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <g clip-path="url(#clip0_28_373)">
                                    <path
                                        d="M17.6509 15.8919C15.9415 16.8691 13.86 17.4105 11.7109 17.4369C4.71094 17.4369 0.710938 11.4369 0.710938 11.4369C1.95483 9.69832 3.68007 8.17936 5.77094 6.98191M9.61094 5.61691C10.2993 5.49607 11.004 5.43566 11.7109 5.43691C18.7109 5.43691 22.7109 11.4369 22.7109 11.4369C22.1039 12.2886 21.38 13.0904 20.5509 13.8294M13.8309 13.0269C13.5563 13.248 13.2251 13.4253 12.8571 13.5482C12.4891 13.6712 12.0918 13.7373 11.689 13.7427C11.2862 13.748 10.8861 13.6924 10.5125 13.5793C10.139 13.4661 9.79966 13.2977 9.51478 13.084C9.22991 12.8704 9.00533 12.6159 8.85445 12.3357C8.70356 12.0555 8.62946 11.7554 8.63657 11.4533C8.64368 11.1512 8.73185 10.8533 8.89581 10.5773C9.05978 10.3013 9.29619 10.0529 9.59094 9.8469"
                                        stroke="#8093A7"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M1 1L23 23"
                                        stroke="#8093A7"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_28_373">
                                        <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>
                <button @click.prevent="handlerSubmit" class="entry-section__button" type="submit">sign in</button>
            </form>
        </div>
    </section>
</template>

<script setup>
import { ref, computed } from "vue";
import router from "@/router";
import { useUserStore } from "@/stores/userStore";
import { useDictStore } from "@/stores/dictStore";
import { useUiStore } from "@/stores/uiStore";

const userStore = useUserStore();
const dictStore = useDictStore();
const uiStore = useUiStore();
const isVisibleButtonPassword = ref(false);
const isSuccessValidateEmail = ref(false);
const isErrorValidateEmail = ref(false);
const isVisibleNotification = ref(false);
const emailValue = ref("");
const passwordValue = ref("");
const textNotification = ref("Login to your personal account was not completed. Please, try again");

uiStore.showPreloader = false;

function handlerInputEmail() {
    if (!validateEmail.value) {
        isErrorValidateEmail.value = true;
        isSuccessValidateEmail.value = false;
    } else {
        isSuccessValidateEmail.value = true;
        isErrorValidateEmail.value = false;
    }
}

async function handlerSubmit() {
    try {
        await userStore.login(emailValue.value, passwordValue.value);
        uiStore.showPreloader = true;
        await router.push("/calculation");
    } catch (error) {
        isVisibleNotification.value = true;
        setTimeout(() => {
            isVisibleNotification.value = false;
        }, 3000);
    }
}

const validatePassword = computed(() => passwordValue.value.length > 7);
const validateEmail = computed(() => {
    const EMAIL_REGEXP =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    return EMAIL_REGEXP.test(emailValue);
})
</script>

<style scoped>
.entry-section__input-alert,
.entry-section__input-button {
    position: absolute;
    right: 0;
    top: 30%;
    transform: translateY(-50%);
}

.entry-modal__notification {
    position: fixed;
    z-index: 99;
    top: 20px;
    right: 42px;
    min-width: 355px;
    min-height: 111px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(32, 92, 148, 0.15);
    padding: 20px 14px 20px 14px;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease;
}

.entry-modal__notification--visible {
    opacity: 1;
    pointer-events: auto;
    transition: 0.5s ease;
}

.entry-modal__notification-wrapper {
    border-left: 2px solid #ff402f;
    padding-left: 15px;
}

.entry-modal__notification-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
}

.entry-modal__notification-title {
    color: #404d63;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.entry-modal__notification-text {
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    max-width: 257px;
}

.entry-section__title {
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 44px;
}

.entry-section {
    width: 100vw;
    height: 100vh;
    background-image: url("@/assets/images/entry-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
}

.entry-section__overlay {
    background: linear-gradient(180deg, rgba(5, 32, 64, 0.9) 0%, rgba(27, 98, 164, 0.9) 100%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
}

.entry-section__modal {
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 510px;
    min-height: 390px;
    border-radius: 20px;
    background: rgba(247, 247, 250, 0.2);
    padding-top: 40px;
}

.entry-section__form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.entry-section__block {
    display: grid;
    row-gap: 18px;
    margin-bottom: 40px;
}

.entry-section__input {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 16px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.entry-section__input-svg {
    position: absolute;
    left: 0;
    top: 30%;
    transform: translateY(-50%);
}

.entry-section__input input {
    color: #fff;
    padding-left: 40px;
    width: 257px;
    padding-right: 40px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transition: 0.5s ease;
}

.entry-section__input input::-ms-input-placeholder {
    color: #fff;
}

.entry-section__input input::placeholder {
    color: #fff;
}

.entry-section__input input:focus {
    border-bottom: 1px solid #2491eb;
    transition: 0.5s ease;
}

.entry-section__button {
    width: 130px;
    height: 50px;
    border-radius: 30px;
    background: #2491eb;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.63px;
    text-transform: uppercase;
}
</style>
