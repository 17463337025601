<template>
    <div class="step__block-doortype">
        <div>
            <span>Transom or filler:</span>
        </div>
        <div>
            <select-dict
                v-model="filler.structureTypeCode"
                :selectList="structureTypes"
                :select-first-item="true"
            ></select-dict>
        </div>
    </div>
    <div v-if="!isNoneTypeSelected" class="step__block-field">
        <div class="step__block-field-block">
            <h3>{{ filler.structureTypeCode }} Dimensions:</h3>
        </div>
        <!-- error message -->
        <div v-if="errorMessages.dimensions" class="step__block-dimensions-warning">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_251_7435)">
                    <path
                        d="M7.99992 14.6654C11.6818 14.6654 14.6666 11.6806 14.6666 7.9987C14.6666 4.3168 11.6818 1.33203 7.99992 1.33203C4.31802 1.33203 1.33325 4.3168 1.33325 7.9987C1.33325 11.6806 4.31802 14.6654 7.99992 14.6654Z"
                        stroke="#FF6255"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path d="M8 5.33203V7.9987" stroke="#FF6255" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M8 10.668H8.00708" stroke="#FF6255" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_251_7435">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            <span><p v-html="errorMessages.dimensions"></p></span>
        </div>
    </div>
    <div v-if="!isNoneTypeSelected" class="step__block-dimensions">
        <div class="step__block-dimensions-block">
            <div class="step__block-dimensions-left">
                <div></div>
                <div class="step__block-dimensions-units">
                    <div>
                        <span>Ft</span>
                    </div>
                    <div>
                        <span>Inch</span>
                    </div>
                    <div>
                        <span>Fractions</span>
                    </div>
                </div>
            </div>
            <div class="step__block-dimensions-right">
                <div>
                    <span>Size, sq.ft:</span>
                </div>
            </div>
        </div>
        <div>
            <div class="step__block-dimensions-block step__block-dimensions-block--table">
                <div class="step__block-dimensions-left">
                    <div class="step__block-dimensions-name">
                        <span>Width</span>
                    </div>
                    <div class="step__block-dimensions-container">
                        <div>
                            <input-num  v-model.number="filler.dimensions.width.ft" min=0 max=999 step=1 />
                        </div>
                        <div>
                            <input-num  v-model.number="filler.dimensions.width.inch" min=0 max=999 step=1 />
                        </div>
                        <div>
                            <input-num  v-model.number="filler.dimensions.width.fractionA" min=0 max=999 step=1 />
                        </div>
                        <div>
                            <input-num  v-model.number="filler.dimensions.width.fractionB" min=0 max=999 step=1 />
                        </div>
                    </div>
                </div>
                <div class="step__block-dimensions-right">
                    <div>{{ formatDimension(dimensionToFt(filler.dimensions.width)) }}</div>
                    <div></div>
                </div>
            </div>
            <div class="step__block-dimensions-block step__block-dimensions-block--table">
                <div class="step__block-dimensions-left">
                    <div class="step__block-dimensions-name">
                        <span>Height</span>
                    </div>
                    <div class="step__block-dimensions-container">
                        <div>
                            <input-num v-model.number="filler.dimensions.height.ft" min="0" max="999" step="1"/>
                        </div>
                        <div>
                            <input-num v-model.number="filler.dimensions.height.inch" min="0" max="999" step="1"/>
                        </div>
                        <div>
                            <input-num v-model.number="filler.dimensions.height.fractionA" min="0" max="999" step="1"/>
                        </div>
                        <div>
                            <input-num v-model.number="filler.dimensions.height.fractionB" min="0" max="999" step="1"/>
                        </div>
                    </div>
                </div>
                <div class="step__block-dimensions-right">
                    <div>{{ formatDimension(dimensionToFt(filler.dimensions.height)) }}</div>
                    <div>
                        <span>{{ formatDimension(filler.dimensions.areaFt) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!isNoneTypeSelected" class="step__block-field">
        <div class="step__block-field-block">
            <h3>{{ filler.structureType }} type:</h3>
        </div>
    </div>
    <div class="step__block-fillertype" v-if="isFillerTypeSelected">
        <div class="step__block-fillertype-container">
            <div class="step__block-fillertype-wrap">
                <div></div>
                <div></div>
                <div>
                    <span>{{ priceCode }}</span>
                </div>
                <div>
                    <span>SUM</span>
                </div>
            </div>
            <div class="step__block-fillertype-wrap">
                <div>
                    <span>Filler type:</span>
                </div>
                <div>
                    <select-dict
                        v-model="filler.fillerTypeCode"
                        :selectList="fillerTypes"
                        :select-first-item="true"
                    />
                </div>
                <div>
                    <span></span>
                </div>
                <div>
                    <span></span>
                </div>
            </div>
            <!-- Temporary disabled -->
            <div v-if="filler.fillerTypeCode === 'aluminum' && false" class="step__block-fillertype-wrap">
                <div>
                    <span>Filler:</span>
                </div>
                <div>
                    <select-dict
                        v-model="filler.fillerMaterialCode"
                        :selectList="fillerMaterials"
                        :select-first-item="true"
                    />
                </div>
                <div >
                    <span> {{ formatMoney(fillerMaterialPrice) }}</span>
                </div>
                <div >
                    <span>{{ formatMoney(fillerMaterialSum) }}</span>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isTransomTypeSelected " class="step__block-doortype step__block-doortype--transform" >
        <div>
            <span>Transom type:</span>
        </div>
        <div>
            <select-dict
                v-model="filler.fillerTypeCode"
                :select-list="fillerTypes"
                :select-first-item="true"
            />
        </div>
    </div>
    <div v-if="isTransomTypeSelected || (isFillerTypeSelected && filler.fillerTypeCode === 'glass')" class="step__block-field">
        <div class="step__block-field-block">
            <h3 v-if="filler.structureTypeCode === 'transom' && filler.fillerTypeCode === 'glass'">TRANSOM glass:</h3>
            <h3 v-else-if="filler.fillerTypeCode === 'glass'" >Filler glass</h3>
            <h3 v-else-if="filler.structureTypeCode === 'transom' && filler.fillerTypeCode === 'aluminum'" >TRANSOM aluminum:</h3>
        </div>
    </div>
    <div v-if="!isNoneTypeSelected && filler.fillerTypeCode === 'glass'" class="step__block-partitionglass">
        <div class="step__block-partitionglass-block">
            <div>
                <span>Glass sort:</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>Glass thickness:</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>Per sq.ft.</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>SUM</span>
            </div>
        </div>
        <div class="step__block-partitionglass-block step__block-partitionglass-block--selects">
            <div>
                <select-dict
                    v-model="filler.glassSortCode"
                    :selectList="glassSorts"
                    :select-first-item="true"
                />
            </div>
            <div class="step__block-partitionglass-subblock">
                <select-dict
                    v-model="filler.glassThicknessCode"
                    :selectList="glassThickness"
                    :select-first-item="true"
                />
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>{{ formatMoney(glassPrice) }}</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>{{ formatMoney(glassSum) }}</span>
            </div>
        </div>
    </div>
    <div v-if="!isNoneTypeSelected && filler.fillerTypeCode === 'aluminum'" class="step__block-partitionglass">
      <div class="step__block-partitionglass-block">
        <div>
          <span>Aluminum sort:</span>
        </div>
        <div class="step__block-partitionglass-subblock">
          <span>Per sq.ft.</span>
        </div>
        <div class="step__block-partitionglass-subblock">
          <span>SUM</span>
        </div>
      </div>
      <div class="step__block-partitionglass-block step__block-partitionglass-block--selects">
        <div class="step__block-partitionglass-subblock">
          <select-dict
            v-model="filler.fillerMaterialCode"
            :selectList="fillerMaterials"
            :select-first-item="true"
          />
        </div>
        <div class="step__block-partitionglass-subblock">
          <span> {{ formatMoney(fillerMaterialPrice) }}</span>
        </div>
        <div class="step__block-partitionglass-subblock">
          <span>{{ formatMoney(fillerMaterialSum) }}</span>
        </div>
      </div>
    </div>

    <div v-if="isTransomTypeSelected && false" class="step__block-field step__block-field--hardware">
        <div class="step__block-field-block">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5 12H19" stroke="#404D63" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <h3>Transom HARDWARE SET:</h3>
        </div>
    </div>
    <ul v-if="isTransomTypeSelected && filler?.hardwares.length && false" class="step__block-hardwareset">
        <template v-for="hardware of filler.hardwares" v-bind:key="hardware.hardwareTypeCode">
            <li class="step__block-hardwareset-item">
                <div>
                    <span>{{ getHardwareItemTitle(hardware.hardwareTypeCode) }}:</span>
                </div>
                <div>
                    <select-dict
                        v-model="hardware.hardwareValueCode"
                        :selectList="getHardwareItemDict(hardware.hardwareTypeCode)"
                    />
                </div>
            </li>
        </template>
    </ul>
    <div v-if="isTransomTypeSelected" class="step__block-field">
        <div class="step__block-field-block">
            <h3>Total:</h3>
            <span class="step__block-field-price"> {{ formatMoney(pagePart1Sum) }}</span>
        </div>
    </div>
    <div class="step__block-partitionglass-total">
        <div v-if="!isTransomTypeSelected" class="step__block-partitionglass-line"></div>
        <div v-if="isAssetsBlockVisible" class="step__block-partitionglass-block">
            <div></div>
            <div class="step__block-partitionglass-subblock step__block-partitionglass-subblock--unit">
                <span>Q-ty</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>{{ priceCode }}</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>SUM</span>
            </div>
        </div>
        <div v-if="isAssetsBlockVisible" class="step__block-partitionglass-block">
            <div
                class="step__block-partitionglass-subblock step__block-partitionglass-subblock step__block-partitionglass-subblock--first">
                <span>Out of square:</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <input type="number" v-model.number="filler.outOfSquareQty" min="0" max="99" step="1" />
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>{{ formatMoney(outOfSquarePrice) }}</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <span>{{ formatMoney(outOfSquareSum) }}</span>
            </div>
        </div>
        <div v-if="isAssetsBlockVisible" class="step__block-partitionglass-block" >
            <div
                class="step__block-partitionglass-subblock step__block-partitionglass-subblock step__block-partitionglass-subblock--first"
            >
                <span>ASSETS (parts):</span>
            </div>
            <div class="step__block-partitionglass-subblock">
                <input type="number" v-model.number="filler.fillerAssetsQty" min="0" max="99" step="1" />
            </div>
            <div class="step__block-partitionglass-subblock">{{ formatMoney(assetsPrice) }}</div>
            <div class="step__block-partitionglass-subblock">
                <span>{{ formatMoney(assetsSum) }}</span>
            </div>
        </div>
    </div>
    <div class="step__block-field">
        <div class="step__block-field-block">
            <h3>page Total:</h3>
            <span class="step__block-field-price">{{ formatMoney(pageSum) }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import SelectDict from "../UI/SelectDict.vue";
import InputNum from "@/components/UI/InputNum.vue";
import { cloneDeep } from "loadsh";
import { computed, Ref, ref, watch } from "vue";
import {
    calcAreaFt,
    calcPerimerFt,
    formatDimension,
    formatMoney,
    dimensionToFt,
    inchToDimension,
    dimensionSubtract,
    dimensionCompare, addTextToText, dimensionAdd
} from "../../utils/common";
import {OneDimension, Structure, StructureItem} from "../../models/structure";
import { Dictionary } from "../../models/dictionary";
import { CalculateService, FillerCalculatorBuilder } from "../../services/calculateService";
import { CalculationItem } from "../../models/calculate";
import { EstimateService, isCgfSystem, isCgsSystem,  } from "../../services/estimateService";
import { useUiStore } from "../../stores/uiStore";
import { useEstimateStore } from "../../stores/estimateStore";
import { useCalculateStore } from "../../stores/calculate";
import { getFromDictByCode, useDictStore } from "../../stores/dictStore";
import {usePriceStore} from "../../stores/priceStore";

let sumKey = usePriceStore().data.currentSumKey;
let priceCode = usePriceStore().data.currentPriceKey;

defineExpose({validatePage, savePage, reloadPage});

const uiStore = useUiStore();

const estimateStore = useEstimateStore();

let activeStructure: Ref<Structure> = ref(cloneDeep(estimateStore.getActiveStructure()));
// for validation and calculations
const activeDoor = estimateStore.getActiveDoor();
const activePartition = estimateStore.getActiveStructure().partition;

let filler: Ref<StructureItem> = ref(cloneDeep(estimateStore.getActiveFiller()));

const pageCalculation: Ref<CalculationItem> = ref(null);

//dicts
const dictStore = useDictStore();
const structureTypes: Dictionary[] = dictStore.getStructureTypes(['filler', activeDoor.structureTypeCode]);
const glassSorts: Dictionary[] = dictStore.getGlassSorts();

const fillerTypes: Dictionary[] = dictStore.getFillerTypes([estimateStore.estimate.info.installedSystemCode]);
const fillerMaterials: Dictionary[] = dictStore.getAluminumMaterialTypes();

const glassThickness = computed(() => dictStore.getGlassThickness(estimateStore.estimate.info.installedSystemCode));
const isNoneTypeSelected = computed(() => filler.value.structureTypeCode === 'none');
const isTransomTypeSelected = computed(() => filler.value.structureTypeCode === 'transom');
const isFillerTypeSelected = computed(() => filler.value.structureTypeCode === 'filler');

const isAssetsBlockVisible = computed(() =>
    filler.value.structureTypeCode !== 'none'
    && (isCgfSystem(estimateStore.estimate.info.installedSystemCode)
        || isCgsSystem(estimateStore.estimate.info.installedSystemCode))
);

let fillerUpdatingFlag: boolean = false;

const errorMessages = ref({
    dimensions: ''
})

const calcArea = computed(() => filler.value.dimensions ? calcAreaFt(filler.value.dimensions) : 0);

function reloadPage() {
    filler.value = cloneDeep(estimateStore.getActiveFiller());
}

function recalcDimensions() {

    if (filler.value.structureTypeCode == 'none') {
        filler.value.dimensions.width = inchToDimension(0);
        filler.value.dimensions.height = inchToDimension(0);
        return;
    }

    if (filler.value.structureTypeCode == 'transom') {
      if (activeDoor.structureTypeCode === 'dr-none') {
        filler.value.dimensions.width = activePartition.dimensions.width;
        filler.value.dimensions.height = activePartition.dimensions.height;
        filler.value.dimensions.width.ft = 0;
        filler.value.dimensions.height.ft = 0;
      } else {
        filler.value.dimensions.width = activePartition.dimensions.width;

        const partitionHeight = activePartition.dimensions.height.ft;
        const activeDoorHeight = activeDoor.dimensions.height.ft;
        const height = cloneDeep(activePartition.dimensions.height);
        height.ft = partitionHeight - activeDoorHeight;
        filler.value.dimensions.height = height
      }
      /*filler.value.dimensions.width = activeDoor.structureTypeCode !== 'dr-none' ?
        activePartition.dimensions.width :
        activeDoor.dimensions.width;
      filler.value.dimensions.height = activeDoor.structureTypeCode !== 'dr-none' ?
        activePartition.dimensions.height.ft - activeDoor.dimensions.height.ft :
        activeDoor.dimensions.height;*/
    }
  /*if (filler.value.structureTypeCode == 'transom') {
    filler.value.dimensions.width = {...activeDoor.dimensions.width};
    filler.value.dimensions.height = {...activeDoor.dimensions.height};//dimensionSubtract(activePartition.dimensions.height, activeDoor.dimensions.height);
  }*/

  // filler width and height set 0
    if (filler.value.structureTypeCode == 'filler') {
      filler.value.dimensions.height = inchToDimension(0);
      filler.value.dimensions.width = inchToDimension(0);
    }

}

function savePage() {
    estimateStore.updateFiller(filler.value);
}

function validatePage() {
    errorMessages.value = {dimensions: ''}
    console.log('Run validate!!')
    // transom ang filler
    if (filler.value.structureTypeCode !== 'none'
        && ((dimensionToFt(filler.value.dimensions.width) === 0 || dimensionToFt(filler.value.dimensions.height) === 0))
    ) {
        errorMessages.value.dimensions = addTextToText(
            errorMessages.value.dimensions,
            'width and height must be greater then zero!!!', '<br>'
        );
    }

    // transom width == door width
    if (filler.value.structureTypeCode == 'transom') {
        if (dimensionCompare(filler.value.dimensions.width, activeDoor.dimensions.width) > 0) {
            if (filler.value.fillerTypeCode !== 'none'
                && ((dimensionToFt(filler.value.dimensions.width) === 0 || dimensionToFt(filler.value.dimensions.height) === 0))
            ) {
                errorMessages.value.dimensions = addTextToText(
                    errorMessages.value.dimensions,
                    `transom width bigger then active door (${formatDimension(dimensionToFt(activeDoor.dimensions.width))} ft)`, '<br>'
                )
            }
        }
        if (dimensionToFt(filler.value.dimensions.height) > 6) {
            errorMessages.value.dimensions = addTextToText(
                errorMessages.value.dimensions,
                `transom height can\`t  be greater than 6 ft`, '<br>'
            )
        }


    }
    //  filler width == partition width
    if (filler.value.structureTypeCode == 'filler') {
        if (dimensionCompare(filler.value.dimensions.width, activePartition.dimensions.width) > 0) {
            if (filler.value.fillerTypeCode !== 'none'
                && ((dimensionToFt(filler.value.dimensions.width) === 0 || dimensionToFt(filler.value.dimensions.height) === 0))
            ) {
                errorMessages.value.dimensions = addTextToText(
                    errorMessages.value.dimensions,
                    `filler width bigger then partition (${formatDimension(dimensionToFt(activePartition.dimensions.width))} ft)`,
                    '<br>'
                )
            }
        }
    }

    // transom height = partition height - door height
    if (filler.value.structureTypeCode == 'transom') {
        if (dimensionCompare(
            dimensionAdd(filler.value.dimensions.height, activeDoor.dimensions.height),
            activePartition.dimensions.height
        ) > 0) {
            errorMessages.value.dimensions = addTextToText(
                errorMessages.value.dimensions,
                `active door with transom bigger then partition (${formatDimension(dimensionToFt(activePartition.dimensions.height))} ft)`,
                '<br>'
            )
        }
    }

  // filler height = partition height
  if (filler.value.structureTypeCode == 'filler') {
    if (dimensionCompare(filler.value.dimensions.height, activePartition.dimensions.height) > 0) {
      errorMessages.value.dimensions = addTextToText(
        errorMessages.value.dimensions,
        `filler height bigger then partition (${formatDimension(dimensionToFt(activePartition.dimensions.height))} ft)`,
        '<br>'
      )
    }
  }
    return true;
}

const glassPrice = computed(
    () => CalculateService.getSubitemByCode(pageCalculation.value, 'glass')?.[priceCode]
);
const glassSum = computed(
    () => CalculateService.getSubitemByCode(pageCalculation.value, 'glass')?.[sumKey]
);

const fillerMaterialPrice = computed(
    () => CalculateService.getSubitemByCode(pageCalculation.value, 'aluminum')?.[priceCode]
);
const fillerMaterialSum = computed(
    () => CalculateService.getSubitemByCode(pageCalculation.value, 'aluminum')?.[sumKey]
);


const outOfSquarePrice = computed(() =>
    CalculateService.getSubitemByCode(pageCalculation.value, 'outOfSquare')?.[priceCode]
);

const outOfSquareSum = computed(
    () => CalculateService.getSubitemByCode(pageCalculation.value, 'outOfSquare')?.[sumKey]
);

const assetsPrice = computed(() =>
    CalculateService.getSubitemByCode(pageCalculation.value, 'assets')?.[priceCode]
);

const assetsSum = computed(
    () => CalculateService.getSubitemByCode(pageCalculation.value, 'assets')?.[sumKey]
);

const pagePrice = computed(() => pageCalculation.value?.[priceCode] + pageCalculation.value.subitems?.[sumKey])
const pageSum = computed(() => (pageCalculation.value?.[priceCode] + pageCalculation.value.subitems?.[sumKey]) * pageCalculation.value.qty)
const pagePart1Calculation = computed(() => {
    let result = cloneDeep(pageCalculation.value);
    result.subitems.items = result?.subitems?.items.filter(item => !['assets', 'outOfSquare'].includes(item.elementCode))
    CalculateService.deepCalculateSubitems(result);
    CalculateService.deepCalculateSubItemsB2C(result);
    CalculateService.deepCalculateSubItemsB2B(result);
    return result;
})

const pagePart1Sum = computed(() => pagePart1Calculation.value?.[sumKey]);

// watchers order is important: global ot top !!!!
watch(filler,
    () => {
        if (fillerUpdatingFlag === true) {
            console.debug('     !!! filler updating');
            return
        }
        console.log(filler);
        console.log(filler.value.fillerTypeCode);
        const pageCalculationLocal = FillerCalculatorBuilder.fromStructure(filler.value, estimateStore.estimate.info.installedSystemCode);
        CalculateService.deepCalculateSubitems(pageCalculationLocal);
        CalculateService.deepCalculateSubItemsB2C(pageCalculationLocal);
        CalculateService.deepCalculateSubItemsB2B(pageCalculationLocal);
        pageCalculation.value = pageCalculationLocal;
        validatePage();
    }, {deep: true, immediate: true}
)

watch(() => filler.value.dimensions,
    () => {
        filler.value.dimensions.areaFt = calcAreaFt(cloneDeep(filler.value.dimensions));
        filler.value.dimensions.perimeterFt = calcPerimerFt(cloneDeep(filler.value.dimensions));
    }, {deep: true}
);

watch(() => filler.value.structureTypeCode, () => {
    if (fillerUpdatingFlag) {
        return;
    }
    console.log('filler type change!');
  recalcDimensions();
    fillerUpdatingFlag = true;
// todo check hardware list changed (by filler type code)
// todo clear hardware values
    filler.value.hardwares = EstimateService.fillerHardwareFactory(filler.value.structureTypeCode)
    recalcDimensions();
    fillerUpdatingFlag = false;
});

// calcTransfer
watch(() => uiStore.transferToWidthFlag, () => {
    filler.value.dimensions.width = uiStore.calcWidth
},)

watch(() => uiStore.transferToHeightFlag, () => {
    filler.value.dimensions.height = uiStore.calcHeight
},)

function getHardwareItemTitle(hardwareTypeCode: string): string {
    return getFromDictByCode(dictStore.getHardwareTypes('transom'), hardwareTypeCode)?.title;
}

function getHardwareItemDict(hardwareTypeCode: string): Dictionary[] {
    return dictStore.getHardwareValues(hardwareTypeCode);
}

function getHardwareItemPrice(hardwareTypeCode: string, hardwareValueCode: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, hardwareTypeCode)?.[priceCode]
}

function getHardwareItemSum(hardwareTypeCode, hardwareValueCode) {
    return CalculateService.getSubitemByCode(pageCalculation.value, hardwareTypeCode)?.[sumKey]
}

let partition: Ref<StructureItem> = ref(null);
partition.value = activeStructure.value.partition;

</script>

<style scoped>
.step__block-fillertype-container {
    display: grid;
    row-gap: 5px;
    padding: 15px 0;
}

.step__block-fillertype-container .step__block-fillertype-wrap:first-child {
    padding-bottom: 5px;
}

.step__block-fillertype {
    color: #404d63;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-fillertype-wrap div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.step__block-fillertype-wrap div {
    align-self: center;
}

.step__block-fillertype-wrap {
    display: grid;
    grid-template-columns: 70px 316px repeat(2, 1fr);
    column-gap: 50px;
}

.step__block-hardwareset {
    padding: 15px 0;
    display: grid;
    row-gap: 5px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-hardwareset-item {
    display: grid;
    grid-template-columns: 100px 1fr;
    column-gap: 31px;
}

.step__block-hardwareset-item div:first-child {
    align-self: center;
}

.step__block-doortype {
    display: grid;
    grid-template-columns: minmax(0, max-content) 316px;
    column-gap: 26px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 15px;
}

.step__block-doortype--transform {
    padding-top: 15px;
}

.step__block-doortype div:first-child {
    align-self: center;
    justify-self: center;
}

.step__block-field {
    display: flex;
    padding: 15px 0;
    border-top: 1px solid #d2d8e3;
    border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
    color: #404d63;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field-price {
    color: #404d63;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.step__block-field--hardware .step__block-field-block {
    justify-content: flex-start;
    column-gap: 10px;
}

.step__block-dimensions {
    padding: 15px 0;
}

.step__block-dimensions-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.step__block-dimensions-right {
    display: flex;
    column-gap: 92px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-dimensions-right div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
    width: 65px;
}

.step__block-dimensions {
    padding: 15px 0;
}

.step__block-dimensions-left {
    display: grid;
    grid-template-columns: minmax(0, 46px) repeat(2, 1fr);
    column-gap: 10px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-dimensions-units {
    display: grid;
    grid-template-columns: repeat(3, 60px);
    padding-bottom: 11px;
}

.step__block-dimensions-units div {
    padding-left: 11px;
}

.step__block-dimensions-container {
    display: grid;
    grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
    background-color: #fff;
    height: 35px;
}

.step__block-dimensions-container div input {
    width: 100%;
    height: 100%;
    padding-left: 15px;
    padding-right: 7px;
}

.step__block-dimensions-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step__block-dimensions-block--table:first-child .step__block-dimensions-container div {
    border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
    border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div:last-child {
    border-right: none;
}

.step__block-partitionglass {
    padding: 15px 0;
}

.step__block-partitionglass-block {
    display: grid;
    grid-template-columns: 35% 150px repeat(2, 1fr);
    column-gap: 15px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    column-gap: 15px;
}

.step__block-partitionglass-block--selects {
    padding-top: 10px;
}

.step__block-partitionglass-subblock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
    align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
    padding-right: 10px;
}

.step__block-partitionglass-subblock input {
    width: 50px;
    height: 35px;
    border-radius: 20px;
    background: #fff;
    padding: 0 10px;
    text-align: center;
}

.step__block-partitionglass-total {
    padding: 15px 0;
    display: grid;
    row-gap: 15px;
}

.step__block-partitionglass-line {
    width: 100%;
    height: 1px;
    background-color: #d2d8e3;
}

.step__block-dimensions-warning {
    align-self: flex-start;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #ff6255;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 25px;
}
</style>
