<template>
    <vSelect
        :options="options" class="select"
        :style="{ backgroundColor: props.backgroundColor }"
        :class="selectFieldClass"
    />
</template>

<script setup lang="ts">
import vSelect from 'vue-select';

import {computed, ref} from "vue";

const options = ref([]);

const props = withDefaults(
    defineProps<{
        placeholder?: string;
        taggable?: boolean;
        disabled?: boolean;
        multiple?: boolean;
        backgroundColor?: string;
    }>(),
    {
        taggable: false,
        disabled: false,
        multiple: false,
        placeholder: 'select',
        backgroundColor: '#fff',
    }
);

const selectFieldClass = computed(() => {
    return props.multiple ? 'vue-select--multiple' : 'vue-select--single';
});

</script>
<style src="vue-select/dist/vue-select.css"></style>
<style>
.select {
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    background: #fff;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 35px;
}

.select.select--extra {
    background: #fbfbfb;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
}

.select.select--finish {
    background: #2491eb;
    color: #fff;
    min-height: 49px;
}

.select__list {
    position: absolute;
    top: 80%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease;
    box-shadow: 0 0 24px 0 rgba(32, 92, 148, 0.15);
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
}

.select.select--finish .select__list {
    top: unset;
    bottom: 80%;
    max-height: unset;
}

.select.select--finish .select__list {
    color: #404d63;
}

.select__list--active {
    top: 100%;
    opacity: 1;
    pointer-events: auto;
    transition: 0.5s ease;
}

.select.select--finish .select__list--active {
    top: unset;
    bottom: 100%;
}

.select__list li {
    padding: 10px;
    background-color: #fff;
    cursor: pointer;
    transition: 0.5s ease;
}

.select.select--finish .select__list li:hover {
    background-color: #fff;
    color: #2491eb;
}

.select__list li:hover {
    background-color: #2491eb;
    transition: 0.5s ease;
    color: #fff;
}

.select__head {
    width: 100%;
    min-height: 35px;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.select.select--finish .select__head {
    min-height: 49px;
}

.select__head span {
    max-width: calc(100% - 70px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vs__dropdown-toggle {
    padding: 3px 7px;
    width: 100%;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: none;
}
</style>
