<template>
    <div class="questions-section__calculate">
        <div class="questions-section__calculator">
            <div class="questions-section__calculator-head">
                <h3>Measurment calculator:</h3>
                <button class="questions-section__calculator-clear" @click="calc1clear">
                    <span>Сlear</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <path
                            d="M6 2L2 6"
                            stroke="white"
                            stroke-width="0.8"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M2 2L6 6"
                            stroke="white"
                            stroke-width="0.8"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
            <div class="questions-section__measurment">
                <div class="questions-section__calculator-measurment">
                    <div class="questions-section__calculator-block questions-section__calculator-block--head">
                        <div>
                            <span>Scale</span>
                        </div>
                        <div>
                            <span>Size (inch)</span>
                        </div>
                        <div>
                            <span>Real size (inch)</span>
                        </div>
                    </div>
                    <div class="questions-section__calculator-block questions-section__calculator-block--value">
                        <div class="questions-section__calculator-field questions-section__calculator-field--fill">
                            <input
                                type="number"
                                v-model="calc1scale"
                                min="0"
                                max="998"
                                maxlength="6"
                                @change="useEmptyZero"
                            />
                        </div>
                        <div class="questions-section__calculator-field questions-section__calculator-field--fill">
                            <input
                                type="number"
                                v-model="calc1size"
                                min="0"
                                max="999"
                                maxlength="6"
                                @change="useEmptyZero"
                            />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input readonly type="number" :value="calc1result.realSize" />
                        </div>
                    </div>
                </div>
                <div class="questions-section__calculator-conversion">
                    <div class="questions-section__calculator-block questions-section__calculator-block--head">
                        <div>
                            <span>Ft</span>
                        </div>
                        <div>
                            <span>Inch</span>
                        </div>
                        <div>
                            <span>Fractions</span>
                        </div>
                    </div>
                    <div class="questions-section__calculator-block questions-section__calculator-block--value">
                        <div class="questions-section__calculator-field">
                            <input readonly type="number" :value="calc1result.dimension.ft" @change="useEmptyZero" />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input readonly type="number" :value="calc1result.dimension.inch" @change="useEmptyZero" />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                readonly
                                type="number"
                                :value="calc1result.dimension.fractionA"
                                @change="useEmptyZero"
                            />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                readonly
                                type="number"
                                :value="calc1result.dimension.fractionB"
                                @change="useEmptyZero"
                            />
                        </div>
                    </div>
                </div>
                <div class="questions-section__calculator-transfer">
                    <button @click="transferToWidth(calc1result.dimension)">Transfer to width</button>
                    <button class="height" @click="transferToHeight(calc1result.dimension)">Transfer to height</button>
                </div>
            </div>
        </div>
        <div class="questions-section__calculator">
            <div class="questions-section__calculator-head">
                <h3>Calculator 2:</h3>
                <button @click="calc2clear" class="questions-section__calculator-clear">
                    <span>Сlear</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <path
                            d="M6 2L2 6"
                            stroke="white"
                            stroke-width="0.8"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M2 2L6 6"
                            stroke="white"
                            stroke-width="0.8"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
            <div class="questions-section__calculator-calculate">
                <div class="questions-section__calculator-conversion">
                    <div class="questions-section__calculator-block questions-section__calculator-block--head">
                        <div>
                            <span>Ft</span>
                        </div>
                        <div>
                            <span>Inch</span>
                        </div>
                        <div>
                            <span>Fractions</span>
                        </div>
                    </div>
                    <div class="questions-section__calculator-block questions-section__calculator-block--value">
                        <div class="questions-section__calculator-field">
                            <input
                                v-model.number="calc2ValueA.ft"
                                type="number"
                                min="0"
                                max="999"
                                @change="useEmptyZero" />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                v-model.number="calc2ValueA.inch"
                                type="number"
                                min="0"
                                max="999"
                                maxlength="6"
                                @change="useEmptyZero"
                            />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                v-model.number="calc2ValueA.fractionA"
                                type="number"
                                min="0"
                                max="999"
                                maxlength="6"
                                @change="useEmptyZero"
                            />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                v-model.number="calc2ValueA.fractionB"
                                type="number"
                                min="0"
                                max="999"
                                maxlength="6"
                                @change="useEmptyZero"
                            />
                        </div>
                    </div>
                </div>
                <div class="questions-section__calculator-control">
                    <button
                        v-for="btn in actions"
                        :key="btn.action"
                        @click="activeAction = btn.action"
                        class="questions-section__calculator-button"
                        :class="{ 'questions-section__calculator-button--active': activeAction === btn.action }"
                    >
                        <span v-html="btn.content"></span>
                    </button>
                </div>
                <div class="questions-section__calculator-conversion">
                    <div class="questions-section__calculator-block questions-section__calculator-block--value">
                        <div class="questions-section__calculator-field">
                            <input v-model.number="calc2ValueB.ft" type="number" min="0" max="999" />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input v-model.number="calc2ValueB.inch" type="number" />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                v-model.number="calc2ValueB.fractionA"
                                type="number"
                                min="0"
                                max="999"
                                maxlength="6"
                            />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                v-model.number="calc2ValueB.fractionB"
                                type="number"
                                min="0"
                                max="999"
                                maxlength="6"
                            />
                        </div>
                    </div>
                </div>
                <div class="questions-section__calculator-line"></div>
                <div class="questions-section__calculator-conversion">
                    <div class="questions-section__calculator-block questions-section__calculator-block--head">
                        <div>
                            <span>Ft</span>
                        </div>
                        <div>
                            <span>Inch</span>
                        </div>
                        <div>
                            <span>Fractions</span>
                        </div>
                    </div>
                    <div class="questions-section__calculator-block questions-section__calculator-block--value">
                        <div class="questions-section__calculator-field">
                            <input
                                readonly
                                v-model.number="calc2ValueR.ft"
                                type="number"
                                min="0"
                                max="999"
                                @change="useEmptyZero"
                            />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                readonly
                                v-model.number="calc2ValueR.inch"
                                type="number"
                                min="0"
                                max="999"
                                @change="useEmptyZero"
                            />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                readonly
                                v-model.number="calc2ValueR.fractionA"
                                type="number"
                                min="0"
                                max="999"
                                @change="useEmptyZero"
                            />
                        </div>
                        <div class="questions-section__calculator-field">
                            <input
                                readonly
                                v-model.number="calc2ValueR.fractionB"
                                type="number"
                                min="0"
                                max="999"
                                @change="useEmptyZero"
                            />
                        </div>
                    </div>
                </div>
                <div class="questions-section__calculator-transfer">
                    <button @click="transferToWidth(calc2ValueR)">Transfer to width</button>
                    <button class="height" @click="transferToHeight(calc2ValueR)">Transfer to height</button>
                </div>

                <div class="questions-section__result">
                    <div class="questions-section__result-block">
                        <input
                            id="inputFeet"
                            v-model.number="inputFeet"
                            @change="lengthConverter"
                            @input="lengthConverter"
                            type="number"
                            min="0"
                            max="999"
                        />
                        <span>Ft</span>
                    </div>
                    <div class="questions-section__result-block">
                        <input
                            id="inputInches"
                            v-model.number="inputInches"
                            @change="lengthConverter"
                            @input="lengthConverter"
                            type="number"
                            min="0"
                            max="999"
                        />
                        <span>Inch</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { watchEffect, ref, unref, toRaw, computed } from "vue";
import { VuePreloader } from 'vue-preloader';
import { useUiStore } from "@/stores/uiStore";
import { OneDimension } from "../models/structure";
import {
    dimensionAdd,
    dimensionDiv,
    dimensionMulti,
    dimensionSubtract,
    inchToDimension,
    roundTo,
} from "../utils/common";
import { _ } from "lodash";

const emit = defineEmits<{
    transferToWidth: [value: OneDimension];
    transferToHeight: [value: OneDimension];
}>();

const uiStore = useUiStore();

function loadingIsOver() {
    uiStore.showPreloader = false;
}
function transitionIsOver() {
    uiStore.showPreloader = false;
}

const actions: Array<{ action: string; content: string }> = [
    {
        action: "plus",
        content:
            '      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">\n' +
            '              <path d="M5 1L5.00001 9.48527" stroke="white" stroke-linecap="square" stroke-linejoin="round" />\n' +
            "              <path\n" +
            '                d="M0.757324 5.24219L9.24259 5.2422"\n' +
            '                stroke="white"\n' +
            '                stroke-linecap="square"\n' +
            '                stroke-linejoin="round"\n' +
            "              />\n" +
            "            </svg>",
    },
    {
        action: "minus",
        content:
            '       <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2" viewBox="0 0 12 2" fill="none">\n' +
            '              <path d="M1 1H11" stroke="white" stroke-linecap="square" stroke-linejoin="round" />\n' +
            "            </svg>",
    },
    {
        action: "multiply",
        content:
            '       <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">\n' +
            '              <path d="M1 1L7 6.99998" stroke="white" stroke-linecap="square" stroke-linejoin="round" />\n' +
            '              <path d="M1 7L7 1.00002" stroke="white" stroke-linecap="square" stroke-linejoin="round" />\n' +
            "            </svg>",
    },
    {
        action: "divide",
        content:
            '     <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">\n' +
            "              <path\n" +
            '                d="M6.08869 1.04619L6.16483 0.9H6H5.16667H5.106L5.07798 0.953807L0.911309 8.95381L0.835166 9.1H1H1.83333H1.894L1.92202 9.04619L6.08869 1.04619Z"\n' +
            '                fill="white"\n' +
            '                stroke="white"\n' +
            '                stroke-width="0.2"\n' +
            "              />\n" +
            "            </svg>",
    },
];

const activeAction: Ref<string> = ref(actions[0].action);

const calc2ValueA: Ref<OneDimension> = ref({ ft: null, inch: null, fractionA: null, fractionB: null });
const calc2ValueB: Ref<OneDimension> = ref({ ft: null, inch: null, fractionA: null, fractionB: null });
const calc2ValueR: Ref<OneDimension> = ref({ ft: null, inch: null, fractionA: null, fractionB: null });

const calc1scale: Ref<number> = ref(null);
const calc1size: Ref<number> = ref(null);

const inputFeet = ref(null);
const inputInches = ref(null);

watchEffect(() => {
    calc2ValueR.value = calculate(calc2ValueA, calc2ValueB, activeAction.value);
});

const calc1result = computed(() => calc1calculate(calc1scale.value, calc1size.value));

function calc1calculate(scale: number, size: number): { realSize: number; dimension: OneDimension } {
    const realSize = roundTo(scale * size);
    if (isNaN(realSize) || realSize === 0) {
        return { realsize: 0, dimension: { ft: null, inch: null, fractionA: null, fractionB: null } };
    }
    return {
        realSize: realSize,
        dimension: inchToDimension(realSize),
    };
}
function calc1clear() {
    //calc1scale.value = null;
    calc1size.value = null;
}

function calculate(a: ref<OneDimension>, b: ref<OneDimension>, operatior: string): OneDimension {
    let result: OneDimension = null;
    const da = _.cloneDeep(a.value);
    const db = _.cloneDeep(b.value);
    // console.log("calculate", da, db);
    switch (operatior) {
        case "plus":
        case "+":
            result = dimensionAdd(da, db);
            break;
        case "minus":
        case "-":
            result = dimensionSubtract(da, db);
            break;
        case "multiply":
        case "*":
            result = dimensionMulti(da, db);
            break;
        case "divide":
        case "/":
            result = dimensionDiv(da, db);
            break;
        default:
            result = <OneDimension>{ ft: null, inch: null, fractionA: null, fractionB: null };
    }

    return normalizeCalculate(result);
}

function normalizeCalculate(result) {
    if (result.ft === 0) {result.ft = null}
    if (result.inch === 0) {result.inch = null}
    if (result.fractionA === 0) {result.fractionA = null}
    if (result.fractionB === 0) {result.fractionB = null}

    return result
}

function calc2clear() {
    calc2ValueA.value = { ft: null, inch: null, fractionA: null, fractionB: null };
    calc2ValueB.value = { ft: null, inch: null, fractionA: null, fractionB: null };
    calc2ValueR.value = { ft: null, inch: null, fractionA: null, fractionB: null };
}

function transferToWidth(calc2ValueR) {
    //emit("transferToWidth", calc2ValueR);
    uiStore.transferToWidth(calc2ValueR);
}

function transferToHeight(calc2ValueR) {
    //emit("transferToHeight", calc2ValueR);
    uiStore.transferToHeight(calc2ValueR);
}

function lengthConverter(event) {
    //TODO https://github.com/KeithKulwicki/Length-Converter/blob/master/index.html#L156
    const valNum = parseFloat(event.target.value);
    if (event.target.id === "inputFeet") {
        let inch = (valNum * 12).toFixed(2);
        inputInches.value = isNaN(inch) ? "" : inch;
    }
    if (event.target.id === "inputInches") {
        let feet = (valNum * 0.083333).toFixed(2);
        inputFeet.value = isNaN(feet) ? "" : feet;
    }
}

function useEmptyZero(event) {
    console.debug(event.target);
    if (event.target.value === "0") {
        event.target.value = null;
    }
}function useZero(event) {
    event.target.value = null;
}
</script>
<style scoped>
.questions-section__result {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 19px;
}

.questions-section__result-block {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.questions-section__result-block input {
    width: 68px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.questions-section__result-block span {
    color: #fff;
    font-family: Gilroy;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.questions-section__calculator-line {
    width: 100%;
    height: 1px;
    background-color: #d2d8e3;
    margin: 16px 0;
}

.questions-section__calculate {
    display: grid;
    row-gap: 30px;
}

.questions-section__calculator-calculate .questions-section__calculator-control {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 7px;
    padding: 10px 5px;
}

.questions-section__calculator-calculate .questions-section__calculator-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 27px;
    border-radius: 5px;
    border: 1px solid transparent;
    background: rgba(255, 255, 255, 0.31);
    transition: 0.5s ease;
}

.questions-section__calculator-calculate .questions-section__calculator-button--active {
    border: 1px solid #fff;
    transition: 0.5s ease;
}

.questions-section__calculator-head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}

.questions-section__measurment {
    display: grid;
    row-gap: 10px;
}

.questions-section__calculator-clear {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #fff;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.questions-section__calculator-measurment .questions-section__calculator-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.questions-section__calculator-conversion .questions-section__calculator-block--value {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.questions-section__calculator-conversion
    .questions-section__calculator-block--value
    .questions-section__calculator-field {
    border-right: 1px solid #7894af;
}

.questions-section__calculator-conversion
    .questions-section__calculator-block--value
    .questions-section__calculator-field:last-child {
    border-right: none;
}

.questions-section__calculator-conversion .questions-section__calculator-block--head {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
}

.questions-section__calculator-conversion .questions-section__calculator-block--head div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 38px;
}

.questions-section__calculator-conversion .questions-section__calculator-block--head div {
    padding-left: 9px;
}

.questions-section__calculator-measurment .questions-section__calculator-block--head {
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
}

.questions-section__calculator-measurment .questions-section__calculator-block--head span {
    padding-left: 10px;
}

.questions-section__calculator-measurment .questions-section__calculator-block--value,
.questions-section__calculator-conversion .questions-section__calculator-block--value {
    border-radius: 5px;
    border: 1px solid #7894af;
    height: 30px;
}

.questions-section__calculator-measurment .questions-section__calculator-field--fill {
    background-color: #2491eb;
    border-right: 1px solid #7894af;
}

.questions-section__calculator-measurment .questions-section__calculator-field input,
.questions-section__calculator-conversion .questions-section__calculator-block--value input {
    width: 100%;
    height: 100%;
    padding-left: 9px;
    padding-right: 9px;
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.questions-section__calculator .questions-section__calculator-transfer {
    margin-top: 10px;
}
.questions-section__calculator-transfer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
}

.questions-section__calculator-transfer button {
    border-radius: 5px;
    background: #2491eb;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 30px;
}
.questions-section__calculator-transfer .height {
    background-color: #404d63;
}
.questions-section__calculator-head h3 {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
</style>
