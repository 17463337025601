import {acceptHMRUpdate, defineStore} from "pinia";
import { OneDimension } from "../models/structure";
import {reactive, ref, Ref, watch} from "vue";
import {useUserStore} from "./userStore";
import {usePriceStore} from "./priceStore";
import {Estimate} from "../models/estimate";
import {useEstimateStore} from "./estimateStore";

export const useUiStore = defineStore("ui", () => {
    const calcWidth: Ref<OneDimension> = ref(<OneDimension>{ft: null, inch: null, fractionA: null, fractionB: null});
    const calcHeight: Ref<OneDimension> = ref(<OneDimension>{ft: null, inch: null, fractionA: null, fractionB: null});
    const transferToWidthFlag:Ref<boolean> = ref(false);
    const transferToHeightFlag:Ref<boolean> = ref(false);
    const tableEstimatesSelectedRowCode: Ref<string> = ref(null);
    const summaryElementsStatus: Ref<string[]> = ref([]);

    const showPreloader = ref(true);

    const steps: string[] = [
        "General information",
        "Partition(s)",
        "Door(s)",
        "Transom(s) and filler(s)",
        "Chanel, tracks, profiles",
        "Overhead expenses",
        "Delivery and installation",
        "Estimation review",
    ]

    const selectFinishStatusList = ref([
        "FINISH and save as official",
        "FINISH and save as quote",
        "FINISH and save as order",
        "FINISH and save as DONE",
    ]);

    const data = reactive({
        user: useUserStore(),
        estimate: <Estimate>useEstimateStore().estimate,
    });

    const getSelectFinishStatusList = (estimate) => {
        if (estimate?.progress?.status === 'DONE') {
            selectFinishStatusList.value = [
                "FINISH and save as quote",
                "FINISH and save as official",
                "FINISH and save as order",
            ];
        }
        if (estimate?.progress?.status === 'QUOTE') {
            selectFinishStatusList.value = [
                "FINISH and save as official",
                "FINISH and save as order",
            ];
        }
        if (estimate?.progress?.status === 'OFFICIAL') {
            selectFinishStatusList.value = [
                "FINISH and save as order",
            ];
        }
        if (data?.user?.profile?.roles?.length > 0) {
            data.user.profile.roles.forEach(function (role){
                if (role.slug == 'manager') {
                    if (estimate?.progress?.status === 'DRAFT') {
                        selectFinishStatusList.value = [
                            "FINISH and save as DONE",
                            "FINISH and save as quote",
                        ];
                    }
                    if (estimate?.progress?.status === 'DONE') {
                        selectFinishStatusList.value = [
                            "FINISH and save as quote",
                        ];
                    }
                    if (estimate?.progress?.status === 'OFFICIAL') {
                        selectFinishStatusList.value = [];
                    }
                    if (estimate?.progress?.status === 'ORDER') {
                        selectFinishStatusList.value = [];
                    }
                }
            });
        }

        return selectFinishStatusList;
    }

    function transferToWidth(value: OneDimension) {
        console.debug('transferToWidth', value)
        calcWidth.value = value;
        transferToWidthFlag.value = !transferToWidthFlag.value
    }

    function transferToHeight(value: OneDimension) {
        console.debug('transferToHeight', value)
        calcHeight.value = value;
        transferToHeightFlag.value = !transferToHeightFlag.value;
    }

    /** используется для обновления списка Summary справа */
    const summaryRecalculateFlag: Ref<boolean> = ref(false);
    const squareRecalculateFlag: Ref<boolean> = ref(false);

    /** используются для перелистывания страниц из внешних функций */
    /** номер текущей страницы */
    const pageChangeFlag:Ref<boolean> = ref(false);

    /** признак необходимости обновления страницы, используется в watcher в CalculateQuestion */
    const newPageNo:Ref<number> = ref(null);


    function reloadSummary() {
        summaryRecalculateFlag.value = !summaryRecalculateFlag.value
    }

    function reloadSquare() {
        squareRecalculateFlag.value = !squareRecalculateFlag.value
    }

    /**
     * перейти на страницу по номеру
     * @param toPage
     */
    function changePage(toPage: number) {
        if (!toPage) { return }
        newPageNo.value = toPage;
        pageChangeFlag.value = !pageChangeFlag.value
    }

    /**
     * перейти на страницу по типу
     * info, space, partition, door, transom, filler, mounts, overhead
     */

    function changePageByType(toPageType: string) {
        let toPage:number = null;
        switch (toPageType) {
            case 'info':
                toPage = 1;
                break;
            case 'space':
                toPage = 2;
                break;
            case 'partition':
                toPage = 2;
                break;
            case 'door':
                toPage = 3;
                break;
            case 'transom':
            case 'filler':
                toPage = 4;
                break;
            case 'mounts':
                toPage = 5;
                break;
            case 'overhead':
                toPage = 6;
                break;
            case 'delivery':
                toPage = 7;
                break;
        }
        changePage(toPage);
    }


    function setSelectedTableRow(estimateCode: string) {
        tableEstimatesSelectedRowCode.value = estimateCode;
    }

    watch(
        () => ( data?.estimate?.progress?.status ),
        () => {
            initSelectFinishStatusList();
        },
    );

    return {
        showPreloader,
        calcWidth,
        calcHeight,
        transferToWidth,
        transferToHeight,
        transferToWidthFlag,
        transferToHeightFlag,
        summaryRecalculateFlag,
        squareRecalculateFlag,
        pageChangeFlag,
        newPageNo,
        changePage,
        changePageByType,
        steps,
        selectFinishStatusList,
        setSelectedTableRow,
        reloadSummary,
        reloadSquare,
        getSelectFinishStatusList,
        summaryAccordeonStatus: summaryElementsStatus,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(usePriceStore, import.meta.hot),
    );
}
