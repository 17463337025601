import {defineStore} from "pinia";
import { useLoginStatus, useLogin, useLogout } from "../utils/api";
import { computed, ComputedRef, ref, Ref } from "vue";
import {UserProfile} from "../models/userProfile";

export const useUserStore = defineStore('user', () => {
    const token: Ref<string> = ref('');
    const cookie: Ref<string> = ref('');
    const profile: Ref<UserProfile> = ref(null); // UserProfile,

    const isLogged: ComputedRef<boolean> = computed(() => {
        return profile.value?.id > 0
    });
    const userCode = computed(() => profile.value.email ??  null);

    const isSalesManager: ComputedRef<boolean> = computed(() => {
        const hasRole = profile.value.roles?.some(role => role.slug == 'manager')
        return !!hasRole;
    });

    const isSalesEstimator: ComputedRef<boolean> = computed(() => {
        const hasRole = profile.value.roles?.some(role => role.slug == 'estimator')
        return !!hasRole;
    });

    const isAdmin: ComputedRef<boolean> = computed(() => {
        const hasRole = profile.value.roles?.some(role => role.slug == 'admin')
        return !!hasRole;
    });

    async function login(login: string, password: string) {
//        await http.get('/sanctum/csrf-cookie');
        const res = await useLogin(login, password);
        if (res.code == 200) {
            return await checkLogin();

        } else return false;
    }

    async function logout() {
//        profile.value = null;
//         console.log("logout");
        await useLogout();
    }


    /**
     * проверка, залогинен ли пользователь
     */
    async function checkLogin()  {
        // console.log('checkLogin');
        try {
            const data = await useLoginStatus()
            // console.log(data);
            if (data.code === 200 || data.code === 201) {
                profile.value = data.data
            } else {
                profile.value = null;
            }
        } catch ( err ) {
            profile.value = null;
        }
    }

    return{
        token,
        cookie,
        profile,
        userCode,
        login,
        logout,
        checkLogin,
        isLogged,
        isSalesManager,
        isSalesEstimator,
        isAdmin,
    }
})
