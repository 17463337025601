<template>
    <div>
        <div class="step__block-doortype">
            <div>
                <span>Door type:</span>
            </div>
            <div>
                <select-dict
                    v-model="door.structureTypeCode"
                    :selectList="doorTypes"
                    :select-first-item="true"
                ></select-dict>
            </div>
        </div>
        <div v-if="isDoorBlockVisible" class="step__block-field">
            <div class="step__block-field-block">
                <h3>DOOR dimensions:</h3>
            </div>
            <div v-if="errorMessages.dimensions" class="step__block-dimensions-warning">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_251_7435)">
                        <path
                            d="M7.99992 14.6654C11.6818 14.6654 14.6666 11.6806 14.6666 7.9987C14.6666 4.3168 11.6818 1.33203 7.99992 1.33203C4.31802 1.33203 1.33325 4.3168 1.33325 7.9987C1.33325 11.6806 4.31802 14.6654 7.99992 14.6654Z"
                            stroke="#FF6255"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path d="M8 5.33203V7.9987" stroke="#FF6255" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M8 10.668H8.00708" stroke="#FF6255" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_251_7435">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <span><p v-html="errorMessages.dimensions"></p></span>
            </div>
        </div>
        <div v-if="isDoorBlockVisible" class="step__block-dimensions">
            <div class="step__block-dimensions-block">
                <div class="step__block-dimensions-left">
                    <div></div>
                    <div class="step__block-dimensions-units">
                        <div>
                            <span>Ft</span>
                        </div>
                        <div>
                            <span>Inch</span>
                        </div>
                        <div>
                            <span>Fractions</span>
                        </div>
                    </div>
                </div>
                <div class="step__block-dimensions-right">
                    <div>
                        <span>Size, sq.ft:</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="step__block-dimensions-block step__block-dimensions-block--table">
                    <div class="step__block-dimensions-left">
                        <div class="step__block-dimensions-name">
                            <span>Width</span>
                        </div>
                        <div class="step__block-dimensions-container">
                            <div>
                                <input-num v-model.number="door.dimensions.width.ft" :max="39"/>
                            </div>
                            <div>
                                <input-num v-model.number="door.dimensions.width.inch" :max="12"/>
                            </div>
                            <div>
                                <input-num v-model.number="door.dimensions.width.fractionA" :max="16"/>
                            </div>
                            <div>
                                <input-num v-model.number="door.dimensions.width.fractionB" :max="16"/>
                            </div>
                        </div>
                    </div>
                    <div class="step__block-dimensions-right">
                        <div>
                            <span>{{ formatDimension(dimensionToFt(door.dimensions.width)) }}</span>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div class="step__block-dimensions-block step__block-dimensions-block--table">
                    <div class="step__block-dimensions-left">
                        <div class="step__block-dimensions-name">
                            <span>Height</span>
                        </div>
                        <div class="step__block-dimensions-container">
                            <div>
                                <input-num v-model.number="door.dimensions.height.ft"/>
                            </div>
                            <div>
                                <input-num v-model.number="door.dimensions.height.inch" :max="12"/>
                            </div>
                            <div>
                                <input-num v-model.number="door.dimensions.height.fractionA" :max="16"/>
                            </div>
                            <div>
                                <input-num v-model.number="door.dimensions.height.fractionB" :max="16"/>
                            </div>
                        </div>
                    </div>
                    <div class="step__block-dimensions-right">
                        <div>
                            <span>{{ formatDimension(dimensionToFt(door.dimensions.height)) }}</span>
                        </div>
                        <div>
                            <span>{{ formatDimension(door.dimensions.areaFt) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDoorBlockVisible" class="step__block-field">
            <div class="step__block-field-block">
                <h3>DOOR glass:</h3>
            </div>
        </div>
        <div v-if="isDoorBlockVisible" class="step__block-partitionglass">
            <div class="step__block-partitionglass-block">
                <div>
                    <span>Glass sort:</span>
                </div>
                <div class="step__block-partitionglass-subblock">
                    <span>Glass thickness:</span>
                </div>
                <div class="step__block-partitionglass-subblock">
                    <span>Ft</span>
                </div>
                <div class="step__block-partitionglass-subblock">
                    <span>Per sq.ft.</span>
                </div>
                <div class="step__block-partitionglass-subblock">
                    <span>SUM</span>
                </div>
            </div>
            <div class="step__block-partitionglass-block step__block-partitionglass-block--selects">
                <div>
                    <select-dict
                        v-model="door.glassSortCode"
                        :select-first-item="true"
                        :select-list="glassSorts"
                    />

                </div>
                <div class="step__block-partitionglass-subblock">
                    <select-dict
                        v-model="door.glassThicknessCode"
                        :select-first-item="true"
                        :select-list="glassThickness"
                    />
                </div>
                <div class="step__block-partitionglass-subblock">
                    <span>{{ formatDimension(door.dimensions.areaFt) }}</span>
                </div>
                <div class="step__block-partitionglass-subblock">
                    <span>{{ formatMoney(glassPrice) }}</span>
                </div>
                <div class="step__block-partitionglass-subblock">
                    <span>{{ formatMoney(glassSum) }}</span>
                </div>
            </div>
        </div>
        <div v-if="isDoorBlockVisible" class="step__block-field">
            <div class="step__block-field-block">
                <h3>DOOR hardware:</h3>
                <span v-if="door.hardwares.length === 0"> not applicabe to this type of door</span>
            </div>
        </div>
        <div class="step__block-hardware" v-if="door.hardwares.length && isDoorBlockVisible">
            <div class="step__block-hardware-block step__block-hardware-head">
                <div class="step__block-hardware-div"></div>
                <div class="step__block-hardware-div"></div>
                <div class="step__block-hardware-div">
                    <span>Q-ty</span>
                </div>
                <div class="step__block-hardware-div">
                    <span>Per asset</span>
                </div>
                <div class="step__block-hardware-div">
                    <span>SUM</span>
                </div>
            </div>
            <div class="step__block-hardware-container">
                <template v-for="hardware in door.hardwares">
                    <div class="step__block-hardware-block step__block-hardware-content">
                        <div class="step__block-hardware-div step__block-hardware-first">
                            <span>{{ getHardwareItemTitle(hardware.hardwareTypeCode) }}</span>
                        </div>
                        <div class="step__block-hardware-div">
                            <select-dict
                                v-model="hardware.hardwareValueCode"
                                :select-first-item="true"
                                :select-list=getHardwareItemDict(hardware.hardwareTypeCode)
                                @select-changed="(key, value) => validateHardwaresList(hardware.hardwareTypeCode, key, value)"
                            ></select-dict>
                        </div>
                        <div class="step__block-hardware-div step__block-hardware-qt">
                            <div>
                                <span>{{ hardware.qty }}</span>
                            </div>
                        </div>
                        <div class="step__block-hardware-div">
                            <span>
                                {{
                                    formatMoney(getHardwareItemPrice(hardware.code))
                                }}
                            </span>
                        </div>
                        <div class="step__block-hardware-div">
                            <span>
                                {{ formatMoney(getHardwareItemSum(hardware.code)) }}
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div v-if="isCutsBlockVisible && isDoorBlockVisible" class="step__block-field">
            <div class="step__block-field-block">
                <h3>CUTS (holes)</h3>
            </div>
        </div>
        <div v-if="isCutsBlockVisible && isDoorBlockVisible" class="step__block-hardware">
            <div class="step__block-hardware-block step__block-hardware-head">
                <div class="step__block-hardware-div"></div>
                <div class="step__block-hardware-div"></div>
                <div class="step__block-hardware-div">
                    <span>Q-ty</span>
                </div>
                <div class="step__block-hardware-div">
                    <span>Per asset</span>
                </div>
                <div class="step__block-hardware-div">
                    <span>SUM</span>
                </div>
            </div>
            <div class="step__block-hardware-container step__block-hardware-container--cuts">
                <div class="step__block-hardware-block step__block-hardware-content">
                    <div class="step__block-hardware-div step__block-hardware-first">
                        <span>Lock:</span>
                    </div>
                    <div class="step__block-hardware-div"></div>
                    <div class="step__block-hardware-div step__block-hardware-qt">
                        <div>
                            <span>{{ holes.lock.qty }}</span>
                        </div>
                    </div>
                    <div class="step__block-hardware-div">
                        <span>{{ formatMoney(holes.lock?.[priceCode]) }}</span>
                    </div>
                    <div class="step__block-hardware-div">
                        <span>{{ formatMoney(holes.lock?.[sumKey]) }}</span>
                    </div>
                </div>
                <div class="step__block-hardware-block step__block-hardware-content">
                    <div class="step__block-hardware-div step__block-hardware-first">
                        <span>Handle:</span>
                    </div>
                    <div class="step__block-hardware-div"></div>
                    <div class="step__block-hardware-div step__block-hardware-qt">
                        <div>
                            <span>{{ holes.handle.qty }}</span>
                        </div>
                    </div>
                    <div class="step__block-hardware-div">
                        <span>{{ formatMoney(holes.handle?.[priceCode]) }}</span>
                    </div>
                    <div class="step__block-hardware-div">
                        <span>{{ formatMoney(holes.handle?.[sumKey]) }}</span>
                    </div>
                </div>
                <div class="step__block-hardware-block step__block-hardware-content"
                     v-if="!isSlideDoor(door.structureTypeCode)">
                    <div class="step__block-hardware-div step__block-hardware-first">
                        <span>Patches set:</span>
                    </div>
                    <div class="step__block-hardware-div"></div>
                    <div class="step__block-hardware-div step__block-hardware-qt">
                        <div>
                            <span>{{ holes.patches.qty }}</span>
                        </div>
                    </div>
                    <div class="step__block-hardware-div">
                        <span>{{ formatMoney(holes.patches?.[priceCode]) }}</span>
                    </div>
                    <div class="step__block-hardware-div">
                        <span>{{ formatMoney(holes.patches?.[sumKey]) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDoorBlockVisible && isDoorBlockVisible" class="step__block-sumperdoor">
            <div class="step__block-sumperdoor-left">
                <div>
                    <span>Door quantity and the SUM per one door:</span>
                </div>
            </div>
            <div class="step__block-sumperdoor-right">
                <div class="step__block-sumperdoor-input">
                    <input v-model.number="door.qty" type="number" min="0" max="99" step="1"/>
                </div>
                <div class="step__block-sumperdoor-price">
                    <span>{{ formatMoney(pageCalculation.price + pageCalculation.subitems?.[sumKey]) }}</span>
                </div>

            </div>
        </div>
        <div class="step__block-field">
            <div class="step__block-field-block">
                <h3>page Total:</h3>
                <span class="step__block-field-price">{{ formatMoney(pageCalculation?.[sumKey]) }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import SelectDict from "@/components/UI/SelectDict.vue";
import InputNum from "@/components/UI/InputNum.vue";
import { getFromDictByCode, useDictStore } from "../../stores/dictStore";
import { useEstimateStore } from "../../stores/estimateStore";
import { computed, handleError, Ref, ref, watch } from "vue";
import { EstimatedSpace, } from "../../models/estimate";
import { cloneDeep } from "lodash";
import { Structure, HardwareItem, StructureItem, AdditionalCostItem } from "../../models/structure";
import {
    calcAreaFt,
    calcPerimerFt,
    formatDimension,
    formatMoney,
    dimensionToFt,
    dimensionCompare,
    addTextToText
} from "../../utils/common";
import { Dictionary } from "../../models/dictionary";
import {
    CalculateService,
    ItemCalculator,
    PartitionCalculatorBuilder,
    DoorCalculatorBuilder
} from "../../services/calculateService";
import { CalculationItem } from "../../models/calculate";
import {
  EstimateService,
  isDoubleDoor,
  isSingleDoor,
  isSlideDoor,
  isSwingDoor,
  isKompasSystem,
  isCgsSystem,
  isCgfSystem, isKompasDoor
} from "../../services/estimateService";
import { useUiStore } from "../../stores/uiStore";
import {usePriceStore} from "../../stores/priceStore";

defineExpose({validatePage, savePage, reloadPage});

let sumKey = usePriceStore().data.currentSumKey;
let priceCode = usePriceStore().data.currentPriceKey;

const estimateStore = useEstimateStore();
const dictStore = useDictStore();
const uiStore = useUiStore();

const glassSorts = dictStore.getGlassSorts();
const glassTypes = dictStore.getGlassTypes();

let activeSpace = ref(cloneDeep(estimateStore.getActiveSpace()));
let activeStructure = ref(cloneDeep(estimateStore.getActiveStructure()));
let door: Ref<StructureItem> = ref(cloneDeep(estimateStore.getActiveDoor()));
const pageCalculation: Ref<CalculationItem> = ref(null);

const doorTypes = computed(() => {
    // todo костыль, чтобы не добавлять ко всем типам дверей все типы партишн кроме dr-none, вернем руками dr-none
    if (activeStructure.value.partition.structureTypeCode === 'div') { // divider
        return dictStore.getDoorTypes(estimateStore.estimate.info.installedSystemCode).filter((item) => item.code === 'dr-none')
    }
    return dictStore.getDoorTypes(estimateStore.estimate.info.installedSystemCode);
})

let glassThickness = computed(() => dictStore.getGlassThickness(estimateStore.estimate.info.installedSystemCode));
if (['kompas-sgl', 'kompas-dbl'].includes(estimateStore.estimate.info.installedSystemCode)) {
  glassThickness = computed(() => dictStore.getGlassThicknessIncludeDoorCondition(estimateStore.estimate.info.installedSystemCode, door.value.structureTypeCode));
}

let doorUpdatingFlag: boolean = false;

const errorMessages = ref(
    {
        dimensions: ''
    }
)

function savePage() {
    estimateStore.updateDoor(door.value);
}


function validatePage() {
    estimateStore.validateStructure(activeStructure.value);
    // reset errors
    errorMessages.value = {
        dimensions: '',
    }

    if (dimensionToFt(door.value.dimensions.width) === 0 || dimensionToFt(door.value.dimensions.height) === 0) {
        errorMessages.value.dimensions = addTextToText(
            errorMessages.value.dimensions,
            'Width and height must be greater then 0',
            '<br>');
    }
    if (dimensionToFt(door.value.dimensions.height) > 10) {
        errorMessages.value.dimensions = addTextToText(
            errorMessages.value.dimensions,
            'Height can`t be greater then 10 ft.',
            '<br>');
    }
    // calc common size
    const activePartition = activeStructure.value.partition;

    if (dimensionToFt(door.value.dimensions.width) > dimensionToFt(activePartition.dimensions.width)) {
        errorMessages.value.dimensions = addTextToText(
            errorMessages.value.dimensions,
            `Door width greater then partition (${formatDimension(dimensionToFt(activePartition.dimensions.width))} ft)`,
            '<br>');
    }
    if (dimensionToFt(door.value.dimensions.height) > dimensionToFt(activePartition.dimensions.height)) {
        errorMessages.value.dimensions = addTextToText(
            errorMessages.value.dimensions,
            `Door height greater then partition (${formatDimension(dimensionToFt(activePartition.dimensions.height))} ft)`,
            '<br>');
    }
    // sliding door height must be equal partition height
    if (isSlideDoor(door.value.structureTypeCode)) {
        if (dimensionCompare(door.value.dimensions.height, activePartition.dimensions.height) !== 0) {
            errorMessages.value.dimensions = addTextToText(
                errorMessages.value.dimensions,
                `Door height must be equal partition height (${formatDimension(dimensionToFt(activePartition.dimensions.height))} ft)`,
                '<br>');
        }
    }

    return true;
}

function reloadPage() {
    door.value = cloneDeep(estimateStore.getActiveDoor());
}

const glassPrice = computed(() => CalculateService.getSubitemByCode(pageCalculation.value, 'glass')?.[priceCode]);
const glassSum = computed(() => CalculateService.getSubitemByCode(pageCalculation.value, 'glass')?.[sumKey]);
const isCutsBlockVisible = computed(() => {
    // kompass systems not have hols or cuts
    return !(['kmp-s', 'kmp-d'].includes(door.value.structureTypeCode))
});

const isDoorBlockVisible = computed(() => door.value.structureTypeCode !== 'dr-none');

const holes = ref({
    lock: {price: 0, priceB2C: 0, priceB2B: 0, qty: 0, sum: 0, sumB2C: 0, sumB2B: 0},
    handle: {price: 0, priceB2C: 0, priceB2B: 0, qty: 0, sum: 0, sumB2C: 0, sumB2B: 0},
    patches: {price: 0, priceB2C: 0, priceB2B: 0, qty: 0, sum: 0, sumB2C: 0, sumB2B: 0},
});

updateHoles();
// watchers order is important: global ot top !!!!
watch(() => door,
    () => {
        if (doorUpdatingFlag) {
            console.debug('     !!! door updating');
            return
        }
        const pageCalculationLocal = DoorCalculatorBuilder.fromStructure(door.value, estimateStore.estimate.info.installedSystemCode);
        CalculateService.deepCalculateSubitems(pageCalculationLocal);
        CalculateService.deepCalculateSubItemsB2C(pageCalculationLocal);
        CalculateService.deepCalculateSubItemsB2B(pageCalculationLocal);
        validatePage();
        pageCalculation.value = pageCalculationLocal;
    }, {deep: true, immediate: true}
)


/**
 * измененеие типа двери
 */
watch(() => door.value.structureTypeCode, () => {
    if (doorUpdatingFlag) {
        return;
    }
    doorUpdatingFlag = true;
// todo check hardware list changed (by door type code)

    // если нет дрери, размеры 0 * 0
    // для компасов дверь по умолчанию 3(6 dbl)*7
    // для остальных  3(6 dbl) * высоту партишна

    if (door.value.structureTypeCode === 'dr-none') {
        door.value.dimensions = {
            width: {ft: 0, inch: 0, fractionA: 0, fractionB: 0},
            height: {ft: 0, inch: 0, fractionA: 0, fractionB: 0},
        }
    } else {
        // ширина
        // 6 и 3 ширина по умолчанию для дверей
        if (isSingleDoor(door.value.structureTypeCode)
            && (dimensionToFt(door.value.dimensions.width) === 6 || dimensionToFt(door.value.dimensions.width) === 0)) {
            door.value.dimensions.width.ft = 3;
        }
        if (isDoubleDoor(door.value.structureTypeCode)
            && (dimensionToFt(door.value.dimensions.width) === 3 || dimensionToFt(door.value.dimensions.width) === 0)) {
            door.value.dimensions.width.ft = 6;
        }
        // высота
        if (dimensionToFt(door.value.dimensions.height) === 0) {
            if (isKompasSystem(estimateStore.estimate.info.installedSystemCode)) {
                door.value.dimensions.height = {ft: 7, inch: 0, fractionA: 0, fractionB: 0};

            } else {
                door.value.dimensions.height = {...activeStructure.value.partition.dimensions.height};
            }
        }
    }
    // компасу ставим стекло по умолчанию 3/8
    if (isKompasSystem(estimateStore.estimate.info.installedSystemCode)) {
        door.value.glassThicknessCode = '3-8';
    }

    // если дверь kompas по уполчанию стекло 1/4
    if (isKompasSystem(estimateStore.estimate.info.installedSystemCode) && isKompasDoor(door.value.structureTypeCode)) {
      door.value.glassThicknessCode = '1-4';
    }

    door.value.hardwares = EstimateService.doorHardwareFactory(door.value.structureTypeCode)
    door.value.additionalCosts = EstimateService.doorAdditionalCostsFactory(door.value);
    // todo update fillert/transom asset qty
    updateHoles();
    doorUpdatingFlag = false;

})

watch(() => door.value.dimensions,
    () => {
        door.value.dimensions.areaFt = calcAreaFt(cloneDeep(door.value.dimensions));
        door.value.dimensions.perimeterFt = calcPerimerFt(cloneDeep(door.value.dimensions));
    }, {deep: true}
);

watch(() => door.value.hardwares, () => {
    if (doorUpdatingFlag) {
        return;
    }
    doorUpdatingFlag = true;
    door.value.additionalCosts = EstimateService.doorAdditionalCostsFactory(door.value);

    const pageCalculationLocal = DoorCalculatorBuilder.fromStructure(door.value, estimateStore.estimate.info.installedSystemCode);
    CalculateService.deepCalculateSubitems(pageCalculationLocal);
    CalculateService.deepCalculateSubItemsB2C(pageCalculationLocal);
    CalculateService.deepCalculateSubItemsB2B(pageCalculationLocal);
    validatePage();
    pageCalculation.value = pageCalculationLocal;

    updateHoles();
    doorUpdatingFlag = false;
}, {deep: true, immediate: true});

// calcTransfer
watch(() => uiStore.transferToWidthFlag, () => {
    door.value.dimensions.width = uiStore.calcWidth
},)

watch(() => uiStore.transferToHeightFlag, () => {
    door.value.dimensions.height = uiStore.calcHeight
},)

function validateHardwaresList(type: string, key: string, value: string) {
    // Patch lock и все Locking ladders - взаимоисключающие элементы.
    // Если есть замок - ручка с замком недоступна и наоборот. Исключается элемент, выбранный последним.

    // переделано 2024-01 по указанию заказчика
    // patch lock можно выбрать только с handle, где в названии есть locking (lls)
    // locking laddar и patch lock должны быть совместимы

    // переделано 2024-02
    // по ручкам: patch lock совместим со всеми Кроме!! locking

    switch (type) {
        case 'dr-lock':
            if (key === 'lock-none') break;
            const handleItem: HardwareItem = <HardwareItem>door.value.hardwares.find(item => item.hardwareTypeCode === 'dr-handle');

            // Patch lock (lock1) совместима со всеми handles кроме hnd-lls-...
            if ((key === 'lock1')
                && handleItem
                && ['hnd-lls-1', 'hnd-lls-2', 'hnd-lls-3', 'hnd-lls-4', 'hnd-lls-5',].includes(handleItem.hardwareValueCode)
            ) {
                handleItem.hardwareValueCode = 'hnd-ls-none';
                break;
            }
            // если ручка с замком
            // if (handleItem
            //     && ['hnd-ls-1', 'hnd-ls-2', 'hnd-ls-3', 'hnd-ls-4', 'hnd-ls-5',].includes(handleItem.hardwareValueCode)
            // ) {
            //     handleItem.hardwareValueCode = 'hnd-ls-none';
            // }
            break;
        case 'dr-handle':
            const lockItem: HardwareItem = <HardwareItem>door.value.hardwares.find(item => item.hardwareTypeCode === 'dr-lock');

            //  locking laddar и patch lock (lock1) должны быть совместимы
            if (lockItem
                && lockItem.hardwareValueCode === 'lock1'
                && ['hnd-lls-1', 'hnd-lls-2', 'hnd-lls-3', 'hnd-lls-4', 'hnd-lls-5',].includes(key)
            ) {
                lockItem.hardwareValueCode = 'lock-none';
                break;
            }

            // if (lockItem
            //     && lockItem.hardwareValueCode !== 'lock1'
            //     && ['hnd-ls-1', 'hnd-ls-2', 'hnd-ls-3', 'hnd-ls-4', 'hnd-ls-5',].includes(key)
            // ) {
            //     lockItem.hardwareValueCode = 'lock-none';
            // }
            // break;
    }
}

function getHardwareItemTitle(hardwareTypeCode: string): string {
    return getFromDictByCode(dictStore.getHardwareTypes('door'), hardwareTypeCode)?.title;
}

function getHardwareItemDict(hardwareTypeCode: string): Dictionary[] {
    return dictStore.getHardwareValues(hardwareTypeCode);
}

function getHardwareItemPrice(code: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, code)?.[priceCode]
}

function getHardwareItemSum(code: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, code)?.[sumKey]
}

function getAdditionalCostItemPrice(code: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, code)?.[priceCode];
}

function getAdditionalCostItemQty(code: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, code)?.qty;
}

function getAdditionalCostItemSum(code: string) {
    return CalculateService.getSubitemByCode(pageCalculation.value, code)?.[sumKey];
}

function updateHoles() {
    holes.value = {
        lock: {
            price: getAdditionalCostItemPrice('dr-lock-holes-01'),
            priceB2C: getAdditionalCostItemPrice('dr-lock-holes-01'),
            priceB2B: getAdditionalCostItemPrice('dr-lock-holes-01'),
            qty: getAdditionalCostItemQty('dr-lock-holes-01'),
            sum: getAdditionalCostItemSum('dr-lock-holes-01'),
            sumB2C: getAdditionalCostItemSum('dr-lock-holes-01'),
            sumB2B: getAdditionalCostItemSum('dr-lock-holes-01'),
        },
        handle: {
            price: getAdditionalCostItemPrice('dr-handle-holes-01'),
            priceB2C: getAdditionalCostItemPrice('dr-handle-holes-01'),
            priceB2B: getAdditionalCostItemPrice('dr-handle-holes-01'),
            qty: getAdditionalCostItemQty('dr-handle-holes-01'),
            sum: getAdditionalCostItemSum('dr-handle-holes-01'),
            sumB2C: getAdditionalCostItemSum('dr-handle-holes-01'),
            sumB2B: getAdditionalCostItemSum('dr-handle-holes-01'),
        },
        patches: {
            price: getAdditionalCostItemPrice('dr-patches-holes-01'),
            priceB2C: getAdditionalCostItemPrice('dr-patches-holes-01'),
            priceB2B: getAdditionalCostItemPrice('dr-patches-holes-01'),
            qty: getAdditionalCostItemQty('dr-patches-holes-01'),
            sum: getAdditionalCostItemSum('dr-patches-holes-01'),
            sumB2C: getAdditionalCostItemSum('dr-patches-holes-01'),
            sumB2B: getAdditionalCostItemSum('dr-patches-holes-01'),
        },
    }
}
let partition: Ref<StructureItem> = ref(null);
partition.value = activeStructure.value.partition;
</script>

<style scoped>
.step__block-doortype {
    display: grid;
    grid-template-columns: minmax(0, max-content) 316px;
    column-gap: 26px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 15px;
}

.step__block-doortype div:first-child {
    align-self: center;
    justify-self: center;
}

.step__block-sumperdoor {
    padding: 15px 0;
    border-top: 1px solid #d2d8e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-sumperdoor-right {
    display: flex;
    align-items: center;
    column-gap: 129px;
}

.step__block-sumperdoor-input input {
    border-radius: 20px;
    background: #fff;
    width: 50px;
    height: 30px;
    color: #404d63;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.step__block-hardware-first {
    align-items: flex-start !important;
}

.step__block-hardware {
    padding: 15px 0;
}

.step__block-hardware-container {
    display: grid;
    row-gap: 5px;
}

.step__block-hardware-container.step__block-hardware-container--cuts {
    row-gap: 10px;
}

.step__block-hardware-block {
    display: grid;
    grid-template-columns: 1fr  50% repeat(3, 1fr);
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-hardware-head {
    margin-bottom: 13px;
}

.step__block-hardware-block.step__block-hardware-head .step__block-hardware-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.step__block-hardware-block.step__block-hardware-content .step__block-hardware-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.step__block-hardware-qt div {
    width: 32px;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    text-align: center;
}

.step__block-field {
    display: flex;
    padding: 15px 0;
    border-top: 1px solid #d2d8e3;
    border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
    color: #404d63;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field-price {
    color: #404d63;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.step__block-field-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.step__block-dimensions {
    padding: 15px 0;
}

.step__block-dimensions-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.step__block-dimensions-right {
    display: flex;
    column-gap: 92px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-dimensions-right div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
    width: 65px;
}

.step__block-dimensions {
    padding: 15px 0;
}

.step__block-dimensions-left {
    display: grid;
    grid-template-columns: minmax(0, 46px) repeat(2, 1fr);
    column-gap: 10px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step__block-dimensions-units {
    display: grid;
    grid-template-columns: repeat(3, 60px);
    padding-bottom: 11px;
}

.step__block-dimensions-units div {
    padding-left: 11px;
}

.step__block-dimensions-container {
    display: grid;
    grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
    background-color: #fff;
    height: 35px;
}

.step__block-dimensions-container div input {
    width: 100%;
    height: 100%;
    padding-left: 15px;
    padding-right: 7px;
}

.step__block-dimensions-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step__block-dimensions-block--table:first-child .step__block-dimensions-container div {
    border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
    border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div:last-child {
    border-right: none;
}

.step__block-partitionglass {
    padding: 15px 0;
}

.step__block-partitionglass-block {
    display: grid;
    grid-template-columns: 35% 155px repeat(3, 1fr);
    column-gap: 15px;
    color: #404d63;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    column-gap: 15px;
}

.step__block-partitionglass-block--selects {
    padding-top: 10px;
}

.step__block-partitionglass-subblock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
    align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
    padding-right: 10px;
}

.step__block-partitionglass-subblock input {
    width: 50px;
    height: 35px;
    border-radius: 20px;
    background: #fff;
    padding: 0 10px;
}

.step__block-partitionglass-total {
    display: grid;
    row-gap: 15px;
}

.step__block-dimensions-warning {
    align-self: flex-start;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #ff6255;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 25px;
}
</style>
