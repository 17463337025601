<template>
    <li v-if="line.type==='keyval'" v-bind:key="line.code">
        {{ line.title }}: {{ line.value }}
    </li>

    <li v-if="line.type==='empty'" v-bind:key="line.code"></li>

    <li v-if="line.type==='qtysum'" class="step__accordion-block-item">
        <div class="step__accordion-block-left">
            <div class="step__accordion-block-dot"></div>
            <h3>{{ line.title }}</h3>
        </div>
        <div class="step__accordion-block-container">
            <div class="step__accordion-block-count">
                <span>{{ line.qty }}</span>
            </div>
            <div class="step__accordion-block-right">
                <div>
                    <span>{{ formatMoney(line?.[priceCode]) }}</span>
                </div>
                <div>
                    <span>{{ formatMoney(line?.[sumKey]) }}</span>
                </div>
            </div>
        </div>
    </li>


</template>

<script setup lang="ts">
import { defineProps, Ref, toRef } from "vue";
import { SummaryLine } from "@/models/summary";
import { formatMoney } from "@/utils/common";
import {usePriceStore} from "../../../stores/priceStore";

const sumKey = usePriceStore().data.currentSumKey;
const priceCode = usePriceStore().data.currentPriceKey;

const props = defineProps({
    summaryLine: {
        type: Object,
        required: true,
    }
})

const line: Ref<SummaryLine> = toRef(props.summaryLine);

</script>

<style scoped>

</style>
