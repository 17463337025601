<template>
  <div class="select">
    <div @click="isVisibleSelectList = !isVisibleSelectList"   class="select-head">
      <div class="select-head__block">
        <span><slot></slot></span>
        <svg :class="['select-head__svg', {'select-head__svg--active': isVisibleSelectList}]" xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
          <mask id="path-1-inside-1_1_39" fill="white">
          <path d="M12 0.0576172L6 6.05762L0 0.0576161"/>
          </mask>
          <path d="M11.1515 -0.790911L12 -1.63944L13.6971 0.0576173L12.8485 0.906145L11.1515 -0.790911ZM6 6.05762L6.84853 6.90615C6.3799 7.37477 5.6201 7.37477 5.15147 6.90614L6 6.05762ZM-0.848528 0.906144L-1.69706 0.057616L1.48361e-07 -1.63944L0.848528 -0.790912L-0.848528 0.906144ZM12.8485 0.906145L6.84853 6.90615L5.15147 5.20909L11.1515 -0.790911L12.8485 0.906145ZM5.15147 6.90614L-0.848528 0.906144L0.848528 -0.790912L6.84853 5.20909L5.15147 6.90614Z" fill="#404D63" mask="url(#path-1-inside-1_1_39)"/>
        </svg>
      </div>
    </div>
    <div :class="['select-search', {'select-search--visible': isVisibleSelectList}]">
      <div class="select-search__block">
        <div @click="changeInputSearchImg" style="cursor: pointer" >
          <svg  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path d="M8.70833 15.0417C12.2061 15.0417 15.0417 12.2061 15.0417 8.70833C15.0417 5.21053 12.2061 2.375 8.70833 2.375C5.21053 2.375 2.375 5.21053 2.375 8.70833C2.375 12.2061 5.21053 15.0417 8.70833 15.0417Z" stroke="#404D63" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.6254 16.6254L13.1816 13.1816" stroke="#404D63" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </div>
        <input @keyup.enter="changeInputSearch" type="text" placeholder="search">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisibleSelectList: false,
    }
  },
  emits: ['findEstimate'],
  methods: {
    changeInputSearch(e) {
      this.$emit('findEstimate', e.target.value);
      this.isVisibleSelectList = false;
    },
    changeInputSearchImg(e) {
      this.$emit('findEstimate', e.target.parentNode.parentNode.lastElementChild.value);
      this.isVisibleSelectList = false;
    }
  }
}
</script>

<style scoped>
  .select {
    position: relative;
  }
  .select-search {
    position: absolute;
    top: 90%;
    left: 0;
    right: 0;
    width: 100%;
    height: 55px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 24px 0px rgba(32, 92, 148, 0.15);
    z-index: 5;
    transition: 0.5s ease;
    opacity: 0;
    pointer-events: none;
  }
  .select-search--visible {
    top: 100%;
    transition: 0.5s ease;
    opacity: 1;
    pointer-events: auto;
  }
  .select-head {
    display: flex;
    /* align-items:center; */
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
  .select-head__block {
    display: flex;
    column-gap: 10px;
    align-items:center;
  }
  .select-head__svg {
    position: relative;
    transform: rotate(0);
    transition: 0.5s ease;
  }
  .select-head__svg--active {
    transform: rotate(-180deg);
    transition: 0.5s ease;
  }
  .select-search__block {
    height: 100%;
    position: relative;
  }
  .select-head span {
    color: #404D63;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .select-search svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }
  .select-search input {
    padding-left: 44px;
    padding-right: 20px;
    display: block;
    width: 100%;
    height: 100%;
    color: #B9C0CD;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
</style>
