<template>
  <div class="not-found">
    <div class="not-found__block">
      <h2>404</h2>
      <h3>Not Found</h3>
      <p>
        Sorry, the requested page was not found. You may have clicked on a link that was in error, or the resource may
        have been removed
      </p>
      <router-link :to="`/calculation/`">BACK</router-link>
    </div>
  </div>
</template>

<style scoped>
.not-found {
  width: 100vw;
  height: 100vh;
  background-image: url("@/assets/images/not-found.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.not-found__block {
  padding-top: 212px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #404d63;
}
.not-found__block h2 {
  font-size: 180px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.not-found__block h3 {
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.not-found__block p {
  color: #7b89a1;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 339px;
  margin-bottom: 40px;
}
.not-found__block a {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 30px;
  background: #2491eb;
  padding: 14px 42px;
}
</style>
